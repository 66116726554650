

import { Link } from "react-router-dom"
import EvaluacionSimple from "../../../_components/evaluacionSimple";
  
export function EvaluacionSimplePage  () {
  
  
  return (
 <div className="  d-flex flex-column flex-root" id="kt_docs_content">
            <div className=" container  " id="kt_docs_content_container">
                
                        <EvaluacionSimple></EvaluacionSimple>
                     
            </div>
        </div>
  );
}