
 
import axios from 'axios';
import React, {Component} from 'react';
import { Empresa } from '../_models/empresa';

//import { Empresa } from '../_models/empresa';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/empresa";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/empresa";
class EmpresaService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    
    getempresas(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {'Content-Type': 'application/json'}
        }) 
    }
    createempresa(empresa){
        return axios.post(API_BASE_URL+ '/ins', empresa,{
            headers: {'Content-Type': 'application/json'}
        })}
    getempresaById(empresaId){
        return axios.get(API_BASE_URL + '/sel/' + empresaId);
    }
    updateempresa(empresa){
        return axios.post(API_BASE_URL + '/upd/' , empresa,{
            headers: {'Content-Type': 'application/json'}
        })}

    deleteempresa(empresaId){
        return axios.delete(API_BASE_URL + '/' + empresaId);
    }
}

export default new EmpresaService()