
import React, { Component ,useState} from "react";
import ContenidoDataService from "../_services/contenido";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

const columns = [
{   dataField: 'id_contenido',   text: 'Editar' ,formatter: (rowContent, row) => {
  return (
    <>
      <Link to={"/contenidoedit?id=" + rowContent}             >
              <i className="far fa-edit fs-1qx text-primary"></i> 
      </Link>
    </>
  )
} }, 
      {   dataField: 'codigo_tipo',   text: 'Tipo',sort:true },                   
      {   dataField: 'url',   text: 'url',sort:true },                   
      {   dataField: 'descripcion',   text: 'Descripción',sort:true },                   
      {   dataField: 'contenido',   text: 'Contenido',sort:true },                   
      {   dataField: 'tema',   text: 'Tema',sort:true },                   
];
export default class ContenidoLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _contenidos: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    ContenidoDataService.getcontenidos()
      .then(response => {
        this.setState({
          _contenidos: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _contenidos } = this.state;
    return (
        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_contenido"
            data={ _contenidos }
            columns={ columns }
            search           >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  <hr />
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 