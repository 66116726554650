
import React, { Component ,useState} from "react";
import FeedbackDataService from "../_services/feedback";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

const columns = [
   
  {   dataField: 'fecha_feedback',   text: 'Fecha Feedback',sort:true },                   
  {   dataField: 'codigo',   text: 'Código',sort:true },    
  {   dataField: 'nombres',   text: 'Nombres',sort:true },                   
  {   dataField: 'apellido',   text: 'Apellido',sort:true },    
  {   dataField: 'fecha_evaluacion',   text: 'Fecha Evaluación',sort:true },                   
  {   dataField: 'titulo',   text: 'Tema',sort:true },         
  {   dataField: 'comentario',   text: 'Comentario',sort:true },    
  {   dataField: 'escala',   text: 'Escala',sort:true },                 
          
];
export default class FeedbackLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _feedbacks: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    FeedbackDataService.getfeedbacks()
      .then(response => {
        this.setState({
          _feedbacks: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _feedbacks } = this.state;
    return (
        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_feedback"
            data={ _feedbacks }
            columns={ columns }
            search           >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  <hr />
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 