import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import logdataservice from "../_services/log";
import { Link, useNavigate } from "react-router-dom";
import UsuarioDataService from "../_services/usuario";
import AsignacionDataService from "../_services/asignacion";
import ProgramaDataService from "../_services/programa";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import { getAnalytics, logEvent } from "firebase/analytics";


const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

const { SearchBar } = Search;
export default function DashboardUsuarioPanel() {
  const analytics = getAnalytics();
  //console.log(currentUser)
  //logEvent(analytics, 'select_content', {
  //    item_name: 'dashboardusuario',
  //    item_id: currentUser?.id,
  //    items: [{ name: currentUser?.username }]
  //});
  var bgColors = {
    "Default": "#81b71a",
    "Blue": "#00B1E1",
    "Cyan": "#37BC9B",
    "Green": "#8CC152",
    "Red": "#E9573F",
    "Yellow": "#F6BB42",
  };
  const columns = [
    {
      dataField: 'id_asignacion', text: 'Temas', formatter: (rowContent, row) => {
        return (
          <>
            <div className="btn-toolbar justify-content-between" role="toolbar"
              aria-label="Toolbar with button groups">
              <div className="btn-group" role="group" aria-label="First group">

                <Link to={"/planestudiousuario?id=" + row["id_planestudio"]}
                  className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon "               >
                  <i className="fa fa-briefcase fs-2x text-primary" ></i>123
                </Link>


              </div>
            </div>
          </>
        )
      }
    },
    { dataField: 'fecha_creacion', text: 'Fecha Asignado', sort: true },

    { dataField: 'plan', text: 'Plan de Estudio', sort: true },

    { dataField: 'fecha_inicio', text: 'Inicio', sort: true },
    { dataField: 'fecha_fin', text: 'Fin', sort: true },

  ];
  const { currentUser, logout } = useAuth()
  const [seguimiento, setSeguimiento] = useState([]);
  const [kpi, setKPI] = useState([]);
  const [isOnline, setIsOnline] = useState(null);
  const [asignacion, setAsignacion] = useState([]);
  const [programa, setPrograma] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('dashboardusuario')
    if (currentUser?.indicador_soporte) {
      navigate('/planestudio')
    }
    fetch(API_BASE_URL + 'usuario/sellst/kpi/' + currentUser?.id, {
      method: 'GET', headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(result => {
        setKPI(result);
        setIsOnline(true)
      })
      .catch(e => {
        console.log(e);
      });
    AsignacionDataService.getasignacionByUsuario(currentUser?.id)
      .then(response => {
        //setContenido(response.data)
        //console.log(response.data)
        if (response.data.length > 0) {
          //**************/
          //Redirecciona al Tema Pendiente
          navigate('/evaluacionedit?id=' + response.data[0].id_tema
            + '&idpe=' + response.data[0].id_planestudio
            + '&idp=' + response.data[0].id_programa
            + '&idc=' + response.data[0].id_curso
            + '&idm=' + response.data[0].id_modulo);
          //**************/
        } else {
          logdataservice.registralog("dashboardusuario", "", "", currentUser.id, currentUser.username);

        }
      })
      .catch(e => {
        console.log(e);
      });

  }, [isOnline]);
  if (isOnline === null) {
    //console.log(isOnline);
    return <>'Cargando...'</>;
  } else {
    return (
      <>
        <div className="row  ">
          <div className="col-xl-12">
            <div className="card card-flush">

              <div className='card-body '>

                <div className='d-flex align-items-center bg-light-warning rounded  '>
                  <span className='svg-icon svg-icon-warning me-5  '>
                    <i className="las la-award fs-3x me-2 text-danger"></i>
                  </span>
                  <div className='flex-grow-1 me-2 py-4'>

                    <Link to={"/planmislogros"}
                      className="fw-bold text-gray-800 text-hover-primary fs-6" >
                      Mis Logros
                    </Link>
                    <span className='text-muted fw-semibold d-block'> Acceder al detalle de sus Logros</span>
                  </div>
                  <span className='fw-bold text-warning py-1'>

                    <Link to={"/planmislogros"}
                      className="btn btn-outline btn-outline-dashed btn-outline-danger   btn-sm py-2"               >
                      <i className="fa-solid fa-edit   text-danger"></i>Ver
                    </Link>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="separator my-1"></div>
        <div className='row'>
          <div className="col-lg-3">
            <a className="card bg-danger hoverable card-xl-stretch mb-xl-2">
              <div className="card-body">
                <i className="fa fa-book text-white fs-2x  ">
                  <span className="path1">
                  </span><span className="path2"></span>
                  <span className="path3">
                  </span>
                  <span className="path4"></span></i>
                <div className="text-white fw-bold fs-3">{kpi[0].temas}
                </div>
                <div className="fw-semibold text-white">Temas Asignados
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-3">
            <a className="card bg-warning hoverable card-xl-stretch mb-xl-8">
              <div className="card-body">
                <i className="fa fa-calendar text-white fs-2x">
                  <span className="path1">
                  </span><span className="path2"></span>
                  <span className="path3">
                  </span>
                  <span className="path4"></span></i>
                <div className="text-white fw-bold fs-3">{kpi[0].vencido}
                </div>
                <div className="fw-semibold text-white">Tema Vencido
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-3">
            <a className="card bg-success hoverable card-xl-stretch mb-xl-8">
              <div className="card-body">
                <i className="fa fa-chalkboard-user text-white fs-2x">
                  <span className="path1">
                  </span><span className="path2"></span>
                  <span className="path3">
                  </span>
                  <span className="path4"></span></i>
                <div className="text-white fw-bold fs-3">{kpi[0].promedio}%
                </div>
                <div className="fw-semibold text-white">Nota Promedio
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-3">
            <a className="card bg-primary hoverable card-xl-stretch mb-xl-8">
              <div className="card-body">
                <i className="fa fa-graduation-cap text-white fs-2x">
                  <span className="path1">
                  </span><span className="path2"></span>
                  <span className="path3">
                  </span>
                  <span className="path4"></span></i>
                <div className="text-white fw-bold fs-3">{kpi[0].evaluaciones}
                </div>
                <div className="fw-semibold text-white">Evaluaciones
                </div>
              </div>
            </a>
          </div>
        </div>




      </>
    )
  }
}