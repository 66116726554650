
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TemaDataService from "../_services/tema";
import ContenidoDataService from "../_services/contenido";
import EvaluacionDataService from "../_services/evaluacion";
import DetalleDataService from "../_services/detalle_evaluacion";
import PreguntaDataService from "../_services/pregunta";
 
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function TemaPreviewForm() {
    const [flag, setFlag] = useState(false);
    const [tema, setTema] = useState([]);
    const [preguntas, setPregunta] = useState([]);
 
    const queryParameters = new URLSearchParams(window.location.search)
    const idtema = queryParameters.get("id")
    useEffect(() => {

        console.log(idtema);
        //const { parametro1, parametro2, parametro3 } = props.location.search;
        //const array = props.location.state.array;
        //console.log(array)
        if (idtema !== null) {
            const arrpreguntas=({
                "id_tema": idtema,
                "id_modulo": 0
            })
            //console.log(JSON.stringify(arrpreguntas))
            PreguntaDataService.getpreguntastema(JSON.stringify(arrpreguntas))
             
                .then(result => {
                    setPregunta(result.data);
                    console.log(result.data);
                    if (result.data.length > 0) {
                        setFlag(true);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            TemaDataService.gettemaById(idtema)
                .then(result => {
                    setTema(result.data);
                    console.log(result.data);
                })
                .catch(e => {
                    console.log(e);
                });
            //

             
        }
    }, []);
    return (
        <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Demo de Evaluacion de Tema</h2>
                    </div>
                </div>

                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">

                    </div>

                </div>
            </div>
            <div className="card-body pt-10">
                <form  >
                <div className="input-group input-group-sm mb-5">
                    <ol className="breadcrumb text-muted fs-6 fw-bold">
                        <li className="breadcrumb-item pe-3">
                        Plan:
                            <span className="pe-3 text-primary ">
                                 {tema.plan}
                            </span>
                        </li>
                        <li className="breadcrumb-item pe-3">
                        Programa:
                            <span  className="pe-3 text-primary">
                                 {tema.programa}
                            </span>
                        </li>
                        <li className="breadcrumb-item pe-3  ">
                        Curso:
                            <span className="text-primary"> {tema.curso}
                            </span>
                        </li>
                        <li className="breadcrumb-item px-3">
                        Modulo:
                            <span className="text-primary">
                                 {tema.modulo}
                            </span>
                        </li>
                    </ol>
                    </div>
                    <div className="input-group input-group-sm mb-5">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                            Tema</span>
                        <input type="text" name="pregunta" readOnly defaultValue={tema.titulo}
                            className="form-control " />
                    </div>

                    <div className='row  '>
                        <div className='col-xl-12'>
                            <span>Contenido:</span>

                            <iframe className='form-control ' height={350}
                                title='Contenido'
                                src={tema.contenido}
                            >
                            </iframe>
                        </div>
                        <div className="card" >
                            <div className="card-header">

                            </div>
                            <div className="card-body d-flex align-items-end pt-0 border-primary">
                                <div className="d-flex align-items-center flex-wrap">
                                    <div className="d-flex flex-column content-justify-center flex-grow-1">
                                        <div className="d-flex fs-6 fw-semibold align-items-center">
                                            <div className="fs-6 fw-semibold text-gray-400 flex-shrink-0">
                                            </div>
                                            <div className="ms-auto fw-bolder text-gray-700 text-end">
                                            <a className="btn btn-danger"  target="_blank"
                                             href={tema.contenido_complementario}>Descargar Material</a> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-10">
                            {preguntas.map((preg, index) => (
                                <div className="form-group">
                                    <label className="text-primary">{index + 1} .{preg.pregunta}</label>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input border border-primary   " type="radio" value={"1"}
                                            name={preg.id_pregunta}  required />
                                        <label className="form-check-label text-dark"    >
                                            {preg.rpta_a}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input border border-primary" type="radio" value={"2"}
                                            name={preg.id_pregunta}   required />
                                        <label className="form-check-label text-dark" >
                                            {preg.rpta_b}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input border border-primary"
                                            name={preg.id_pregunta} type="radio"  
                                            value={"3"} required
                                        />
                                        <label className="form-check-label text-dark" >
                                            {preg.rpta_c}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input border border-primary"
                                            name={preg.id_pregunta} type="radio"  
                                            value={"4"} required
                                        />
                                        <label className="form-check-label text-dark" >
                                            {preg.rpta_d}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mb-10">

                        <Link to={"/tema"}
                            className="btn btn-icon-white btn-text-white btn-danger">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        
                    </div>

                </form>
            </div>
        </div>
    );
}
