import React, { Component ,useEffect,useState} from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import PlanDataService from "../_services/planestudio";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

const { SearchBar } = Search;
export default function PlanEstudioProgreso() {
   
  const columns = [
    {   dataField: 'id',   text: 'Id' ,sort:true },  
    {   dataField: 'denominacion',   text: 'Plan',sort:true },                   
    {   dataField: 'titulo',   text: 'Tema',sort:true },
    {   dataField: 'empresa',   text: 'Empresa',sort:true },
    {   dataField: 'codigo_usuario',   text: 'Codigo',sort:true },
    {   dataField: 'personal',   text: 'Personal',sort:true },
    //{   dataField: 'nota',   text: 'Nota',sort:true },      
    //{   dataField: 'correcta',   text: 'correcta',sort:true },
    {   dataField: 'fecha_asignado',   text: 'Asignado',sort:true },       
    {   dataField: 'fecha_creacion',   text: 'Fecha',sort:true }, 
    {   dataField: 'hora_creacion',   text: 'Hora ',sort:true },    
    { dataField: 'nota', text: 'Nota',sort:true, formatter: (rowContent, row) => {
      return (
        <>
        {rowContent >= row["notaminima"] ?
          <>
            <span className="badge badge-primary">{rowContent}</span>
          </> :
          <>
            <span className="badge badge-danger">{rowContent}</span>
          </>}
      </>
      )
    }
  },   
  { dataField: 'nota', text: 'Evaluado', sort:true,formatter: (rowContent, row) => {
    return (
      <>
        {rowContent === 0 ?
            <>
              <span className="badge badge-danger">Pendiente</span>
            </> :
            <>
              {rowContent <=row["notaminima"]  ?
                <>
                  <span className="badge badge-warning">En Progreso</span>

                </> :
                <>
                  <span className="badge badge-primary">Evaluado</span>

                </>}
            </>}
    </>
    )
  }
},   
     ];
  const { currentUser, logout } = useAuth()
  const [progreso, setProgreso] = useState([]);
  const [kpi, setKPI] = useState([]);
  const [isOnline, setIsOnline] = useState(null);

  useEffect(() => {
    PlanDataService.getplanprogreso ()
      .then(result => {
        setProgreso(result.data);
        //console.log(result);
        setIsOnline(true)
    })
    .catch(e => {
        console.log(e);
        });
      
     
       
   
  }, [isOnline]);
  if (isOnline === null) {
    console.log(isOnline);
    return <>'Cargando...'</>;
  } else {
  return (
    
    <>
       
      <div className='row'>
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
          <div className="   " id="kt_docs_content_container">

            <div className="row  mb-5 mb-xl-8">
              <div className="col-xl-12">
                <div className="card card-flush">
                  <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <h1>Plan de Estudio Progreso</h1>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                      <div className="w-100 mw-150px">

                      </div>

                    </div>
                  </div>

                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <ToolkitProvider
                        keyField="id"
                        data={ progreso }
                        columns={ columns }
                        search           >
                        {
                          props => (
                            <div>
                              <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                              <hr />
                              <BootstrapTable  className="table table-bordered table-responsive"
                                { ...props.baseProps }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>   
        </div>
      </div>
    </>
  )
}
}