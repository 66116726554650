
import React, { useCallback, Component, useEffect, useState } from "react";
import LoadingSpinner from "../_components/LoadingSpinner"; 
import PlanestudioDataService from "../_services/planestudio";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import { useAuth } from "../app/modules/auth";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Select from 'react-select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const ddlreporte = [];
const exportToCSV = (csvData, fileName) => {
  for (var i = 0, l = csvData.length; i < l; i++) {
    ddlreporte.push({
      "plan": csvData[i].denominacion,
      "fecha_inicio": csvData[i].fecha_inicio,
      "fecha_fin": csvData[i].fecha_fin,
      "fecha_creacion": csvData[i].fecha_creacion,
      "fecha_modificacion": csvData[i].fecha_modificacion,
      "temas": csvData[i].temas
    })
  }
  const ws = XLSX.utils.json_to_sheet(ddlreporte);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export default function PlanestudioLista() {
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [plan, setPlan] = useState([]);
  const [plansel, selPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, logout } = useAuth()
  const handleClickOpen = () => {
    setFlag(false);
    setOpen(true);
  };
   
  const handleClose = () => {
    setOpen(false);
  };
  const handleCopiarUsuarios = async (e) => {
    e.preventDefault();
    const answer = window.confirm("Esta seguro de Copiar Los usuarios del Plan?");
    console.log(plansel)
    if (answer) {
      PlanestudioDataService.copiarplanestudio(JSON.stringify(plansel))
      .then(response => {
        //setPlan(response.data)
        console.log(response.data)
        let iasignados= response.data.resultado;
        alert("Se realizo la copia de "+ iasignados +" usuarios"   )
        //setIsLoading(false)
      })
      .catch(e => {
        console.log(e);
      });
      handleClose()
      
             
    }
  }
  const columns = [
    {
      dataField: 'id_planestudio', text: 'Accion', formatter: (rowContent, row) => {
        let indicadorsoporte = currentUser?.indicador_soporte
        return (
          <>
            {indicadorsoporte ?
              <>
                <Link to={"/planestudioflujo?id=" + rowContent}
                  className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                  <i className="fa-solid   fa-list text-warning"></i>
                </Link>
              </> :
              <>
                <div className="btn-toolbar justify-content-between" role="toolbar"
                  aria-label="Toolbar with button groups">
                  <div className="btn-group" role="group" aria-label="First group">
                    <Link to={"/planestudioedit?id=" + rowContent}
                      className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                      <i className="fa-solid fa-edit   text-primary"></i>
                    </Link>
                    <Link to={"/planestudioflujo?id=" + rowContent}
                      className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                      <i className="fa-solid   fa-list text-warning"></i>
                    </Link>
                    <Link to={"/planimportar?id=" + rowContent}
                      className="btn btn-outline btn-outline-dashed btn-outline-success btn-icon btn-sm"               >
                      <i className="fa-solid   fa-file-excel "></i>
                    </Link>
                    <Link to={"/planestudiousuario?id=" + rowContent}
                      className="btn btn-outline btn-outline-dashed btn-outline-danger btn-icon btn-sm"               >
                     <i className="fa-solid fa-users-rectangle"></i>
                    </Link>
                  </div>
                </div>
              </>}

          </>
        )
      }
    },
    { dataField: 'denominacion', text: 'Denominación', sort: true, csvText: 'Plan' },
    {
      dataField: 'codigo_estado', text: 'Estado', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {rowContent == "Activo" ?
              <>
                <span className="badge badge-primary">{rowContent}</span>
              </> :
              <>
                {rowContent == "Inactivo" ?
                  <>
                    <span className="badge badge-danger">{rowContent}</span>
                  </> : <></>}
              </>}
          </>
        )
      }
    },
    { dataField: 'fecha_inicio', text: 'Inicio', sort: true },
    { dataField: 'fecha_fin', text: 'Fin', sort: true },
    { dataField: 'fecha_creacion', text: 'Creado' },
    { dataField: 'fecha_modificacion', text: 'Modificado' },

    { dataField: 'programas', text: 'Prog', sort: true },
    { dataField: 'cursos', text: 'Cur', sort: true },
    { dataField: 'modulos', text: 'Mod', sort: true },
    {
      dataField: 'temas', text: 'Tem', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {rowContent == "0" ?
              <>
                <span className="badge badge-danger">{rowContent}</span>
              </> :
              <>
                <span className="badge badge-primary">{rowContent}</span>
              </>}
          </>
        )
      }
    },
    { dataField: 'cuestionarios', text: 'Cuest', sort: true },
    { dataField: 'duracion', text: 'Duracion(Hora)', sort: true },
  ];
  useEffect(() => {
    PlanestudioDataService.getplanestudios()
      .then(response => {
        setPlan(response.data)
        console.log(response.data)
        setIsLoading(false)
      })
      .catch(e => {
        console.log(e);
      });

  }, [isLoading]);
  if (isLoading) {
    return <> <LoadingSpinner />  </>;
  }
  return (
    <div className="card card-flush">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            Listado de Planes de Estudio
          </div>
        </div>

        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 mw-150px">

          </div>
          {currentUser?.indicador_soporte?
          <>
          </>:
          <>
          <Link  to={"/planestudioedit"} 
            className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm">
            <i className="fa-solid fa-file "></i>
            Nuevo Plan
          </Link>
          <button type="button"  
            className="btn btn-outline btn-outline-dashed btn-outline-danger    btn-sm"
            onClick={handleClickOpen}><i className="fa-solid fa-copy"></i>
            Copiar Usuarios de Plan
          </button>
          </>}
           
          
        </div>
      </div>

      <div className="card-body pt-0">
        <div className="table-responsive">
          <ToolkitProvider
            keyField="id_planestudio"
            data={plan}
            columns={columns}
            search
            exportCSV={{
              fileName: 'plan-de-estudio.csv',
              separator: ';',
              noAutoBOM: false
            }}      >
            {
              props => (
                <div>
                  <SearchBar {...props.searchProps} placeholder="Buscar..." />
                  <button variant="warning" className="btn btn-success btn-sm"
                    onClick={(e) => exportToCSV(plan, 'UCJ-Plan de Estudio')}>
                    <i className="fa-solid fa-file-excel "></i>Exportar</button>
                  <hr />
                  <BootstrapTable className="table table-responsive table-bordered"
                    {...props.baseProps}
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
      </div>
      <Dialog
        open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" 
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"> {"Copiar Usuarios de Plan de Estudio a otro"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <form onSubmit={handleCopiarUsuarios} id="frmcopiarusuarios">
             
                 
                  <span className="label text-info">Seleccione Origen: </span>
                  <Select className="form-control"
                    placeholder="Plan de Estudio"
                    name="id_planestudio"
                    options={plan}
                    onChange={(e) => selPlan((prev) => ({ ...prev, ['id_planestudio']: e.id_planestudio}))}
                    getOptionValue={option => option.id_planestudio}
                    getOptionLabel={option => option.denominacion}
                  />
                
                  <span className="label text-info">Seleccione Destino: </span>
                  <Select className="form-control"
                    placeholder="Plan de Estudio"
                    name="id_planestudio"
                    options={plan}
                    onChange={(e) => selPlan((prev) => ({ ...prev, ['id_referencia']: e.id_planestudio}))}
                    getOptionValue={option => option.id_planestudio}
                    getOptionLabel={option => option.denominacion}
                  />
                
             
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button className="btn btn-primary" type="submit"  form="frmcopiarusuarios"   autoFocus>
            Copiar Usuarios
          </Button>
        </DialogActions>
      </Dialog>
    </div>



  );

};

