import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import TemaDataService from "../_services/tema";
import ModuloDataService from "../_services/modulo";
import { useAuth } from "../app/modules/auth";

//import { Pregunta } from "../_models/pregunta";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
function TemaModuloForm() {
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate();
    const [tema, setTemas] = useState([]);
    const [modulo, stModulos] = useState([]);
     const [asignacion, setAsignacion] = useState([]);
    useEffect(() => {
        TemaDataService.gettemas()
            .then(result => {
                setTemas(result.data);
                console.log(result);
            })
            .catch(e => {
                console.log(e);
            });
        ModuloDataService.getmodulos() 
             .then(result => {
                stModulos(result.data);
                console.log(result);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Guardar el Registro?");
        if (answer) {
            const listatema = document.getElementsByName("lista_tema");
            const listamodulo = document.getElementsByName("lista_modulo");
            var arrAsignacion=[]
            for (let i = 0; i < listatema[0].options.length; i++) {
                if (listatema[0].options[i].selected) {
                    for (let x = 0; x < listamodulo[0].options.length; x++) {
                        if (listamodulo[0].options[x].selected) {
                            arrAsignacion.push({
                                id_tema:listatema[0].options[i].value,
                                id_modulo: listamodulo[0].options[x].value 
                            })
                        }
                    }
                    
                    //console.log(listausuario[0].options[i].value)
                }
            }
            TemaDataService.createtemamodulo(JSON.stringify(arrAsignacion))
                    .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            alert("Registro Actualizado correctamente");
                            navigate('/tema');
                        })
                    .catch(function (error) {
                            console.log(error);
                        });
            
        }
    }
    return (
        <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Asociar Temas y Modulos</h2>
                    </div>
                </div>
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    </div>
                </div>
            </div>
            <div className='card-body pt-2'>
                <form onSubmit={handleSubmit}   >
                     
                    <div className='row g-5 g-xl-8'>
                        <div className='col-xl-6'>
                            <span>Listado de Temas</span>
                            <select multiple name={"lista_tema"} className="form-control h-350px"  >
                                {tema.map((option) => (
                                    <option value={option.id_tema}>{option.titulo}</option>
                                ))}
                            </select>

                        </div>
                        
                        <div className='col-xl-6'>
                            <span>Listado de Modulos</span>
                            <select multiple className="form-control" name={"lista_modulo"}    >
                                {modulo.map((option) => (
                                    <option value={option.id_modulo}>{option.titulo}</option>
                                ))}
                            </select>

                        </div>
                        <div className="mb-10">

                            <Link to={"/pregunta"}
                                className="btn btn-icon-white btn-text-white btn-danger">
                                <i className="fa-solid fa-reply "></i>
                                Cancelar
                            </Link>
                            <button className='btn btn-primary' type="submit">
                                <i className="fa-solid fa-floppy-disk"></i>
                                Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default TemaModuloForm;
