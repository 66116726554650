
import React, { Component ,useEffect,useState} from "react";
import { Link ,useNavigate} from "react-router-dom";
import ContenidoDataService from "../_services/contenido";
import { useAuth } from "../app/modules/auth";
import TemaDataService from "../_services/tema";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function ContenidoForm()  {
  const { currentUser, logout } = useAuth()

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idcontenido = queryParameters.get("id")
  const handledelete = async (e)=>{
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idcontenido !== null) {
        ContenidoDataService.deletecontenido(idcontenido)
        .then(result => {
            //setResult(result.data);
            console.log(result.data);
            if (result.data.result=='0'){
                alert("Registro Eliminado con Éxito" )
                navigate('/contenido');
            }else {
                alert("Registro no se puede eliminar por dependencias : \n"+  result.data.message )
            }
            //console.log(resultado);

        })
        .catch(e => {
            console.log(e);
        });
         
      }
      
    }
  }
  const handleSubmit = async (e ) => {
            e.preventDefault();
            const answer = window.confirm("Esta seguro de Guardar el Registro?");
            if (answer) {
              console.log(contenidos);
              if (idcontenido  == null){
                contenidos.usuario_creacion=currentUser?.username
                contenidos.codigo_estado='1'
                ContenidoDataService.createcontenido(contenidos)
                .then(function (response) {
                  console.log(JSON.stringify(response.data));
                  alert("Registro Insertado correctamente");
                  navigate('/contenido');
                })
                .catch(function (error) {
                  console.log(error);
                });
              } else {
                contenidos.usuario_modificacion=currentUser?.username
                contenidos.id_contenido=idcontenido
                ContenidoDataService.updatecontenido(contenidos)
                .then(function (response) {
                  console.log(JSON.stringify(response.data));
                  alert("Registro Actualizado correctamente");
                  navigate('/contenido');
                })
                .catch(function (error) {
                  console.log(error);
                });
               
              }
            }
    };
    const handleChange = (e ) => {
             console.log();
            // console.log(e.target.value);
            // setTodo({ ...todo, [e.target.name]: e.target.value });
            setContenidos((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
    };
    const [contenidos, setContenidos] = useState([]);
    const [temas, setTemas] = useState([]);

         useEffect(() => {
          if (idcontenido  !== null){
            fetch(API_BASE_URL+'contenido/sel/'+ idcontenido, {
              method: 'GET',headers: {'Content-Type': 'application/json'}
            })
                .then(response => response.json())
                .then(result => {
                    setContenidos(result);
                    console.log(result);
                })
                .catch(e => {
                console.log(e);
                });
          }
          TemaDataService.gettemas()
                  .then(result => {
                    setTemas(result.data);
                    //console.log(response);
                    
                  });
          }, []);
        return (
            <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Editar Contenido</h2>
                    </div>
                </div>

                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    
                    </div>
                        
                        <Link to={"/contenido"} 
                        className="btn btn-icon-white btn-text-white btn-primary">
                          <i className="fa-solid fa-right-to-bracket"></i>Contenido
                        </Link>
                </div>
            </div>
            <div className="card-body pt-10">
                <form   onSubmit={handleSubmit}>
                    <div className="input-group input-group-sm mb-5">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Tema</span>
                      <select value={contenidos.id_tema}
                        className="form-control mb-2"
                        name="id_tema"
                        onChange={handleChange}>
                        <option value=""> -- Seleccione -- </option>
                        {temas.map((parametro) => <option value={parametro.id_tema}>{parametro.titulo}</option>)}
                      </select>
                    </div>
                        <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Tipo</span>
                                <select className="form-control" required name="codigo_tipo" 
                                value={contenidos.codigo_tipo}
                                onChange={handleChange} >
                                <option value=""> -- Seleccione -- </option>
                                  <option value={"video"}>Video</option>
                                  <option value={"pdf"}>PDF</option>
                                  <option value={"audio"}>Audio</option>
                                </select>
                        </div>
                        <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">url</span>
                                <input type="text" name="url"   defaultValue={contenidos.url} 
                                 className="form-control" onChange={handleChange}  />
                        </div>
                        
                        
                    <div className="mb-10">
                         
                        <Link to={"/contenido"} 
                        className="btn btn-icon-white btn-text-white btn-danger">
                        <i className="fa-solid fa-reply "></i>
                        Cancelar
                        </Link>
                        <button className='btn btn-primary' type="submit">
                        <i className="fa-solid fa-floppy-disk"></i>
                        Guardar</button>
                    </div>
                        
                </form>
            </div>
            <div className="card-footer">
                <button type="button"   disabled={idcontenido>0? false:true}
                    className="btn btn-outline btn-outline-dashed btn-outline-default btn-sm"  
                    onClick={handledelete}
                >
                Eliminar Registro
                </button>
                
            </div>
        </div>
        );
    }
