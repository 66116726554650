import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UsuarioDataService from "../_services/usuario";
import { useAuth } from "../app/modules/auth";

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function UsuarioFormPassword() {
  const { currentUser, logout } = useAuth()
  const [usuarios, setUsuarios] = useState([]);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idusuario = queryParameters.get("id")
  const handleSubmit = async (e) => {
    e.preventDefault();
    const passcurrent = usuarios.codigo_grupo
    if (inputValue1 === inputValue2 ) {
      if (inputValue1 === passcurrent ) {
        alert("Las contraseña no puede ser la misma que la actual");
      }else {
        const answer = window.confirm("Esta seguro de Guardar el Registro?");
        if (answer) {
            usuarios.usuario_modificacion = currentUser?.username
            usuarios.contrasenia=inputValue1
            UsuarioDataService.updateUsuarioPassword(usuarios)
              .then(function (response) {
                //console.log(JSON.stringify(response.data));
                alert("Registro Actualizado correctamente");
                navigate('/');
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      }
      
    } else {
      alert("Las contraseñas son diferentes");
    }
    
  };
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [inputsMatch, setInputsMatch] = useState(true);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "contrasenia") {
      setInputValue1(value);
    } else if (name === "contrasenia_valida") {
      setInputValue2(value);
    }

    if (inputValue1 === inputValue2) {
      setInputsMatch(true);
    } else {
      setInputsMatch(false);
    };
  };
 
  useEffect(() => {
      if (idusuario  !== null){
        UsuarioDataService.getUsuarioById(idusuario)
          .then(response => response.json())
          .then(result => {
            setUsuarios(result);
          })
          .catch(e => {
            console.log(e);
          });
      }
    
  }, []);
  return (
    <div className="container mt-2">
      <form onSubmit={handleSubmit}>

        <div className="input-group input-group-sm mb-5">
          <span className="input-group-text" id="inputGroup-sizing-sm">Nombres </span>
          <input type="text" name="codigo_usuario" defaultValue={usuarios.nombres }
            className="form-control"  readOnly />
        </div>
        <div className="input-group input-group-sm mb-5">
          <span className="input-group-text" id="inputGroup-sizing-sm">Apellidos</span>
          <input type="text" name="codigo_usuario" defaultValue={ usuarios.apellido_paterno}
            className="form-control"  readOnly />
        </div>
        <div className="input-group input-group-sm mb-5">
          <span className="input-group-text" id="inputGroup-sizing-sm">Nueva Contraseña</span>
          <input type="password" name="contrasenia" required  maxLength={20}
            className="form-control" onChange={handleInputChange} />
        </div>
        <div className="input-group input-group-sm mb-5">
          <span className="input-group-text" id="inputGroup-sizing-sm">Repetir Contraseña</span>
          <input type="password" name="contrasenia_valida" required maxLength={20}
            className="form-control" onChange={handleInputChange} />
        </div>
          


        <Link to={"/dashboardusuario"}
          className="btn btn-icon-white btn-text-white btn-danger">Cancelar
        </Link>
        <button className='btn btn-primary' type="submit">Guardar</button>

      </form>
    </div>
  );
}

