import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import UsuarioDataService from "../_services/usuario";
import ParametroDataService from "../_services/parametro";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from "../app/modules/auth";


 

const columns = [
    { dataField: 'empresa', text: 'Empresa', sort: true, filter: textFilter() ,style: {fontSize: '11px'}},
    { dataField: 'codigo_usuario', text: 'Código', sort: true, filter: textFilter() ,style: {
         
        fontSize: '11px'
      } },
    { dataField: 'nombres', text: 'Personal', formatter: (rowContent, row) => {
            return (
                <>
                    <span className="font-size-sm ">{row["nombres"]} {row["apellido_paterno"]} {row["apellido_materno"]}</span>
                </>
            )
        }, filter: textFilter() ,style: {fontSize: '11px'}
    },
    { dataField: 'correo_electronico', text: 'Email', sort: true, filter: textFilter(),style: {fontSize: '11px'} },
    { dataField: 'centro_costo', text: 'Centro Costo', sort: true, filter: textFilter(),style: {fontSize: '11px'} },
    { dataField: 'codigo_grupo', text: 'Grupo', sort: true, filter: textFilter() ,style: {fontSize: '11px'}},
    { dataField: 'cargo', text: 'Cargo', sort: true, filter: textFilter(),style: {fontSize: '11px'}},
    { dataField: 'fecha_ingreso', text: 'Fecha Ingreso', sort: true,style: {fontSize: '11px'}},
];

export default function UsuarioGrupoForm() {

    const { currentUser, logout } = useAuth()
    const [usuarios, setUsuarios] = useState([]);
    const [isOnline, setIsOnline] = useState(null);
    const [grupos, setGrupos] = useState([]);
    const [asignacion, setAsignacion] = useState([]);
    const navigate = useNavigate();
    const [seleccionados, setSelected] = useState([]);
    const [flag, setFlag] = useState(false);
    const [open, setOpen] = React.useState(false);
    
    const options = {
        
        sizePerPageList: [{
          
          text: '10', value: 10
        } , {
            text: '100', value: 100
          }, {
            text: '250', value: 250
          }, {
            text: '500', value: 500
          }] // A numeric array is also available. the purpose of above example is custom the text
      };
    const handleClickOpen = () => {
        setFlag(false);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const alertselected = () => {
        console.log(selectedRow)
        console.log(seleccionados)
    }
    const handleselgrupo = (event, id) => {
        //console.log(event.target)
        //console.log(event.target.value)
        setFlag(true);
        asignacion.push({
            "codigo_grupo": event.target.value
        })

    };
    const handleSubmit = async (e ) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Asignar los Usuarios?");
        if (answer) {
             handleClose();
            let arrasigna=[]
            if (flag) {
                console.log(seleccionados)
                console.log(asignacion)
                for (var i = 0, l = seleccionados.length; i < l; i++) {
                    for (var y = 0, l = asignacion.length; y < l; i++) {
                        arrasigna.push({
                            "codigo_grupo":asignacion[y],
                            "id_usuario":seleccionados[i]
                        })
                    }
                }
            }
            console.log(arrasigna)
           
    
           
        }
    }
    function asignarusuarios   (e) {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Asignar los Usuarios?");
        if (answer) {
             handleClose();
            let arrasigna=[]
            if (flag) {
                console.log(seleccionados)
                console.log(asignacion)
                for (var i = 0, ii = seleccionados.length; i < ii; i++) {
                    for (var y = 0, yy = asignacion.length; y < yy; y++) {
                        arrasigna.push({
                            "codigo_grupo":asignacion[y].codigo_grupo,
                            "id_usuario":seleccionados[i].id_usuario
                        })
                    }
                }
            }
            console.log(JSON.stringify(arrasigna))
            UsuarioDataService.createUsuarioGrupo(JSON.stringify(arrasigna)) 
            .then(function (response) {
                console.log(response);
                alert("Asignado correctamente");
                window.location.reload(false);
            })
            .catch(e => {
                console.log(e);
            });
           
        }
        
    };
    const selectedRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            //console.log('=== selectRow row ===', row);
            if (isSelect) {
                seleccionados.push({
                    "id_usuario": row["id_usuario"]
                })
            } else {
                const idindex = seleccionados.findIndex(element => element.id_usuario === row["id_usuario"]);
                seleccionados.splice(idindex, 1)
            }


            //console.log('=== selectRow event ===', e);
        },
        onSelectAll: (isSelected, rows) => {
            //console.log('=== selectRow row ===', rows[0]);
            //console.log(isSelected)
            //console.log(rows)
            if (isSelected) {
                for (let i = 0; i < rows.length; i++) {
                    //console.log('=== selectRow row ===', rows[i]);
                    seleccionados.push({
                        "id_usuario": rows[i]["id_usuario"]
                    })
                }
            } else {
                //let filteredArray = seleccionados.filter(item => item.id_usuario !== idprograma)
                // let arrfiltro=[]
                for (let i = 0; i < rows.length; i++) {
                    // arrfiltro.push({
                    //    "id_usuario":rows[i]["id_usuario"]
                    //})
                    const idindex = seleccionados.findIndex(element => element.id_usuario === rows[i]["id_usuario"]);
                    seleccionados.splice(idindex, 1)
                }

                //console.log(arrfiltro)

                //console.log(filtrarusuarioasignado(seleccionados,arrfiltro))
            }
            console.log(seleccionados)
        }
    };
 
    useEffect(() => {
        UsuarioDataService.getUsuariosAsignar()
            .then(response => {
                setUsuarios(response.data);
                //console.log(response.data)
                setIsOnline(true)
            })
            .catch(e => {
                console.log(e);
            });
        ParametroDataService.getparametrosbycod('grupo_usuario')
            .then(response => {
                setGrupos(response.data);
                console.log(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    }, [isOnline]);
    if (isOnline === null) {
        return <>'Cargando...'</>;
    }
    
    return (
        <>
            <div className="card card-custom">
                <form onSubmit={handleSubmit}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <h2>Enlaza Grupos y Usuarios</h2>
                        </div>
                    </div>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                        <div className="w-100 mw-150px">
                        </div>
                        <Link to={"/usuario"}
                            className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        
                        <Button className="btn btn-primary btn-sm"
                            onClick={handleClickOpen}> 
                            <i className="fa-solid fa-floppy-disk"></i>
                            Asignar
                        </Button>
                    </div>
                </div>
                <div className='card-body pt-2'>
                    <div className='row '>
                        <div className='col-lg-12'> 
                        
                            <BootstrapTable
                                className={"table table-responsive table-bordered"}
                                headerWrapperClasses="foo"
                                bordered={true}
                                keyField='id_usuario'
                                data={usuarios}
                                columns={columns}
                                filter={filterFactory()}
                                selectRow={selectedRow}
                                pagination={ paginationFactory(options) }
                            />
                          

                        </div>


                    </div>
                </div>
                </form>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Seleccione el Grupo a Asignar:"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <select defaultValue={usuarios.codigo_grupo}
                            className="form-control mb-2"
                            name="codigo_grupo" onChange={handleselgrupo} >
                             <option value=""> -- Seleccione -- </option>
                            {grupos.map((parametro) => <option value={parametro.codigo_parametro}>{parametro.parametro}</option>)}

                            </select>
                       
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button className="btn btn-primary btn-sm" onClick={asignarusuarios} autoFocus>
                        Asignar Usuarios a Grupo
                    </Button>
                </DialogActions>
            </Dialog>
            
        </>
    );

}
