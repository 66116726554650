import axios from 'axios';
import React, {Component} from 'react';

import { Usuario } from '../_models/usuario';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/email";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/email";
class EmailService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
     
    bienvenidausuario(data){
        return axios.post( API_BASE_URL+ '/bienvenida',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    asignausuario(data){
        return axios.post( API_BASE_URL+ '/asigna',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
     
}

export default new EmailService()