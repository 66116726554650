
 
import axios from 'axios';
import React, {Component} from 'react';

 //const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/reporte";
 class ReporteService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getseguimientoempresa(){
        return axios.get( API_BASE_URL+ '/empresa', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getreportenps(){
        return axios.get( API_BASE_URL+ '/nps', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getreportelinea_tiempo(){
        return axios.get( API_BASE_URL+ '/linea_tiempo', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getusuarioxempresa(data){
        return axios.post( API_BASE_URL+ '/usuario/empresa',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    
    obtener_parametros(data){
        return axios.post( API_BASE_URL+ '/select',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getgeneralempresa(data){
        return axios.post( API_BASE_URL+ '/general/empresa',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getgeneralempresa_cols(data){
        return axios.post( API_BASE_URL+ '/general/empresa/cols',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getgeneraldptoceco(data){
        return axios.post( API_BASE_URL+ '/general/dpto',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getgeneraldptoceco_cols(ide,idp){
        return axios.get( API_BASE_URL+ '/general/dpto/cols/'+ide+'/'+idp, {
            headers: {
                'Content-Type': 'application/json'
            }
        })  
    }
    
}

export default new ReporteService()