import PaginaPdf from "../../../_components/certificadopdf"
import { PDFViewer } from '@react-pdf/renderer';
 
export function CertificadoVisorPDF  () {
    
   return (
       <> 
        <PaginaPdf  />
       </>
   )
   
}