
import { Link } from "react-router-dom"
import ProgramaSeguimiento from "../../../_components/programaseguimiento";

export function ProgramaSeguimientoPage  () {
  
  return (
    <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-lg-12">
                          
                            <ProgramaSeguimiento></ProgramaSeguimiento>
                    </div>
                </div>
            </div>
        </div>
  );
}