
 
import axios from 'axios';
import React, {Component} from 'react';
import { configuracion } from '../_models/configuracion';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/configuracion";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/configuracion";
class ConfiguracionService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getconfiguracion(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createconfiguracion(configuracion){
        return axios.post(API_BASE_URL+ '/ins', configuracion,{
            headers: {'Content-Type': 'application/json'}
        })}
    getconfiguracionById(configuracionId){
        return axios.get(API_BASE_URL + '/sel/' + configuracionId);
    }
    updateconfiguracion( configuracion){
        return axios.post(API_BASE_URL + '/upd/' , configuracion,{
            headers: {'Content-Type': 'application/json'}
        }) }

    deleteconfiguracion(configuracionId){
        return axios.delete(API_BASE_URL + '/' + configuracionId);
    }
}

export default new ConfiguracionService()