
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PlanestudioDataService from "../_services/planestudio";
import ProgramaDataService from "../_services/programa";
import { useAuth } from "../app/modules/auth";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function PlanestudioForm() {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idplanestudio = queryParameters.get("id")
  const [planestudios, setPlanestudios] = useState([]);
  const [programa, setPrograma] = useState([]);

  const [programas, setProgramas] = useState([]);
  const [temas, setTemas] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [arrtemas, setProgramasPlanestudio] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openform, setOpenForm] = React.useState(false);
  const handledelete = async (e) => {
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idplanestudio !== null) {
        console.log(idplanestudio)
        PlanestudioDataService.deleteplanestudio(idplanestudio)
          .then(result => {
            console.log(result.data)
            if (result.data.result == '0') {
              alert("Registro Eliminado con Éxito")
              navigate('/planestudio');
            } else {
              alert("Registro no se puede eliminar por dependencias : \n" + result.data.message)
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }
  const handleClickOpen = () => {
    setFlag(false);
    setOpen(true);
  };
  const handleClickOpenFOrm = () => {
    //setFlag(false);
    setOpenForm(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    const answer = window.confirm("Esta seguro de Guardar el Registro?");
    if (answer) {
      console.log(planestudios);
      if (idplanestudio == null) {
        planestudios.usuario_creacion = currentUser?.username
        //planestudios.codigo_estado = '1'
        PlanestudioDataService.createplanestudio(planestudios)
          .then(function (response) {
            for (var i = 0, l = respuestas.length; i < l; i++) {
              respuestas[i].id_planestudio = response.data.id_planestudio
            }
            ProgramaDataService.createprogramaplan(respuestas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Insertado correctamente");
            navigate('/planestudio');
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        planestudios.usuario_modificacion = currentUser?.username
        planestudios.id_planestudio = idplanestudio
        for (var i = 0, l = respuestas.length; i < l; i++) {
          respuestas[i].id_planestudio = idplanestudio
        }
        PlanestudioDataService.updateplanestudio(planestudios)
          .then(function (response) {
            ProgramaDataService.createprogramaplan(respuestas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Actualizado correctamente");
            navigate('/planestudio');
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
  };
  const handleChangePrograma = (e) => {
    setPrograma((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChange = (e) => {
    setPlanestudios((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckedRow = (event, id, titulo) => {
    setFlag(true);
    arrtemas.push({
      "id_planestudio_programa": 0,
      "id_programa": id,
      "titulo": titulo
    })

  };
  function filtrarTemaAsignado(temas_, filtros) {
    return temas_.filter((curso) => !filtros.includes(curso.id_programa));
  }
  function asignartemas(temas, filtros) {
    return temas.filter((item) =>
      filtros.some((filtro) => filtro.id_programa === item.id_programa)
    );
  }
  const registrarprograma = async (e) => {
    e.preventDefault();
    console.log(e.target);
    const answer = window.confirm("Esta seguro de Registrar el Programa?");
    if (answer) {
      programa.usuario_creacion = currentUser?.username
      programa.codigo_estado = '1'
      programa.indicador_obligatorio = 'off'
      programa.numero_orden = ''
      console.log(programa)
      ProgramaDataService.createprograma(programa)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          programas.push({
            "id_programa": response.data.id_programa,
            "codigo": programa.codigo,
            "titulo": programa.titulo,
            "descripcion": programa.descripcion
          })
          console.log(programas)
        })
        .catch(function (error) {
          console.log(error);
        });
      handleCloseForm();
    }

  }
  function seleccionartema() {
    if (flag) {
      var arrfiltro = []
      var arrcontenido = []
      //console.log(asignartemas(temas,arrtemas))
      for (var i = 0, l = arrtemas.length; i < l; i++) {
        let idprograma = arrtemas[i].id_programa
        let index = arrcontenido.findIndex(object => object.id_programa === idprograma);
        if (index === -1) {
          arrcontenido.push({
            "id_planestudio_programa": 0,
            "id_programa": arrtemas[i].id_programa,
            "titulo": arrtemas[i].titulo
          });
          arrfiltro.push(idprograma)
          setRespuestas(arrcontenido)
        }
      }
      setProgramas(filtrarTemaAsignado(programas, arrfiltro))
    }
    handleClose();
  };

  const handleClick = (event, idprograma, title) => {
    event.preventDefault();
    //console.log(idprograma);
    let filteredArray = respuestas.filter(item => item.id_programa !== idprograma)
    //console.log(filteredArray);
    setRespuestas(filteredArray);
    programas.push({
      "id_programa": idprograma,
      "titulo": title
    })

  };
  useEffect(() => {
    if (idplanestudio !== null) {
      ProgramaDataService.getplanprograma(idplanestudio)
        .then(result => {
          setProgramas(result.data)
          console.log(result.data)
        });
      PlanestudioDataService.getplanestudioById(idplanestudio)
        .then(response => response.json())
        .then(result => {
          setPlanestudios(result);
          //console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
      PlanestudioDataService.getplanprograma(idplanestudio)
        .then(function (response) {
          console.log(response.data)
          setProgramasPlanestudio(response.data)
          for (var i = 0, l = response.data.length; i < l; i++) {
            respuestas.push({
              "id_planestudio_programa": response.data[i].id_tema_modulo,
              "id_programa": response.data[i].id_programa,
              "titulo": response.data[i].titulo
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      ProgramaDataService.getprogramas('1')
        .then(result => {
          setProgramas(result.data)
          //console.log(result.data)
        });
    }
  }, []);
  return (
    <div className="card card-custom">
      <form onSubmit={handleSubmit}>
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <h2>Editar Planestudio</h2>
            </div>
          </div>

          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">

            <Link to={"/planestudio"}
              className="btn btn-icon-white btn-text-white btn-danger btn-sm">
              <i className="fa-solid fa-reply "></i>
              Volver
            </Link>
            <button className='btn btn-primary btn-sm' type="submit">
              <i className="fa-solid fa-floppy-disk"></i>
              Guardar</button>
            <Button className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success btn-sm" onClick={handleClickOpen}>
              <><i className="fa-solid fa-floppy-disk"></i></>
              Agregar  Programa
            </Button>
            <Button className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm" onClick={handleClickOpenFOrm}>
              <><i className="fa-solid fa-file"></i></>
              Nuevo  Programa
            </Button>
          </div>
        </div>
        <div className="card-body pt-10">
          <div className="form-group row">
            <div className="col-lg-12   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Denominacion</span>
                <input type="text" name="denominacion" maxLength={"250"}
                  required defaultValue={planestudios.denominacion}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="col-lg-4   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Fecha_inicio</span>
                <input type="date" name="fecha_inicio" maxLength={"8"} required defaultValue={planestudios.fecha_inicio}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="col-lg-4   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Fecha_fin</span>
                <input type="date" name="fecha_fin" maxLength={"8"} required defaultValue={planestudios.fecha_fin}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="col-lg-4   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Estado</span>
                <select className="form-control" onChange={handleChange}
                  value={planestudios.codigo_estado} required name="codigo_estado">
                  <option >-- Seleccione --</option>
                  <option value="1">Activo</option>
                  <option value="2">Inactivo</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Notifica Nivel1</span>
                <input type="number" name="notifica_nivel1" maxLength={"2"} required 
                  defaultValue={planestudios.notifica_nivel1}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="col-lg-4   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Notifica Nivel2</span>
                <input type="number" name="notifica_nivel2" maxLength={"2"} required 
                  defaultValue={planestudios.notifica_nivel2}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="col-lg-4   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Notifica Nivel3</span>
                <input type="number" name="notifica_nivel3" maxLength={"2"} required 
                  defaultValue={planestudios.notifica_nivel3}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="card-footer bg-gray-100 border-top-0 text-right">

        <div className="col-lg-12 ">
          <form  >
            <div className="table table-rounded table-striped border table-responsive">
              <table className="table"  >
                <thead>
                  <tr className="fw-bold fs-6 border border-gray-200">
                    <th>Nro</th>
                    <th>Programa</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {respuestas.map((item, index) => (
                    <>
                      <tr>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          <Link to={"/programaedit?id=" + item.id_programa}
                            className="text-primary">
                            {item.titulo}
                          </Link>
                        </td>
                        <td>
                          <button className='btn btn-icon btn-danger'
                            onClick={event => handleClick(event, item.id_programa, item.titulo)}
                            type="submit" >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <button type="button" style={idplanestudio > 0 ? { display: "block" } : { display: "none" }}
          className="btn btn-outline btn-outline-dashed btn-outline-danger   btn-sm"
          onClick={handledelete}
        >
          Eliminar Plan de Estudio
        </button>
      </div>
      <Dialog
        open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"> {"Seleccione los programas asociados al planestudio"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <table className="table">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800">
                  <th>Sel</th>
                  <th>programa</th>
                </tr>
              </thead>
              <tbody>
                {programas.map((item) => (
                  <>
                    <tr>
                      <td><input type="checkbox" value={item.id_programa} className="form-check"
                        name={"id_programa" + item.id_programa}
                        onChange={(event) => handleCheckedRow(event, item.id_programa, item.titulo)}></input></td>
                      <td>{item.titulo}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button className="btn btn-primary" onClick={seleccionartema} autoFocus>
            Seleccionar Programas
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openform}
        onClose={handleCloseForm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"> {"Nuevo Programa"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={registrarprograma} id="frmprograma">
              <div className="form-group row">
                <div className="col-lg-12   input-group-sm mb-5">
                  <div className="input-group input-group-sm  ">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Codigo</span>
                    <input type="text" name="codigo"
                      required onChange={handleChangePrograma}
                      className="form-control" />
                  </div>
                </div>
                <div className="col-lg-12   input-group-sm mb-5">
                  <div className="input-group input-group-sm  ">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Titulo</span>
                    <input type="text" name="titulo"
                      required onChange={handleChangePrograma}
                      className="form-control" />
                  </div>
                </div>
                <div className="col-lg-12   input-group-sm mb-5">
                  <div className="input-group input-group-sm  ">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Descripcion</span>
                    <input type="text" name="descripcion" required
                      onChange={handleChangePrograma}
                      className="form-control" />
                  </div>
                </div>
              </div>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cerrar</Button>
          <Button className="btn btn-primary" type="submit" form="frmprograma" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
