import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import TemaDataService from "../_services/tema";
import PreguntaDataService from "../_services/pregunta";
import ContenidoDataService from "../_services/contenido";
import EvaluacionDataService from "../_services/evaluacion";
import axios from "axios";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function TemaUsuario() {
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const idtema = queryParameters.get("id")
    const [tema, setTema] = useState([]);
    const [preguntas, setPregunta] = useState([]);
    const [respuestas, setRespuestas] = useState([]);
    const [contenido, setContenido] = useState([]);
    const [idevaluacion, setEvaluacion] = useState([]);
    const handleSubmit = async (e ) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Guardar el Registro?");
        if (answer) {
            
                const requestEvaluacion= {
                    method: 'POST',headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id_tema: idtema, id_usuario:currentUser?.id,
                        codigo_estado: 1,usuario_creacion: currentUser?.username
                    })
                };
                fetch(API_BASE_URL + 'evaluacion/ins', requestEvaluacion)
                .then(response => response.json())
                .then((data) => {
                    console.log(data);
                    setEvaluacion(data.id_evaluacion)
                    idevaluacion=data.id_evaluacion;
                    for (var i = 0, l = respuestas.length; i < l; i++) {
                        const requestDetalleEvaluacion = {
                            method: 'POST',headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                id_evaluacion:data.id_evaluacion,
                                id_pregunta: respuestas[i].id_pregunta,
                                respuesta: respuestas[i].respuesta
                            })
                        };
                        fetch(API_BASE_URL + 'detalle/ins', requestDetalleEvaluacion)
                        .then(() => {
                            console.log("Todo bien");
                            navigate('/temaresultado?id='+ idevaluacion);
                        })
                        .catch(() => console.log("Algo falló"))
                        //.then(response => response.json())
                        //.then(data => console.log(data))
                    } 
                   
                    //navigate('/temaresultado?id='+ idevaluacion);
                })
                .catch((error) => console.log('Hubo un problema  :' + error.message))
                alert("Cuestionario Enviado correctamente");
                navigate('/temaresultado?id='+ idevaluacion);
        }
    }
    useEffect(() => {
        if (idtema !== null){
            fetch(API_BASE_URL+'pregunta/sellst/tema/'+ idtema, {
                method: 'GET',headers: {'Content-Type': 'application/json'}
              })
                  .then(response => response.json())
                  .then(result => {
                        setPregunta(result);
                        console.log(result);
                  })
                  .catch(e => {
                  console.log(e);
                  });
           
            TemaDataService.gettemaById(idtema)
            .then(result => {
                setTema(result.data);
                console.log(result.data);
            })
            .catch(e => {
                console.log(e);
            });
            ContenidoDataService.getcontenidoByTema(idtema)
            .then(result => {
                setContenido(result.data);
                console.log(result.data);
            })
            .catch(e => {
                console.log(e);
            });
        }
        if (idevaluacion.length>0){
            navigate('/temaresultado?id='+ idevaluacion[0]);
        }
    }, []);
    function handleChange(event) {
        //console.log(event.target); 
        const rptaexiste = respuestas.filter((t) => t.id_pregunta === event.target.name);
        //const index = respuestas.findIndex((emp) => emp.id_pregunta === event.target.name);
        if (rptaexiste.length > 0) {
            //alert('Ya se respondio')
            const index = respuestas.map((itemf) => {
                if (itemf.id_pregunta == event.target.name) {
                    itemf.respuesta = event.target.value;
                }
            })
            //console.log('d')
        } else {
            respuestas.push({
                "id_tema": idtema, "id_pregunta": event.target.name,
                "respuesta": event.target.value
            })
        }
    }
    function evaluacion(data){
        return fetch(API_BASE_URL + 'evaluacion/ins',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res) => res.json());
    }
    function enviarevaluacion() {
        const answer = window.confirm("Esta seguro de Enviar las Respuestas?");
         
        if (answer) {
            console.log('ddd');
                const dataeval = ({id_tema: idtema, id_usuario:currentUser?.id,
                    codigo_estado: 1,usuario_creacion: currentUser?.username})
                EvaluacionDataService.createevaluacion(dataeval)
                const requestEvaluacion= {
                    method: 'POST',headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        id_tema: idtema, id_usuario:currentUser?.id,
                        codigo_estado: 1,usuario_creacion: currentUser?.username
                    })
                };
                fetch(API_BASE_URL + 'evaluacion/ins', requestEvaluacion)
                .then(response => response.json())
                .then((data) => {
                    console.log("Todo bien");
                    //idevaluacion=result.data.id_evaluacion;
                    setEvaluacion(data.id_evaluacion)
                    for (var i = 0, l = respuestas.length; i < l; i++) {
                        const requestDetalleEvaluacion = {
                            method: 'POST',headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                id_evaluacion:idevaluacion,
                                id_pregunta: respuestas[i].id_pregunta,
                                respuesta: respuestas[i].respuesta
                            })
                        };
                        fetch(API_BASE_URL + 'detalle/ins', requestDetalleEvaluacion)
                        .then(() => {
                            console.log("Todo bien");
                            navigate('/temaresultado?id='+ idevaluacion);
                        })
                        .catch(() => console.log("Algo falló"))
                        //.then(response => response.json())
                        //.then(data => console.log(data))
                    }
                   
                })
               // .catch((error) => console.log('Hubo un problema  :' + error.message))
               alert("Cuestionario Enviado correctamente");
              
                //navigate('/temaresultado?id='+ idevaluacion);
        }
        
    }
    
    return (
        <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Tema por Usuario</h2>
                    </div>
                </div>
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    </div>
                </div>
            </div>
            <div className='card-body pt-2'>
                <form onSubmit={handleSubmit}  >
                    <div className="input-group input-group-sm mb-5">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                            Tema</span>
                        <input type="text" name="pregunta" readOnly defaultValue={tema.titulo} 
                            className="form-control " />
                    </div>
                    <div className='row  '>
                        <div className='col-xl-12'>
                            <span>Video</span>
 
                            <iframe className='form-control ' height={350}
                                title='Youtube player'
                                src={contenido.url}
                            >
                            </iframe>
                        </div>
                        <div className='col-xl-12'>
                            <form>
                            <h2>Preguntas</h2>
                                <div className="mb-10">
                                    {preguntas.map((preg,index) => (
                                        <div className="form-group">
                                            <label className="text-primary">{index+1} .{preg.pregunta}</label>
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="radio" value={"1"}
                                                    name={preg.id_pregunta} onChange={handleChange} required />
                                                <label className="form-check-label"   >
                                                    {preg.rpta_a}
                                                </label>
                                                <input className="form-check-input" type="radio" value={"2"}
                                                    name={preg.id_pregunta} onChange={handleChange} required />
                                                <label className="form-check-label"  >
                                                    {preg.rpta_b}
                                                </label>
                                                <input className="form-check-input"
                                                    name={preg.id_pregunta} type="radio" onChange={handleChange} 
                                                    value={"3"} required
                                                />
                                                <label className="form-check-label"  >
                                                    {preg.rpta_c}
                                                </label>
                                                <input className="form-check-input"
                                                    name={preg.id_pregunta} type="radio" onChange={handleChange} 
                                                    value={"4"} required
                                                />
                                                <label className="form-check-label"   >
                                                    {preg.rpta_d}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="mb-10">
                                    <Link to={"/tema"}
                                        className="btn btn-icon-white btn-text-white btn-danger">
                                        <i className="fa-solid fa-reply "></i>
                                        Cancelar
                                    </Link>

                                    <button className='btn btn-primary' type="submit"   >
                                        <i className="fa-solid fa-floppy-disk"></i>
                                        Guardar</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </form>
            </div>
            <div className="card-footer">
            
            </div>
        </div>
    );
}
 