
import React, { Component ,useEffect,useState} from "react";
import AsignacionDataService from "../_services/asignacion";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { useAuth } from "../app/modules/auth";

const { SearchBar } = Search;

const columns = [
{   dataField: 'id_tema',   text: 'Ver' ,formatter: (rowContent, row) => {
  return (
    <>
      <Link to={{
        pathname: "/evaluacionedit" ,
        search:   '?id='+ row["id_tema"]
      + '&idpe='+ row["id_planestudio"]
      +'&idp='+ row["id_programa"]
      +'&idc='+ row["id_curso"]
      +'&idm='+ row["id_modulo"]
      }}              
        className="btn btn-outline btn-outline-dashed  btn-outline-danger btn-icon">
        <i className="fa-solid fa-eye text-danger fs-4 me-1"></i>
      </Link>
    </>
  )
} }, 
      {   dataField: 'fecha_creacion',   text: 'Fecha Asignado',sort:true },                   
      {   dataField: 'plan',   text: 'Plan',sort:true },                    
      
      {   dataField: 'programa',   text: 'Programa',sort:true },                    
      {   dataField: 'curso',   text: 'Curso',sort:true },                   
      {   dataField: 'modulo',   text: 'Modulo',sort:true },     
      {   dataField: 'titulo',   text: 'Tema',sort:true },    
      {   dataField: 'fecha_inicio',   text: 'Inicio',sort:true },                   
      {   dataField: 'fecha_fin',   text: 'Fin',sort:true },                  
 ];
 export default function AsignacionUsuarioLista()  {
    const { currentUser, logout } = useAuth()
    const [asignacion, setAsignacion] = useState([]);

    useEffect(() => {
        AsignacionDataService.getasignacionByUsuario(currentUser?.id)
        .then(response => {
            setAsignacion(response.data)
        })
        .catch(e => {
          console.log(e);
        });
      }, []); 
    
  
    return ( 
        <div className="card card-custom">
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
            <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                    <h2>Temas Asignados</h2>
                </div>
            </div>

            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                <div className="w-100 mw-150px">
                
                </div>
            </div>
        </div>
        <div className="card-body pt-10">

        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_asignacion"
            data={ asignacion }
            columns={ columns }
            search           >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  <hr />
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
        </div>
        </div>
    );
   
}
 