import React, { Component ,useEffect,useState} from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import ReporteService from "../_services/reporte";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

const { SearchBar } = Search;
export default function ReporteEmpresa() {
    const selectOptions = {
        2	:"ADAMS",
        9	:"EL ORIENTE",
        1	:"EL S.A.",
        5	:"GLOBAL SOURCING SAC",
        13	:"LEATHERCORP",
        8	:"LLAVE EN MANO",
        16	:"LLAVE EN MANO INMOB",
        11	:"PANORAMA CSC SAC",
        7	:"PANORAMA OUTSOURCING",
        15	:"PANORAMA STAFF SAC",
        6	:"PRIMATEX",
        14	:"RDF ORIENTE S.A.C.",
        17	:"REMATE DE FÁBRICA S.",
        3	:"SAMITEX SA",
        12	:"SINERCORP",
        4	:"TEXCORP S.A.C.",
        10	:"UP GRADE",
      };
  const columns = [
     
    {   dataField: 'id',   text: 'Nro' ,sort:true },  
    {   dataField: 'id_empresa',   text: 'Empresa',sort:true, 
    formatter: (rowContent, row) => {
        return (
          <>
            <span className="badge  ">{row["empresa"]}</span>
          </>
        )
      },      filter: selectFilter({
        options: selectOptions
      })
    },                   

     {   dataField: 'centro_costo',   text: 'Centro Costo',sort:true },   
    {   dataField: 'centrocosto',   text: 'Descripcion',sort:true },       
    {   dataField: 'usuarios',   text: 'Usuarios',sort:true },                   
    {   dataField: 'asignados',   text: 'Asignados',sort:true },                   
    {   dataField: 'evaluados',   text: 'Evaluados',sort:true },      
    {   dataField: 'avance',    text: 'Porcentaje',sort:true,formatter: (rowContent, row) => {
        //var porcentaje = Math.round(row["evaluados"]*100/row["asignados"],2)
        return (
          <>
            {rowContent <30? 
            <> 
                <span className="badge  badge-danger">{Math.round(rowContent,2)}%</span>
            </>
            :
            <> 
            {rowContent <70 ?
                <> 
                 <span className="badge badge-warning ">{Math.round(rowContent,2)}%</span>
                </>
                :
                <> 
                {rowContent <=100 ? 
                    <> 
                        <span className="badge  badge-success">{Math.round(rowContent,2)}%</span>
                    </> : 
                    <>
                    <span className="badge">{"Sin Asignar"}</span>
                    </>
                }  
                </>}
            </>}
           
          </>
        )
      } },
     ];

    const [reporte, setReporte] = useState([]);
    const [isOnline, setIsOnline] = useState(null);

  useEffect(() => {
    ReporteService.getseguimientoempresa()
      .then(response => {
        setReporte(response.data);
        setIsOnline(true)
      })
      .catch(e => {
        console.log(e);
      });
   
  }, [isOnline]);
  if (isOnline === null) {
    console.log(isOnline);
    return <>'Cargando...'</>;
  } else {
  return (
    
    <>
       
      <div className='row'>
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
          <div className="   " id="kt_docs_content_container">

            <div className="row  mb-5 mb-xl-8">
              <div className="col-xl-12">
                <div className="card card-flush">
                  <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <h1>Empresa - Seguimiento</h1>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                      <div className="w-100 mw-150px">

                      </div>

                    </div>
                  </div>

                  <div className="card-body pt-0">
                    <div className="table-responsive">
                    <BootstrapTable keyField='id' 
                    data={ reporte } 
                    columns={ columns } 
                    filter={ filterFactory() } />
                     
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>   
        </div>
      </div>
    </>
  )
}
}