
import React, { useCallback, Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EvaluacionDataService from "../_services/evaluacion";
import logdataservice from "../_services/log";
import { useAuth } from "../app/modules/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

export default function ResultadoForm() {
    const { currentUser, logout } = useAuth()
    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', {
        item_name: 'resultado',
        item_id: currentUser?.id,
        items: [{ name: currentUser?.username }]
    });
    const navigate = useNavigate();
    const [flgAprobado, setAprobado] = useState(false);
    const [respuestas, setRespuestas] = useState([]);
    const [evaluacion, setEvaluacion] = useState([]);
    const [isOnline, setIsOnline] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search)
    const [user, setUser] = useState(null);
    async function getData(id) {
        const res = await fetch(API_BASE_URL +'evaluacion/sel/'+ id );
        if (!res.ok) {
          throw new Error('Failed to fetch data');
        }
        return res.json();
      }
    const idevaluacion = queryParameters.get("id")

    const fetchData = useCallback( () => {
        EvaluacionDataService.getevaluacionById(idevaluacion)
            .then(result => {
                //setEvaluacion(result.data);
                //setTimeout(() => {
                    EvaluacionDataService.getevaluacionById(idevaluacion)
                    .then(result => {
                        setEvaluacion(result.data);
                        setIsOnline(true)
                        if (result.data.nota >= result.data.notaminima) {
                            setAprobado(true)
                        }
                    });
                  //}, 500);
                console.log(result.data);
                setIsOnline(true)
            })
            .catch(e => {
                console.log(e);
            });
        //const json = await result.json();
        //console.log(evaluacion)
        fetch(API_BASE_URL + 'evaluacion/sel/respuesta/' + idevaluacion, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
         })
            .then(response => response.json())
            .then(result => {
                setRespuestas(result);
                
                console.log(result)
            })
            .catch(e => {
                console.log(e);
        });
        //setEvaluacion(result.data);
       // if (result.data.nota >= result.data.notaminima) {
        //       setAprobado(true)
        //   }
        //console.log(result.data);
        //let str = result.data.respuestas.toString();
        //var myarray = str.split (';');
       // setRespuestas(myarray)
       // console.log(myarray);
    }, [])
      useEffect(  () => {
        logdataservice.registralog("evaluacion","fin",idevaluacion,currentUser.id,currentUser.username);

        //logdataservice.registralog("feedback","","",currentUser.id,currentUser.username);
        // declare the async data fetching function
        fetchData()
            // make sure to catch any error
           
 
       // setIsOnline(true)
        if (idevaluacion !== null) {
         //   fetch(API_BASE_URL+'evaluacion/sel/'+ idevaluacion, {
                //       method: 'GET', headers: { 'Content-Type': 'application/json' }
                //  })
             //   .then( data => setUser(data)) ;
              //  console.log(user)
            
            //EvaluacionDataService.getevaluacionById(idevaluacion)
               // .then(result => {
                 //   setEvaluacion(result);
                    
                 //   console.log(result);
                   // setIsOnline(true)
                  //  if (result.nota >= result.notaminima) {
                     //   setAprobado(true)
                   // }
               // })
               // .catch(e => {
                 //   console.log(e);
               // });
           
        }
     }, [isOnline]);
     if (isOnline === null) {
        //window.location.reload(false);

        return <>'Cargando...'</>;
      }
    return (
        <div >
            <form>
            <div className="card card-custom card-px-1">
                <div className="card-header align-items-center  ">
                    <div className="card-title">
                         
                    </div>

                    <div className="card-toolbar  ">
                        
                        <span className="badge badge-light-success fs-base">
                                <i className="ki-duotone ki-arrow-up fs-5px mb-5 text-info  ">
                                    <span className="path1"></span><span className="path2"></span></i>
                                Intento:
                            <span className="badge badge-square badge-white"> {evaluacion.numero_intento}</span>
                        </span>
                    </div>
                </div>
                <div className="card-body">
               
                    <div className="alert alert-dismissible bg-light-success d-flex flex-center flex-column  ">


                        <span className="svg-icon svg-icon-5tx svg-icon-danger  ">...</span>

                        <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">Resultado de Cuestionario</h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                                {flgAprobado?
                                <>
                                Felicitaciones! completó con éxito el tema :<><b> {evaluacion.titulo} </b> </>
                                </>:
                                <>
                                No logró completar el tema:<><b> {evaluacion.titulo} </b> </>, porfavor :
                                </>
                                }
                                
                            </div>
                            <div className="d-flex flex-center flex-wrap">
                                
                                {flgAprobado ? <>
                                    <Link to={"/temafeedback?id=" + evaluacion.id_evaluacion}
                                        className="btn btn-danger m-2">
                                        <i className="fa-solid fa-star "></i>
                                        Enviar Feedback
                                    </Link>
                                    <Link to={"/dashboardusuario" }
                                        className="btn btn-primary m-2">
                                        <i className="fa-solid fa-home "></i>
                                        Siguiente Tema
                                    </Link>
                                </> : <>
                                <Link to={
                                    {
                                        pathname: "/evaluacionedit" ,
                                        search:   '?id='+ evaluacion.id_tema
                                      + '&idpe='+ evaluacion.id_planestudio
                                      +'&idp='+ evaluacion.id_programa
                                      +'&idc='+ evaluacion.id_curso
                                      +'&idm='+ evaluacion.id_modulo
                                      }

                                }
                                    className="btn btn-danger m-2">
                                    <i className="fa-solid fa-reply "></i>
                                    Intentar Nuevamente
                                </Link>
                                </> }
                              

                            </div>
                        </div>
                    </div>

                    <h1 className="anchor fw-bolder mb-3" id="icon-colors"><a href="#icon-colors"></a>Resumen de Respuestas
                    </h1>
                    <div className="separator my-10"></div>
                    {flgAprobado ?
                            <>
                               <div className="d-flex align-items-center bg-light-primary rounded p-5 mb-7">
                                    <span className=" text-danger me-5">
                                        <i className="fa-solid fa-user text-primary fs-1 me-5">
                                            </i>
                                    </span>
                                    <div className="flex-grow-1 me-2">
                                        <h2 className="fw-bold text-primary text-hover-primary fs-6">
                                                Aprobado
                                        </h2>                                
                                    </div>
                                    <span className="badge   fs-2x badge-primary">{evaluacion.nota}%
                                    </span>
                                </div>
                            </> :
                            <>
                            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
                                    <span className=" text-danger me-5">
                                        <i className="fa-solid fa-xmark text-danger fs-1 me-5">
                                            </i>
                                    </span>
                                    <div className="flex-grow-1 me-2">
                                        <h2 className="fw-bold text-primary text-hover-primary fs-6">
                                                Desaprobado
                                        </h2>                                
                                    </div>
                                    <span className="badge   fs-2x badge-danger">{evaluacion.nota}%
                                    </span>
                            </div>
                            </>}
                    
                    
                    <div className="d-flex align-items-center bg-light-primary rounded p-5 mb-7">
                            <span className=" text-danger me-5">
                                <i className="fa-solid fa-check text-primary fs-1 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span></i>
                            </span><div className="flex-grow-1 me-2">
                                <span href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">Respuestas Correctas</span>
                                <span className="text-muted fw-semibold d-block"> </span>
                                
                            </div>
                            <span className="badge   fs-2x badge-primary">{evaluacion.correcta}
                            </span>
                    </div>
                    <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
                            <span className=" text-danger me-5">
                                <i className="fa-solid fa-xmark text-danger fs-1 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span></i>
                            </span><div className="flex-grow-1 me-2">
                                <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">Respuestas Incorrectas</a>
                                <span className="text-muted fw-semibold d-block">Preguntas:</span>
                                <ul>
                                {respuestas.map((rpta) => <li id={rpta.id}>{rpta.pregunta}</li>)}
                                </ul>
                            </div>
                            <span className="badge   fs-2x badge-danger">{evaluacion.incorrecta}
                            </span>
                    </div>
                     
                </div>
            </div>
            </form>
        </div>
    );
}
