import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import ProgramaDataService from "../_services/programa";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';

const options = {
    sizePerPageList: [{

        text: '100', value: 100
    }, {
        text: '250', value: 250
    }, {
        text: '500', value: 500
    }, {
        text: '1000', value: 1000
    }] // A numeric array is also available. the purpose of above example is custom the text
};
const columns = [
    {
        dataField: 'id_tema', text: 'Accion', formatter: (rowContent, row) => {
            let idevaluacion = row["id_evaluacion"];
            let boolcuestionario = row["cuestionario"];
            return (
                <>
                    {idevaluacion == 0 ?
                        <>
                            {boolcuestionario == 1 ?
                                <>
                                    <Link to={'/evaluacionedit?id=' + row["id_tema"]
                                        + '&idpe=' + row["id_planestudio"]
                                        + '&idp=' + row["id_programa"]
                                        + '&idc=' + row["id_curso"]
                                        + '&idm=' + row["id_modulo"]}
                                        className="btn btn-link btn-color-success btn-active-color-success me-5 mb-2 py-1  "               >
                                        <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                                    </Link>
                                </> :
                                <>
                                    <Link to={'/evaluacionsimple?id=' + row["id_tema"]
                                        + '&idpe=' + row["id_planestudio"]
                                        + '&idp=' + row["id_programa"]
                                        + '&idc=' + row["id_curso"]
                                        + '&idm=' + row["id_modulo"]}
                                        className="btn btn-link btn-color-success btn-active-color-success me-5 mb-2 py-1  "               >
                                        <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                                    </Link>
                                </>}

                        </>
                        : <>
                            <div className="btn-toolbar justify-content-between" role="toolbar"
                                aria-label="Toolbar with button groups">
                                <div className="btn-group" role="group" aria-label="First group">
                                    <Link to={"/temareview?id=" + row["id_tema"]}
                                        className="btn   btn-icon btn-sm "            >
                                        <i className="bi bi-youtube text-danger fs-1 "></i>
                                    </Link>
                                    <Link to={"/certificadovisor?id=" + row["id_evaluacion"]}
                                        className="btn btn-link btn-color-success btn-active-color-success me-5 mb-2 py-1  "               >
                                        <i className="las la-certificate fs-2x text-warning" ></i>
                                    </Link>
                                </div>
                            </div>
                        </>}


                </>
            )
        }
    },
    { dataField: 'titulo', text: 'Tema', filter: textFilter() },
    { dataField: 'fecha_creacion', text: 'Fecha', filter: textFilter() },
    { dataField: 'nota', text: 'Nota', filter: textFilter() },
];
export default function ProgramaSeguimiento() {
    const { currentUser, logout } = useAuth()

    const queryParameters = new URLSearchParams(window.location.search)
    const idprograma = queryParameters.get("id")
    const idplan = queryParameters.get("idp")
    const [programa, setPrograma] = useState([]);
    const [detalle, setDetalle] = useState([]);
    const [isOnline, setIsOnline] = useState(null);

    useEffect(() => {
        ProgramaDataService.getProgramaDetalleById(idprograma)
            .then(response => response.json())
            .then(result => {
                setPrograma(result)
            })
            .catch(e => {
                console.log(e);
            });

        ProgramaDataService.getprogramadetalleusuarioById(currentUser?.id,idplan, idprograma)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                setDetalle(result)
                setIsOnline(true)
            })
            .catch(e => {
                console.log(e);
            });

    }, [isOnline]);
    if (isOnline === null) {
        console.log(isOnline);
        return <>'Cargando...'</>;
    } else {
        return (

            <>
                <div className='row'>
                    <div className="card card-custom">
                        <div className="card-header d-flex align-items">
                            <div className="card-title d-flex py-5 pt-0">
                                <h3 className="card-label d-flex">
                                    <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                                    <div className="bullet bg-primary h-35px w-1px mx-5"> </div>
                                    <span className="card-label fw-bold text-info fs-2x">{programa.titulo}</span>
                                </h3>
                            </div>
                            <div className="card-toolbar">
                                <div className="btn-toolbar justify-content-between" role="toolbar"
                                    aria-label="Toolbar with button groups">
                                    
                                    {currentUser?.indicador_soporte ?
                                        <>
                                            <Link to={"/planestudio"}
                                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                                <i className="fa-solid fa-reply "></i>
                                                Volver
                                            </Link>
                                        </> :
                                        <>
                                            <Link to={"/planmislogros"}
                                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                                <i className="fa-solid fa-reply "></i>
                                                Volver
                                            </Link>
                                        </>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator my-1"></div>
                <div className='row '>
                    <div className="col-lg-12 ">
                        <div className="card card-custom">
                            <div className="table-responsive">
                                <BootstrapTable
                                    className={"table table-responsive table-bordered "}
                                    headerWrapperClasses="foo"
                                    bordered={true}
                                    keyField='id_tema'
                                    data={detalle}
                                    columns={columns}
                                    filter={filterFactory()}

                                    pagination={paginationFactory(options)}
                                />

                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}