
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TemaDataService from "../_services/tema";
import ContenidoDataService from "../_services/contenido";
import EvaluacionDataService from "../_services/evaluacion";
import DetalleDataService from "../_services/detalle_evaluacion";
import { useAuth } from "../app/modules/auth";

export default function TemaReview() {
    const [flag, setFlag] = useState(false);
    const [tema, setTema] = useState([]);
    const [preguntas, setPregunta] = useState([]);
    const { currentUser, logout } = useAuth()
    const queryParameters = new URLSearchParams(window.location.search)
    const idtema = queryParameters.get("id")
    useEffect(() => {

        console.log(idtema);
        //const { parametro1, parametro2, parametro3 } = props.location.search;
        //const array = props.location.state.array;
        //console.log(array)
        if (idtema !== null) {
            const arrpreguntas = ({
                "id_tema": idtema,
                "id_modulo": 0
            })
            //console.log(JSON.stringify(arrpreguntas))

            TemaDataService.gettemaById(idtema)
                .then(result => {
                    setTema(result.data);
                    console.log(result.data);
                })
                .catch(e => {
                    console.log(e);
                });
            //


        }
    }, []);
    return (
        <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <h3 className="card-title d-flex align-items-center">
                    <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                    <div className="bullet bg-secondary h-35px w-1px mx-5"></div>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6"> </span>
                    Tema:<span className="card-label fw-bold text-danger fs-2x">{tema.titulo}</span>
                </h3>
                <div className="card-toolbar">
                    <div className="btn-toolbar justify-content-between" role="toolbar"
                        aria-label="Toolbar with button groups">
                        {currentUser?.indicador_soporte?
                        <>
                         <Link to={"/planestudioflujo?id=" + tema.id_planestudio}
                            className="btn btn-icon-white btn-text-white btn-danger">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        </>:
                        <>
                         <Link to={"/programaseguimiento?id=" + tema.id_programa}
                            className="btn btn-icon-white btn-text-white btn-danger">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        </>
                        } 
                       
                    </div>
                </div>
            </div>
            <div className=" pt-1">
                <form  >

                    <div className='   '>
                        <div className=' '>


                            <iframe className='form-control ' width={'100%'} height={350}
                                title='Contenido'
                                src={tema.contenido}>
                            </iframe>
                        </div>
                        <div className="card card-custom" >
                            <div className="card-body  p-0  ">
                                {tema.contenido_complementario?
                                <>
                                  <a className="btn btn-danger btn-lg "  target="_blank"
                                 href={tema.contenido_complementario}>Descargar Material</a> 
                                </>:
                                <>
                                 
                                </>}
                              
                                             
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        </div>
    );
}
