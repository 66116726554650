
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import ConfiguracionService from   "../_services/configuracion";
import LoadingSpinner from "../_components/LoadingSpinner";
import { useAuth } from "../app/modules/auth";

export default function ConfiguracionForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, logout } = useAuth()

    const queryParameters = new URLSearchParams(window.location.search)
    const iddominio = queryParameters.get("id")
    const handleSubmit = async (e) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Guardar el Registro?");
        if (answer) {
            configuracion.usuario_modificacion=currentUser?.username
            configuracion.codigo_estado=1
            configuracion.id_configuracion=1
            ConfiguracionService.updateconfiguracion(configuracion)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                alert("Registro Actualizado correctamente");
                //navigate('/dashboardadmin');
              })
              .catch(function (error) {
                console.log(error);
              });
             
            
          }
    };
    const handleChange = (e ) => {
       setConfiguracion((prev) => ({
           ...prev,
           [e.target.name]: e.target.value,
       }));
};
    const [configuracion, setConfiguracion] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    useEffect(() => {
       // setIsLoading(true)
        ConfiguracionService.getconfiguracionById(1)
            .then(result => {
                //setIsLoading(false)
                setConfiguracion(result.data);
                console.log(result.data);
                
            })
            .catch(e => {
            console.log(e);
            });
    }, [ ]);
    
    return (
        <div className="card card-custom">
            <form onSubmit={handleSubmit}>
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Editar Configuración</h2>
                    </div>
                </div>

                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">

                    </div>

                    
                    <button className='btn btn-primary' type="submit">
                        <i className="fa-solid fa-floppy-disk"></i>
                        Guardar</button>
                </div>
            </div>
            <div className="card-body pt-10">
                

                    <div className="form-group row">
                        <div className="col-lg-12   input-group-sm mb-5">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Firma de Notificación</span>
                                <TextField
                                    id="firma_notificacion"
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    defaultValue={configuracion.firma_notificacion}
                                    className="form-control"
                                    name="firma_notificacion"
                                />
                            
                        </div>
                        <div className="col-lg-12   input-group-sm mb-5">
                            <div className="input-group input-group-sm mb-5">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Dias Vencimiento Contraseña</span>
                                <input type="number" className="form-control"  
                                defaultValue={configuracion.dias_vencimiento}   onChange={handleChange}
                                id="dias_vencimiento" name="dias_vencimiento"   />
                            </div>
                        </div> 
                    </div>
               
            </div>
            </form>
            <div className="card-footer">

                <div  >
                     <pre className="prism-code language-bash h-25px p-1  ">
                     <span className=" m-2">{'<br />'}</span>
                    </pre>
                    </div>
                <span className="badge badge-light-danger fs-base">
                    <span className="path1"></span><span className="path2"></span>
                    Use el codigo    <span className="path1"><br /></span>  para los saltos de linea
                </span>
            </div>
        </div>
    );
}
