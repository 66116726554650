import React, { Component, useState } from "react";
import TemaDataService from "../_services/tema";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';

import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Rating from '@mui/material/Rating';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { ExportCSVButton } = CSVExport;

const { SearchBar } = Search;
const columns = [
  {
    dataField: 'id_tema', text: 'Editar', formatter: (rowContent, row) => {
      return (
        <>
          <div className="btn-toolbar justify-content-between" role="toolbar"
            aria-label="Toolbar with button groups">
            <div className="btn-group" role="group" aria-label="First group">
              <Link to={"/temaedit?id=" + rowContent}
                className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon  btn-sm "            >
                <i className="fa-solid fa-edit text-primary fs-2x"></i>
              </Link>
              <Link to={"/temapreview?id=" + rowContent}
                className="btn btn-outline btn-outline-dashed btn-outline-danger btn-icon  btn-sm"            >
                <i className="fa-solid fa-eye text-danger fs-2x"></i>
              </Link>
              <Link to={"/planestudioedt?id=5&ref="+ rowContent}
                className="btn btn-outline btn-outline-dashed btn-outline-danger btn-icon btn-sm  "            >
                <i className="bi bi-diagram-2 text-warning fs-2x"></i>
              </Link>
            </div>
          </div>
        </>
      )
    }
  },
  { dataField: 'codigo', text: 'Código',sort: true,csvText: 'Codigo'  },
  { dataField: 'titulo', text: 'Título',sort: true,csvText: 'Titulo'  },
  { dataField: 'descripcion', text: 'Descripción', sort: true,csvText: 'Descripcion' },
  {   dataField: 'codigo_categoria', 
      text: 'Categoria', sort: true
 },
  { dataField: 'codigo_estado', text: 'Estado', sort: true, formatter: (rowContent, row) => {
      return (
        <>
          {rowContent === "Activo" ?
            <>
              <span className="badge badge-primary">{rowContent}</span>
            </> :
            <>
              {rowContent === "Inactivo" ?
                <>
                  <span className="badge badge-danger">{rowContent}</span>
                </> :
                <>
                  <span className="badge badge-warning">{rowContent}</span>
                </>}
            </>}
        </>
      )
    }
  },
  { dataField: 'referencia', text: 'Tema Dependencia', sort: true },
  { dataField: 'fecha_creacion', text: 'Creado', sort: true }, 
  {   dataField: 'fecha_modificacion',   text: 'Modificado'   },   
  { dataField: 'modulos', text: 'Modulos', sort: true, formatter: (rowContent, row) => {
    return (
      <>
        {rowContent == "0" ?
          <>
            <span className="badge badge-danger">{rowContent}</span>
          </> :
          <>
            <span className="badge badge-primary">{rowContent}</span>
          </>}
      </>
    )}
  },    
  { dataField: 'preguntas', text: 'Preguntas', sort: true, formatter: (rowContent, row) => {
    return (
      <>
        {rowContent == "0" ?
          <>
            <span className="badge badge-danger">{rowContent}</span>
          </> :
          <>
            <span className="badge badge-primary">{rowContent}</span>
          </>}
      </>
    )}
  },    
  { dataField: 'rating', text: 'Ranking', sort: true, formatter: (rowContent, row) => {
      return (
        <>
          <Rating precision={1} disabled={true}
            value={rowContent}
          />
        </>
      )
    }
  }, 

];
// Function to export data as a file
const ddlreporte = [];
 const exportToCSV = (csvData, fileName) => {
   for (var i = 0, l = csvData.length; i < l; i++) {
     ddlreporte.push({
         "codigo": csvData[i].codigo, 
         "titulo":  csvData[i].titulo ,
         "descripcion":  csvData[i].descripcion,
         "categoria":  csvData[i].codigo_categoria,
         "referencia":  csvData[i].referencia,
         "fecha_creacion":  csvData[i].fecha_creacion        ,
         "fecha_modificacion":  csvData[i].fecha_modificacion
     })
   }
   const ws = XLSX.utils.json_to_sheet(ddlreporte);
   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], {type: fileType});
   FileSaver.saveAs(data, fileName + fileExtension);
 }
export default class TemaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _temas: []
    };
  }
  exportacsv(){
    alert('prueba')
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  
  obtenerregistros() {
    TemaDataService.gettemas()
      .then(response => {
        this.setState({
          _temas: response.data
 
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const { _temas } = this.state;
    return (
      <div className="col-lg-12">

        <ToolkitProvider
          keyField="id_tema"
          data={_temas}
          columns={columns}
          search  
          exportCSV={{  
            fileName: 'Tema.csv',
            separator: ';',
            noAutoBOM: false 
            }}              >
          {
            props => (
              <div>
                <SearchBar {...props.searchProps} placeholder="Buscar..." />
                <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(_temas,'UCJ-Temas')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                <hr />
                <BootstrapTable className="table table-bordered"
                  {...props.baseProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    );
  }
}
