

import { Link } from "react-router-dom"
import EvaluacionResultadoForm from "../../../_components/evaluacionresultado";
export function EvaluacionResultadoPage  () {
  return (
 <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                        <EvaluacionResultadoForm></EvaluacionResultadoForm>
                </div>
            </div>
        </div>
  );
}