import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageTitle } from '../../../_metronic/layout/core'
import PlanEstudioFin from "../../../_components/planestudiofin";
export   function PlanFin() {
  const [cliente, setCliente] = useState([]);
  useEffect(() => {
    
  }, []);
  return (
    <>
         <PlanEstudioFin></PlanEstudioFin>
    </>
  )
}
 