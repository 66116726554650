
import React, { Component } from "react";
import { Link } from "react-router-dom";

import PlanestudioLista from "../../../_components/planestudio";

export function Planestudio() {
    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-lg-12">
                        <PlanestudioLista></PlanestudioLista>
                    </div>
                </div>
            </div>
        </div>
    );
}