import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import TemaDataService from "../_services/tema";
import PreguntaDataService from "../_services/pregunta";
//import { Pregunta } from "../_models/pregunta";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
function TemaPreguntaForm() {
    const navigate = useNavigate();
   
    const [tema, setTema] = useState([]);
    const [preguntas, setPregunta] = useState([]);

 
    useEffect(() => {
        PreguntaDataService.getpreguntas() 
            .then(result => {
                setPregunta(result.data);
                console.log(result);
            })
            .catch(e => {
                console.log(e);
            });
        TemaDataService.gettemas()
            .then(result => {
                setTema(result.data);
                console.log(result);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Guardar el Registro?");
 
        if (answer) {
            const listatema = document.getElementsByName("lista_tema");
            const listapregunta = document.getElementsByName("lista_pregunta");
            var arrAsignacion=[]
            for (let i = 0; i < listatema[0].options.length; i++) {
                if (listatema[0].options[i].selected) {
                    for (let x = 0; x < listapregunta[0].options.length; x++) {
                        if (listapregunta[0].options[x].selected) {
                            arrAsignacion.push({
                                id_tema:listatema[0].options[i].value,
                                id_pregunta: listapregunta[0].options[x].value 
                            })
                        }
                    }
                    
                    //console.log(listausuario[0].options[i].value)
                }
            }
            TemaDataService.createtemapregunta(JSON.stringify(arrAsignacion))
                    .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            alert("Registro Actualizado correctamente");
                            navigate('/tema');
                        })
                    .catch(function (error) {
                            console.log(error);
                        });
           
        }
    }
    return (
        <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Enlazar Tema y Preguntas</h2>
                    </div>
                </div>
                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    </div>
                </div>
            </div>
            <div className='card-body pt-2'>
                <form onSubmit={handleSubmit}   >
                    
                    <div className='row g-5 g-xl-8'>
                        <div className='col-xl-6'>
                            <h3>Base de Preguntas</h3>
                            <select multiple className="form-control h-350px" name="lista_pregunta"  >
                                {preguntas.map((option,index) => (
                                    <option value={option.id_pregunta}>{index+1}-{option.pregunta}</option>
                                ))}
                            </select>

                        </div>
                       
                        <div className='col-xl-6'>
                            <h3>Temas Disponibles</h3>
                            <select multiple className="form-control" name="lista_tema"   >
                                {tema.map((option) => (
                                    <option value={option.id_tema}>{option.titulo}</option>
                                ))}
                            </select>

                        </div>
                        <div className="mb-10">

                            <Link to={"/pregunta"}
                                className="btn btn-icon-white btn-text-white btn-danger">
                                <i className="fa-solid fa-reply "></i>
                                Cancelar
                            </Link>
                            <button className='btn btn-primary' type="submit">
                                <i className="fa-solid fa-floppy-disk"></i>
                                Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default TemaPreguntaForm;
