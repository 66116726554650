import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import ProgramaDataService from "../_services/programa";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const optionsgraph = {
  responsive: true,
  type: "bar",

  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: true,
      text: "Avance por Periodo / Año Actual"
    }
  }
};
const labels = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

export default function PlanEstudioMisLogros() {


  const [planes, setPlan] = useState([]);
  const [isOnline, setIsOnline] = useState(null);
  const [programa, setPrograma] = useState([]);
  const { currentUser, logout } = useAuth()
  const [Avance, setAvance] = useState([]);
  const arravance = Avance.map((hh) => hh.avance);
  const dataavance = {
    labels,
    datasets: [
      {
        label: "Datos:",
        data: arravance,
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)'
        ],
      }
    ]
  };
  useEffect(() => {

    ProgramaDataService.getprogramasusuario(currentUser?.id)
      .then(response => {
        setPrograma(response.data)
        console.log(response.data)
        setIsOnline(true)
      })
      .catch(e => {
        console.log(e);
      })
    ProgramaDataService.getprogramausuariographById(currentUser?.id)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setAvance(result)
      })
      .catch(e => {
        console.log(e);
      });
  }, [isOnline]);
  if (isOnline === null) {
    console.log(isOnline);
    return <>'Cargando...'</>;
  } else {
    return (

      <>

        <div className=''>
          <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
               
              <div className="card card-custom ">

                <div className="row py-2">
                  <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div className="card-title d-flex py-5 pt-0">
                      <h3 className="card-label d-flex">
                        <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                        <div className="bullet bg-primary h-35px w-1px mx-5"> </div>
                        <span className="card-label fw-bold text-info fs-2x">Plan de Estudio - Mis Logros</span>
                      </h3>
                    </div>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                      <div className="w-100 mw-150px">
                      </div>
                    </div>
                  </div>
                  {programa.map((prog, index) => (
                    <div className="col-lg-4">
                    <Link to={"/programaseguimiento?idp="+ prog.id_planestudio+"&id="+ prog.id_programa } 
                    className="card hover-elevate-up shadow-sm parent-hover h-150px border border-dotted border-primary border-active active m-2">
                      <div className="card-body d-flex align-items ">
                       
                        <i className="fa-solid fa-chalkboard-user text-ucj fs-3x"></i>
                        <div className="bullet bg-primary h-35px w-1px mx-5"> </div>
                        <div className="separator my-10"></div>
                        <ul className="list-unstyled">
                          <li>
                            <span className="text-gray-400 pt-1 fw-semibold fs-7">{prog.plan}</span>
                          </li>
                          <li>
                            <h6 className="text-danger fs-1hx me-2 lh-1 ls-n2">
                            {prog.programa}
                            </h6>
                          </li>
                          <li>
                            <div className="btn-group d-flex align-items-center"   >
                              <span className="fs-6 fw-semibold text-gray-400 flex-shrink-0">Promedio:</span>
                              <span id="kt_app_toolbar_slider_value"
                                className="d-flex flex-center bg-light-success rounded-circle w-35px h-35px ms-4 fs-7 fw-bold text-info"
                                data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Set impact level"
                                data-bs-original-title="Set impact level" data-kt-initialized="1"> {prog.promedio}%</span>
                              <div className="bullet bg-secondary h-35px w-1px mx-5"> </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex fs-1 fw-semibold align-items-center">
                              <span className="fs-6 fw-bold text-info flex-shrink-0  ">
                              {prog.duracion}
                                <i className="bi bi-clock-fill text-dark   "></i>
                              </span>
                              <span className="fs-6 fw-semibold text-gray-400 flex-shrink-0">hh:mm</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                  
                  ))}


                </div>
                <div className="separator separator-dashed my-3"></div>
                <div className="col-lg-12 ">
                  <Bar options={optionsgraph} data={dataavance} />;
                </div>
              </div>

            </div>
          </div>
        </div>

      </>
    )
  }
}