import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import EvaluacionDataService from "../_services/evaluacion";  

//import { Pregunta } from "../_models/pregunta";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
const queryParameters = new URLSearchParams(window.location.search)
const idevaluacion = queryParameters.get("id")

export default class TemaResultado extends Component {
    constructor(props) {
        super(props);
        this.state = {evaluacion: []  ,
          loading: true };

    }
    componentDidMount() {
        this.obtenerregistros();
    }
    obtenerregistros() {
        this.setState({ loading: true });
        const queryParameters = new URLSearchParams(window.location.search)
        const idevaluacion = queryParameters.get("id")
        EvaluacionDataService.getevaluacionById()
        .then(result => {
               // this.setState({result});
                //console.log(result);
                //console.log(this.state.evaluacion);
                this.state.evaluacion = result;
                console.log(this.state.evaluacion);
                console.log(this.state.evaluacion[0].tema);
                this.setState({ loading: false });
            })
            .catch(e => {
                console.log(e);
                this.setState({ loading: false });
            });
        
    }
    render() {
    
        if (this.state.loading) return "Loading...";
    return (
        <div>
            <div className="card card-custom card-flush">

                <div className="card-body">
                    <div className="alert alert-dismissible bg-light-success d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
                        

                        <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>

                        <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">Resultado de Cuestionario</h5>

                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                            <div className="mb-9">
                                Feliciaciones! completo el tema <><b>{ this.state.evaluacion[0]?.tema }</b> </>con éxito, el resultado
                            </div>
                            <div className="d-flex flex-center flex-wrap">
                                <Link to={"/temausuario?id="+ this.state.evaluacion[0]?.id_tema }
                                        className="btn btn-outline btn-outline-danger btn-active-danger m-2">
                                        <i className="fa-solid fa-reply "></i>
                                        Intentar Nuevamente
                                    </Link>
                                    <Link to={"/temafeedback?id="+ idevaluacion}
                                        className="btn btn-danger m-2">
                                        <i className="fa-solid fa-star "></i>
                                        Enviar Feedback
                                    </Link>
                                 
                            </div>
                        </div>
                    </div>

                    <h1 className="anchor fw-bolder mb-3" id="icon-colors"><a href="#icon-colors"></a>Resumen de Respuestas
                    </h1>
                    <div className="separator my-10"></div>

                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px bg-primary">
                        </span>
                        <div className="form-check form-check-custom form-check-solid mx-5">
                            Nro
                        </div>
                        <div className="flex-grow-1"><a href="#"
                            className="text-gray-800 text-hover-primary fw-bold fs-6">Respuestas Correctas
                        </a>
                        </div>
                        <span className="badge   fs-2x badge-primary">
                            {this.state.evaluacion[0]?.correcta} </span>
                    </div>
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px bg-danger">
                        </span>
                        <div className="form-check form-check-custom form-check-solid mx-5">
                            Nro
                        </div>
                        <div className="flex-grow-1"><a href="#"
                            className="text-gray-800 text-hover-danger fw-bold fs-6">Respuestas Incorrectas
                        </a>
                        </div>
                        <span className="badge   fs-2x badge-danger">
                        {this.state.evaluacion[0]?.incorrecta}
                        </span>
                    </div>
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px bg-success">
                        </span>
                        <div className="form-check form-check-custom form-check-solid mx-5">
                            Nro
                        </div>
                        <div className="flex-grow-1"><a href="#"
                            className="text-gray-800 text-hover-success fw-bold fs-6">Nota Final
                        </a>
                        </div>
                        <span className="badge   fs-2x badge-success">
                        {(this.state.evaluacion[0]?.correcta / this.state.evaluacion[0]?.total)*100}
                         </span>
                    </div>
                </div>
            </div>

        </div>
    );
}}

 
