
import React, { Component, useEffect, useState } from "react";
import EvaluacionDataService from "../_services/evaluacion";
import ReporteDataService from "../_services/reporte";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import LoadingSpinner from "../_components/LoadingSpinner";
import Select from 'react-select';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const API_BASE_URL = process.env.REACT_APP_API_URL;

const { SearchBar } = Search;
 

const columns = [
  {
    dataField: 'id_evaluacion', text: 'Certificado', formatter: (rowContent, row) => {
      return (
        <div className="btn-toolbar" role="toolbar" aria-label="...">
          <div className="btn-group mr-2" role="group" aria-label="...">

            {row.nota > 70 ?
              <>

                <Link to={"/certificadovisor?id=" + rowContent}
                  className="btn btn-icon ">
                  <i className="fa-solid fa-file-pdf fs-2x text-primary"></i>
                </Link>
              </> :
              <></>}


          </div></div>
      )
    }
  },
  { dataField: 'codigo', text: 'Código', sort: true },
  { dataField: 'nombres', text: 'Nombres', sort: true },
  { dataField: 'apellido', text: 'Apellido', sort: true },
  { dataField: 'titulo', text: 'Tema', sort: true },
  { dataField: 'fecha_creacion', text: 'Fecha', sort: true },
  { dataField: 'correcta', text: 'Correcta', sort: true },
  { dataField: 'incorrecta', text: 'Incorrecta', sort: true },
  { dataField: 'nota', text: 'Nota %', sort: true },

];
function reprogramartema() {
  const answer = window.confirm("Esta seguro de Reprogramar Tema?");
  if (answer) {

  }
}
const exportToCSV = (csvData, fileName) => {

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}
export default function EvaluacionLista() {
  const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  const [empresa, setEmpresa] = useState([]);
  const [plan, setPlan] = useState([]);
  const [tema, setTema] = useState([]);
  const [empresasel, selEmpresa] = useState([]);
  const [plansel, selPlan] = useState([]);
  const [temasel, selTema] = useState([]);
  const handleChangeSelect = (e) => {
    let arrtemp = []
    for (var i = 0, l = e.length; i < l; i++) {
      arrtemp.push(e[i].id)
    }
    selEmpresa(arrtemp.toString())
   }
  const handleChangePlanSelect = (e) => {
     
    selPlan(e.id)
   }
  const handleChangeTemaSelect = (e) => {
     
    selTema(e.id)
   }
  const handleClick = async () => {
    try {
      setIsLoading(true)
      console.log(empresasel)
      console.log(plansel)
      console.log(temasel)
      //const response = await axios.get('https://apiucj.azurewebsites.net/api/programa/sellst/usuario/graph/1661');
      const response = await fetch(API_BASE_URL+'/evaluacion/sellst/'+empresasel+'/'+plansel+'/'+ temasel );
      const result = await response.json();
      setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
      console.log(result)
      setIsLoading(false)

    } catch (error) {
      console.error('Hubo un error al obtener los datos:', error);
    }
  };
  useEffect(() => {

    const parempresa = {
      "nombre_tabla": "tab_empresa",
      "idresult": "convert(varchar,id_empresa)",
      "codigo": "convert(varchar,id_empresa)",
      "descripcion": "empresa",
      "primer_valor": "[Todas las Empresas]",
      "campocondicion": "codigo_estado",
      "campovalor": "1"
    }
    ReporteDataService.obtener_parametros(parempresa)
      .then(result => {
        setEmpresa(result.data);
        //setIsLoading(false)
      })
     

    const parplan = {
      "nombre_tabla": "tab_planestudio",
      "idresult": "convert(varchar,id_planestudio)",
      "codigo": "denominacion",
      "descripcion": "denominacion",
      "primer_valor": "",
      "campocondicion": "codigo_estado",
      "campovalor": "1"
    }
    ReporteDataService.obtener_parametros(parplan)
      .then(result => {
        setPlan(result.data)
         
      })
       
      const partema = {
        "nombre_tabla": "tab_tema",
        "idresult": "convert(varchar,id_tema)",
        "codigo": "titulo",
        "descripcion": "descripcion",
        "primer_valor": "[Todos los Temas]",
        "campocondicion": "codigo_estado",
        "campovalor": "1"
      }
      ReporteDataService.obtener_parametros(partema)
        .then(result => {
          setTema(result.data)
          console.log(result.data)
          setIsLoading(false)
        })
       ;
  }, [isLoading]);
  if (isLoading) {
    return <> <LoadingSpinner />  </>;
  }

  return (
    <>
      <div className="col-lg-12">
        <div className="card card-custom">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">Reporte Cuestionarios</span>
              <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
            </h3>
            <div className="card-toolbar">
              <button className='btn btn-primary btn-sm' onClick={handleClick}>Generar Reporte</button>
              <button variant="warning" className="btn btn-success btn-sm"
                onClick={(e) => exportToCSV(datos, 'UCJ-Reporte Linea de Tiempo')}>
                <i className="fa-solid fa-file-excel "></i>Exportar</button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="form-group row">
              <div className="col-lg-4   input-group-sm mb-5">
                <span className="label text-info">Seleccione: </span>
                <Select
                  placeholder="Empresas"
                  name="id"
                  isMulti
                  onChange={(e) => handleChangeSelect(e)}
                  options={empresa}
                  // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.codigo}
                />
              </div>
              <div className="col-lg-4   input-group-sm mb-5">
                <span className="label text-info">Seleccione: </span>
                <Select
                  placeholder="Plan de Estudio"
                  name="id"
                  onChange={(e) => handleChangePlanSelect(e)}
                  options={plan}
                  // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.descripcion}
                />
              </div>
              <div className="col-lg-4   input-group-sm mb-5">
                <span className="label text-info">Seleccione: </span>
                <Select
                  placeholder="Tema"
                  name="id"
                  onChange={(e) => handleChangeTemaSelect(e)}
                  options={tema}
                  // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.descripcion}
                />
              </div>

            </div>
            <div className="table-responsive">
        <ToolkitProvider
          keyField="id_evaluacion"
          data={datos}
          columns={columns}
          search           >
          {
            props => (
              <div>
                <SearchBar {...props.searchProps} placeholder="Buscar..." />
                <button variant="warning" className="btn btn-success btn-sm"
                  onClick={(e) => exportToCSV(datos, 'UCJ-Cuestionarios')}>
                  <i className="fa-solid fa-file-excel "></i>Exportar</button>
                <hr />
                <BootstrapTable className="table table-bordered"
                  {...props.baseProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
          </div>
        </div>
       
      </div>
      
    </>

  );
}

