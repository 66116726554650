
 
import axios from 'axios';
import React, {Component} from 'react';
import { contenido } from '../_models/contenido';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/contenido";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/contenido";
class ContenidoService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getcontenidos(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        
        //.then((data) => console.log(data));
    }
    createcontenido(contenido){
        return axios.post(API_BASE_URL+ '/ins', contenido,{
            headers: {'Content-Type': 'application/json'}
        })}
    getcontenidoById(contenidoId){
        return axios.get(API_BASE_URL + '/sel/' + contenidoId);
    }
    getcontenidoByTema(idtema){
        return axios.get(API_BASE_URL + '/sel/tema/' + idtema);
    }
    updatecontenido(idcontenido,contenido){
        return axios.post(API_BASE_URL + '/upd/'+idcontenido , contenido,{
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            console.log(response);
          }).catch(err => console.error("Wasn't able to update user.", err));}

    deletecontenido(contenidoId){
        return axios.get(API_BASE_URL + '/del/' + contenidoId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new ContenidoService()