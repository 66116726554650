import {SidebarMenuMain} from './SidebarMenuMain'

const SidebarMenu = () => {
  const divStyle={
    overflowY: 'scroll',
    border:'1px solid red',
     
    position:'relative'
  };
  return (
    
    <div className='app-sidebar-menu   flex-column scroll'>
      <div
        id='kt_app_sidebar_menu_wrapper'   
        className='app-sidebar-wrapper overflow-y my-5 hover-scroll'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      >
        <div
          className='menu menu-column menu-rounded menu-sub-indention px-3 scroll'
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
          data-kt-menu-expand='false'
          data-kt-scroll='true'
        >
          <SidebarMenuMain />
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
