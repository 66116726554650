import React, { useCallback, Component, useEffect, useState } from "react";
import axios from 'axios';
import LoadingSpinner from "../_components/LoadingSpinner";
import Select from 'react-select';
import ReporteDataService from "../_services/reporte";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import EmailService from "../_services/email";
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import planestudio from "../_services/planestudio";
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte/usuario/";

const options = {
    sizePerPageList: [{
      
      text: '100', value: 100
    } , {
        text: '250', value: 250
      }, {
        text: '500', value: 500
      }, {
        text: '1000', value: 1000
      }] // A numeric array is also available. the purpose of above example is custom the text
  };
const columns = [
    { dataField: 'planestudio', text: 'Plan', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'fecha_creacion', text: 'Fecha', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'hora_creacion', text: 'Hora', sort: true , headerStyle: {fontSize: '10px', color:'#fff', backgroundColor: '#009ef7'}},
    { dataField: 'empresa', text: 'Empresa', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'departamento', text: 'Dpto', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'centro_costo', text: 'Cod CeCo', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'centrocosto', text: 'CeCo', sort: true,  headerStyle: { fontSize: '10px',  color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'personal', text: 'Usuario', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'codigo', text: 'Cod Usuario', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'cargo', text: 'Cargo', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'temas', text: 'Temas Asig', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'avance', text: '% Avance', sort: true,  headerStyle: { fontSize: '10px', color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'promedio', text: 'Nota Promedio', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'certificados', text: 'Certificados', sort: true,  headerStyle: { fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'} },

];
const exportToCSV = (csvData, fileName) => {
    const ddlreporte = [];
    for (var i = 0, l = csvData.length; i < l; i++) {
        ddlreporte.push({
            "planestudio": csvData[i].planestudio, 
            "fecha_creacion":  csvData[i].fecha_creacion ,
            "hora_creacion":  csvData[i].hora_creacion,
            "empresa":  csvData[i].empresa        ,
            "departamento":  csvData[i].departamento,
            "centro_costo":  csvData[i].centro_costo,
            "centrocosto":  csvData[i].centrocosto,
            "personal":  csvData[i].personal,
            "codigo":  csvData[i].codigo,
            "cargo":  csvData[i].cargo,
            "temas":  csvData[i].temas,
            "avance":  csvData[i].avance,
            "promedio":  csvData[i].promedio,
            "certificados":  csvData[i].certificados 
        })
      }
    const ws = XLSX.utils.json_to_sheet(ddlreporte);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}
const UsuarioReportexEmpresa = () => {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    const [plan, setPlan] = useState([]);
    const [empresasel, selEmpresa] = useState([]);
    const [plansel, selPlan] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const [filtro, setFiltro] = useState([]);
     
    const handleClick = async () => {
        try {
            console.log(empresasel)
            if (empresasel.length>0){
                if (plansel.length>0){
                    setIsLoading(true)
                    const response = await fetch(API_BASE_URL+'/empresa/'+empresasel+'/'+plansel+'/'+fechadesde+'/'+fechahasta );
                    const result = await response.json();
                    setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
                    setIsLoading(false)
                }else {
                    alert("Seleccione Plan")
                }
            }else {
                alert("Seleccione Empresa")
            }
            
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
    const handleChangeSelect = (e) => {
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        selEmpresa(arrtemp.toString())
        console.log(empresasel)
    }
    const handleChangePlanSelect = (e) => {
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        selPlan(arrtemp.toString())
        console.log(plansel)
    }
    useEffect(() => {
        const date = new Date();
        const futureDate = date.getDate() + 7;
        const defaultfecha = date.toLocaleDateString('en-CA');
        date.setDate(futureDate);
        const defaultfecha2 = date.toLocaleDateString('en-CA');
        setFechaDesde(defaultfecha)
        setFechaHasta(defaultfecha2)
        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
        }
        ReporteDataService.obtener_parametros(parempresa)
            .then(result => {
                setEmpresa(result.data);
                //console.log(result.data)
                //setIsLoading(false)
            })
            .catch(e => {
                console.log(e);
            });
            const parplan = {
                "nombre_tabla": "tab_planestudio",
                "idresult": "convert(varchar,id_planestudio)",
                "codigo": "convert(varchar,id_planestudio)",
                "descripcion": "denominacion",
                "primer_valor": "[Todos las PLanes]",
                "campocondicion": "codigo_estado",
                "campovalor": "1"
            }
            ReporteDataService.obtener_parametros(parplan)
                .then(result => {
                    setPlan(result.data);
                    //console.log(result.data)
                    setIsLoading(false)
                })
                .catch(e => {
                    console.log(e);
                });

    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <div className="card card-custom">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">Reporte Usuarios Asignados</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                </h3>
                <div className="card-toolbar">
                    <button className='btn btn-primary btn-sm' onClick={handleClick}>Generar Reporte</button>
                    {datos?<>
                      <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(datos,'UCJ-Usuarios Asignados')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                    </>:<></>}
                  
                </div>
            </div>

            <div className="card-body">
                <div className="form-group row">
                    <div className="col-lg-3   input-group-sm mb-5">
                     <span className="label text-info">Seleccione: </span>
                        <Select  
                            placeholder="Empresas"
                            name="id"
                            isMulti
                            onChange={(e) => handleChangeSelect(e)}
                            options={empresa}
                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.codigo}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        <span className="label text-info">Seleccione: </span>
                        <Select  
                            placeholder="Plan de Estudio"
                            name="id"
                            isMulti
                            onChange={(e) => handleChangePlanSelect(e)}
                            options={plan}
                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.codigo}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        
                        <span className="label text-info">Fecha Desde: </span>
                        <input type="date" className="form-control"
                         id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                         defaultValue={fechadesde} />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                    <span className="label text-info">Hasta: </span>
                         <input type="date" className="form-control" 
                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                        defaultValue={fechahasta} />
                    </div>
                </div>
               
                <ToolkitProvider
                    keyField="id_asignacion"
                    data={datos}
                    columns={columns}
                    search           >
                    {
                        props => (
                            <div className="table-responsive">
                                <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                                <BootstrapTable className="table table-bordered table-responsive"
                                 pagination={ paginationFactory(options) }
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
               
            </div>

        </div>
    );

};

export default UsuarioReportexEmpresa;
