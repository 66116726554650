
 
import axios from 'axios';
import React, {Component} from 'react';
import { curso } from '../_models/curso';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/curso";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/curso";
class CursoService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getcursos(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {                'Content-Type': 'application/json'            }
        }) 
    }
    getcursosplan(idplan){
        return axios.get( API_BASE_URL+ '/sellst/plan/'+ idplan, {
            headers: {                'Content-Type': 'application/json'            }
        }) 
    }
    getcursosmodulo(curso){
        return axios.get( API_BASE_URL+ '/sellst/modulo/'+ curso, {
            headers: {                'Content-Type': 'application/json'            }
        }) 
    }
    getprogramacurso(programa){
        return axios.get( API_BASE_URL+ '/sellst/programa/'+ programa, {
            headers: {                'Content-Type': 'application/json'            }
        }) 
    }
    createcurso(curso){
        return axios.post(API_BASE_URL+ '/ins', curso,{
            headers: {'Content-Type': 'application/json'}
        })}
    createcursoprograma(curso){
            return axios.post(API_BASE_URL+ '/ins/programa', curso,{
                headers: {'Content-Type': 'application/json'}
        })}
    getcursoById(cursoId){
        return fetch(API_BASE_URL + '/sel/' + cursoId);
    }
    updatecurso(curso){
        return axios.post(API_BASE_URL + '/upd/' , curso,{
            headers: {'Content-Type': 'application/json'}
        })}

    deletecurso(cursoId){
        return axios.get(API_BASE_URL + '/del/' + cursoId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new CursoService()