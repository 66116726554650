
import React, { Component, useState } from "react";
import ParametroDataService from "../_services/parametro";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

const columns = [
  {
    dataField: 'id_parametro', text: 'Editar', formatter: (rowContent, row) => {
      return (
        <>
          <Link to={"/parametroedit?id=" + rowContent} 
          className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"            >
            <i className="far fa-edit fs-1qx text-primary"></i>
          </Link>
        </>
      )
    }
  },
  { dataField: 'codigo_parametro', text: 'Código', sort: true },

  { dataField: 'parametro', text: 'Parametro', sort: true },
  { dataField: 'dominio', text: 'Tabla', sort: true },
];
export default class ParametroLista extends Component {

  constructor(props) {
    super(props);
    this.state = {
      _parametros: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    const queryParameters = new URLSearchParams(window.location.search)
    const iddominio = queryParameters.get("id")
    ParametroDataService.getparametros(iddominio)
      .then(response => {
        this.setState({
          _parametros: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const { _parametros } = this.state;
    const queryParameters = new URLSearchParams(window.location.search)
    const iddominio_ = queryParameters.get("id")
    return (

      <div className="card card-flush">
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              Editar Parametros
            </div>
          </div>
          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-150px">

            </div>
            <Link to={"/parametroedit?idd=" + iddominio_}
              className="btn btn-icon-white btn-text-white btn-primary btn-sm">
                <i className="fa-solid fa-file "></i>
              Nuevo parametro
            </Link>
          </div>
        </div>

        <div className="card-body pt-0">

          <div className="col-lg-12">
            <ToolkitProvider
              keyField="id_parametro"
              data={_parametros}
              columns={columns}
              search           >
              {
                props => (
                  <div>
                    <SearchBar {...props.searchProps} placeholder="Buscar..." />
                    <hr />
                    <BootstrapTable className="table table-bordered"
                      {...props.baseProps}
                    />
                  </div>
                )
              }
            </ToolkitProvider>
          </div>
        </div></div>

    );
  }
}
