
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ContenidoDataService from "../_services/contenido";
import { useAuth } from "../app/modules/auth";
import TemaDataService from "../_services/tema";
import jsPDF from "jspdf";

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function CertificadoForm() {
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const idevaluacion = queryParameters.get("id")
    const pdf = new jsPDF("l", "mm", "a4");

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('boton')
    };

    const [contenidos, setContenidos] = useState([]);
    const [temas, setTemas] = useState([]);
    useEffect(() => {
        if (idevaluacion !== null) {
            fetch(API_BASE_URL + 'evaluacion/sel/' + idevaluacion, {
                method: 'GET', headers: { 'Content-Type': 'application/json' }
            })
                .then(response => response.json())
                .then(result => {
                    setTemas(result)
                    console.log(result);
                    pdf.addImage('media/fondo_certificado.png', 'png', 0, 0, 297, 210);
                    pdf.setFontSize(40);
                    pdf.text(result.nombres + result.apellido, 90, 100);
                    pdf.setDrawColor(153, 153, 153);
                    pdf.line(70, 105, 260, 105);
                    pdf.setFontSize(12);
                    pdf.setTextColor(153, 153, 153);
                    pdf.text('Por haber culminado con éxito el Tema: Induccion a JERUTH, se emite el presente certificado a solicitud', 70, 115);

                    pdf.html("", {
                        callback: function (pdf) {
                            pdf.save("Certificado-" + result.codigo);
                        },
                        x: 10,
                        y: 10
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        }

    }, []);
    return (
        <>
            <div className="card card-custom card-flush">

                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="alert alert-dismissible bg-light-warning d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">


                            <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>

                            <div className="text-center">
                                <h5 className="fw-bolder fs-1 mb-5">Certificado de Tema Generado</h5>

                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                                <div className="mb-9">
                                    <h3 className="anchor fw-bolder mb-2" id="icon-colors"><a href="#icon-colors"></a>
                                         
                                    </h3>
                                    Tema:<h1>{ temas.titulo}</h1>
                                </div>

                                <div className="mb-10">
                                    <div className="   ">
                                        
                                    </div>
                                    <div className="rating text-center">

                                    </div>
                                    
                                </div>
                                <div className="d-flex flex-center flex-wrap">

                                <Link to={"/Evaluacion"}
                                    className="btn btn-icon-white btn-text-white btn-primary">Volver a Certificados
                                </Link>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
        </>
    );
}
