import ReporteDataService from "../_services/reporte";
import React, { Component, useEffect, useState } from "react";
import LoadingSpinner from "../_components/LoadingSpinner";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
function ReporteDptoCeCo_Detalle(id_planestudio) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reporte, setReporte] = useState([]);
    const [columnas, setReporteCols] = useState([]);
   
    useEffect(() => {
      let isMounted = true;
    console.log(id_planestudio)
      const fetchData = async () => {
        setIsLoading(true);
        try {
            const arrparam = ({
                "id_planestudio": '2'
            })

            ReporteDataService.getgeneraldptoceco_cols(arrparam)
                .then(result => {
                    setReporteCols(result.data)
                })
            ReporteDataService.getgeneraldptoceco(arrparam)
                .then(result => {
                    setReporte(result.data)
                    console.log(result.data)
                })
        } catch (err) {
          if (isMounted) {
            //setData(null);
            setError(err.message);
          }
        }
  
        setIsLoading(false);
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, []);
  
    if (isLoading) {
      return <p><LoadingSpinner /></p>;
    }
  
    if (error) {
      return <p>Error: {error}</p>;
    }
  
    return (
      <div>
        <ToolkitProvider
                                                keyField="id"
                                                data={reporte}
                                                columns={columnas}
                                                search           >
                                                {
                                                    props => (
                                                        <div>
                                                            <SearchBar {...props.searchProps} placeholder="Buscar..." />
                                                            <hr />
                                                            <BootstrapTable className="table table-striped table-bordered table-sm"
                                                                {...props.baseProps}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>

      </div>
    );
  }
  
  export default ReporteDptoCeCo_Detalle;