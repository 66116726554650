import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import PlanestudioDataService from "../_services/planestudio";
import ProgramaDataService from "../_services/programa";
import CursoDataService from "../_services/curso";
import ModuloDataService from "../_services/modulo";
import TemaDataService from "../_services/tema";
 
export default function PlanEstudioFlujo() {

  const columns = [

    { dataField: 'id', text: 'Id', sort: true },
    { dataField: 'edt', text: 'EDT', sort: true },
    { dataField: 'denominacion', text: 'Nivel', sort: true },

    { dataField: 'denominacion', text: 'Plan', sort: true },
    { dataField: 'programa', text: 'Programa', sort: true },
    { dataField: 'fecha_inicio', text: 'Inicio', sort: true },
    { dataField: 'fecha_fin', text: 'Vence', sort: true },
    { dataField: 'centrocosto', text: 'Centro Costo', sort: true },
    { dataField: 'cargo', text: 'Cargo', sort: true },
    { dataField: 'usuarios', text: 'Usuarios', sort: true },
    { dataField: 'evaluados', text: 'Evaluados', sort: true },

  ];

  const [plan, setPlan] = useState([]);
  const [programas, setPrograma] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [temas, setTemas] = useState([]);
  const { currentUser, logout } = useAuth()
  const [isOnline, setIsOnline] = useState(null);
  const [seguimiento, setSeguimiento] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search)

  const idplanestudio = queryParameters.get("id")
  useEffect(() => {
    PlanestudioDataService.getplanestudioById(idplanestudio)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        setPlan(result);
        setIsOnline(true)
      })
      .catch(e => {
        console.log(e);
      });
    PlanestudioDataService.getplanprograma(idplanestudio)

      .then(response => {
        setPrograma(response.data)
        //console.log(response.data);
        setIsOnline(true)
      })
      .catch(e => {
        console.log(e);
      });
    PlanestudioDataService.getplancurso(idplanestudio)
      .then(function (response) {
        //console.log(response.data)
        setCursos(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    PlanestudioDataService.getplanmodulo(idplanestudio)
      .then(function (response) {
        //console.log(response.data)
        setModulos(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    PlanestudioDataService.getplantemas(idplanestudio)
      .then(function (response) {
        console.log(response.data)
        setTemas(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [isOnline]);
  if (isOnline === null) {
    console.log(isOnline);
    return <>'Cargando...'</>;
  } else {
    return (

      <>

        <div className='row'>
          <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">

              <div className="row  mb-5 mb-xl-8">
                <div className="col-xl-12">
                  <div className="card  card-custom " >
                    <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                      <div className="card-title d-flex py-5 pt-0">
                        <h3 className="card-label d-flex">
                          <i claclassNamess="las la-chalkboard-teacher text-danger fs-2x"></i>
                          <div className="bullet bg-primary h-35px w-1px mx-5"> </div>
                          <span className="card-label fw-bold text-info fs-2x">Plan de Estudio - Flujo</span>
                        </h3>
                      </div>

                      <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                        <div className="w-100 mw-150px">
                        <Link to={"/planestudio"}
                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                <i className="fa-solid fa-reply "></i>
                                Volver
                            </Link>
                        </div>

                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div className="accordion accordion-icon-toggle" id="">
                        <>
                          <div className='accordion-item'>
                            <h2 className='accordion-header' id={'plan' + plan.id_planestudio + 'header'}>
                              <button
                                className='accordion-button   pt-0 text-danger'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={'#plan_' + plan.id_planestudio + '_body'}
                                aria-expanded='true'
                                aria-controls={'plan_' + plan.id_planestudio + '_body'}
                              >
                                Plan: {plan.denominacion}


                              </button>

                            </h2>
                            <div id={'plan_' + plan.id_planestudio + '_body'}
                              className='accordion-collapse collapse show'
                              aria-labelledby={'plan_' + plan.id_planestudio + '_header'}
                              data-bs-parent='#plan' >
                              <div className='accordion-body pt-0'>
                                {programas.map((programa, index) => (
                                  <>
                                    <div className='accordion-item'>
                                      <h2 className='accordion-header' id={'programa' + programa.id_programa + 'header'}>
                                        <button
                                          className='accordion-button  pt-0 '
                                          type='button'
                                          data-bs-toggle='collapse'
                                          data-bs-target={'#programa_' + programa.id_programa + '_body'}
                                          aria-expanded='true'
                                          aria-controls={'programa_' + programa.id_programa + '_body'}
                                        >
                                          <span className="text-success"> </span> {programa.titulo}
                                        </button>
                                      </h2>
                                      <div id={'programa_' + programa.id_programa + '_body'}
                                        className='accordion-collapse collapse show'
                                        aria-labelledby={'programa_' + programa.id_programa + '_header'}
                                        data-bs-parent='#plan' >
                                        <div className='accordion-body pt-0'>

                                          {cursos.filter(curso => curso.id_programa == programa.id_programa).map(filteredCurso => (

                                            <>
                                              <div className='accordion-item'>
                                                <h2 className='accordion-header' id={'curso' + filteredCurso.id_curso + 'header'}>
                                                  <button
                                                    className='accordion-button  pt-0  '
                                                    type='button'
                                                    data-bs-toggle='collapse'
                                                    data-bs-target={'#curso_' + filteredCurso.id_curso + '_body'}
                                                    aria-expanded='true'
                                                    aria-controls={'curso_' + filteredCurso.id_curso + '_body'}
                                                  >
                                                    <span className="text-warning"></span> {filteredCurso.titulo}
                                                  </button>
                                                </h2>
                                                <div id={'curso_' + filteredCurso.id_curso + '_body'}
                                                  className='accordion-collapse collapse show'
                                                  aria-labelledby={'curso_' + filteredCurso.id_curso + '_header'}
                                                  data-bs-parent='#curso3' >
                                                  <div className='accordion-body pt-0'>
                                                    {modulos.filter(modulo => modulo.id_curso == filteredCurso.id_curso).map(filteredModulo => (
                                                      <>
                                                        <div className='accordion-item'>
                                                          <h2 className='accordion-header' id={'modulo_' + filteredModulo.id_modulo + '_header'}>
                                                            <button
                                                              className='accordion-button  pt-0 collapsed'
                                                              type='button'
                                                              data-bs-toggle='collapse'
                                                              data-bs-target={'#modulo_' + filteredModulo.id_modulo + '_body'}
                                                              aria-expanded='false'
                                                              aria-controls={'modulo_' + filteredModulo.id_modulo + '_body'}
                                                            >
                                                              <span className="text-danger"></span> {filteredModulo.titulo}
                                                            </button>
                                                          </h2>
                                                          <div id={'modulo_' + filteredModulo.id_modulo + '_body'}
                                                            className='accordion-expanded expanded p-2'
                                                            aria-labelledby={'modulo_' + filteredModulo.id_modulo + '_header'}
                                                            data-bs-parent='#modulo' >
                                                            <div className='accordion-body p-0 pt-0'>
                                                              
                                                                {temas.filter(tema => tema.id_modulo == filteredModulo.id_modulo).map(filteredTema => (
                                                                  
                                                                   
                                                                    <div className="btn-toolbar justify-content-between" role="toolbar"
                                                                      aria-label="Toolbar with button groups">
                                                                      <div className="btn-group" role="group" aria-label="First group">
                                                                        {currentUser?.indicador_soporte?
                                                                        <>
                                                                         <Link to={"/temareview?id=" + filteredTema.id_tema}
                                                                            className=" label text-danger  "               >
                                                                               <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                                                                            {filteredTema.codigo} -{filteredTema.titulo}
                                                                          </Link>
                                                                        </>:<>
                                                                          <Link to={"/moduloedit?id=" + filteredTema.id_modulo}
                                                                            className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                                                                            M
                                                                          </Link>
                                                                          <Link to={"/temaedit?id=" + filteredTema.id_tema}
                                                                            className="btn btn-link btn-color-info   "               >
                                                                            {filteredTema.codigo} -{filteredTema.titulo}
                                                                          </Link>
                                                                        </>}
                                                                        
                                                                      </div>
                                                                    </div>

                                                                   
                                                                ))}

                                                               
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ))}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}