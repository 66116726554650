import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import UsuarioDataService from "../_services/usuario";
import AsignacionDataService from "../_services/asignacion";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

const { SearchBar } = Search;
export default function DashboardTemaPanel() {
    var bgColors = {
        "Default": "#81b71a",
        "Blue": "#00B1E1",
        "Cyan": "#37BC9B",
        "Green": "#8CC152",
        "Red": "#E9573F",
        "Yellow": "#F6BB42",
    };

    const { currentUser, logout } = useAuth()
    const [seguimiento, setSeguimiento] = useState([]);
    const [kpi, setKPI] = useState([]);
    const [isOnline, setIsOnline] = useState(null);
    const [asignacion, setAsignacion] = useState([]);

    useEffect(() => {
        setIsOnline(true)
    }, [isOnline]);
    if (isOnline === null) {
        console.log(isOnline);
        return <>'Cargando...'</>;
    } else {
        return (

            <>
                <div className='row'>

                    <div className="col-lg-3">
                        <a   className="card bg-danger hoverable card-xl-stretch mb-xl-2">
                            <div className="card-body">
                                <i className="fa fa-users text-white fs-2x  ">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">99
                                </div>
                                <div className="fw-semibold text-white">Usuarios Activos
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3">
                        <a   className="card bg-warning hoverable card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-bars text-white fs-2x">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">99%
                                </div>
                                <div className="fw-semibold text-white">Porcentaje de Avance
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3">
                        <a   className="card bg-success hoverable card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-calendar text-white fs-2x">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">99
                                </div>
                                <div className="fw-semibold text-white">Dias por vencer
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3">
                        <a   className="card bg-primary hoverable card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-file-contract text-white fs-2x">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">99
                                </div>
                                <div className="fw-semibold text-white">Certificados Emitidos
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="separator  my-5  border-secondary"></div>
                    <div className='col-lg-6 '>
                        <table className="table table-responsive table-bordered"  >
                            <thead>
                                <tr className="fw-bold fs-6 border bg-primary text-white">
                                    <th>Empresa</th>
                                    <th>Centro Costo</th>
                                    <th>#Usuarios</th>
                                    <th>Porcentaje</th>
                                    <th>Promedio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>EL</td>
                                    <td>GERENCIA GENERAL</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                </tr>
                                <tr>
                                    <td>EL</td>
                                    <td>GERENCIA LIMA - IQUI</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                </tr>
                                <tr>
                                    <td>EL</td>
                                    <td>MEGA PLAZA RETAI. D</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-lg-6 '>
                        <table className="table table-responsive table-bordered"  >
                            <thead>
                                <tr className="fw-bold fs-6 border bg-primary text-white">
                                    <th>Empresa</th>
                                    <th>Tema</th>
                                    <th>Rating</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>EL</td>
                                    <td>Quienes Somos</td>
                                    <td>⭐⭐⭐</td>
                                    
                                </tr>
                                <tr>
                                    <td>EL</td>
                                    <td>GERENCIA LIMA - IQUI</td>
                                    <td>⭐⭐</td>
                                </tr>
                                <tr>
                                    <td>EL</td>
                                    <td>Valores</td>
                                    <td>⭐⭐⭐⭐</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-lg-6 '>
                    <h2 className="text-primary">Usuarios que no Tomaron el Cuestionario</h2>
                        <table className="table table-responsive table-bordered"  >
                            <thead>
                                <tr className="fw-bold fs-6 border bg-primary text-white">
                                    <th>Empresa</th>
                                    <th>Usuario</th>
                                    <th>Correo</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>EL</td>
                                    <td>MArtin Alonso</td>
                                    <td>mhuaman@panoramapo.com</td>
                                </tr>
                                <tr>
                                    <td>EL</td>
                                    <td>Mary Yllesca</td>
                                    <td>myllesc@panoramapo.com</td>
                                </tr>                                 
                            </tbody>
                        </table>
                    </div>
                    <div className='col-lg-6 '>
                        <h2 className="text-primary">Promedio de Nota </h2>
                        <table className="table table-responsive table-bordered"  >
                            <thead>
                                <tr className="fw-bold fs-6 border bg-primary text-white">
                                    <th>Empresa</th>
                                    <th>Nota</th>
                                    
                                  
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>EL</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }%</td>
                                </tr>
                                <tr>
                                    <td>PanoramaBPO</td>
                                    <td>{ Math.floor(Math.random() * 100).toString() }%</td>
                                </tr>                                 
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="separator my-1"></div>

            </>
        )
    }
}