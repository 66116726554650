import React, { useCallback, Component, useEffect, useState } from "react";
import axios from 'axios';
import LoadingSpinner from "../_components/LoadingSpinner";
import Select from 'react-select';
import ReporteDataService from "../_services/reporte";
import AsignacionDataService from "../_services/asignacion";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import EmailService from "../_services/email";
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Link } from "react-router-dom";
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const API_BASE_URL = process.env.REACT_APP_API_URL+"/asignacion";

const options = {
    sizePerPageList: [{
      
      text: '100', value: 100
    } , {
        text: '250', value: 250
      }, {
        text: '500', value: 500
      }, {
        text: '1000', value: 1000
      }] // A numeric array is also available. the purpose of above example is custom the text
  };
const columns = [
    {
      dataField: 'id_usuario', text: 'Notifica', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {row["correo_electronico"] != '' ?
              <>
                <a className="btn btn-outline btn-icon btn-sm"
                  onClick={() => handleEmail(row)}>
                  <i className="fa fa-envelope   text-warning"> </i>
                </a>
              </> :
              <> </>}
          </>
        )
      }
    },
    { dataField: 'plan', text: 'Plan', sort: true },
    { dataField: 'fecha_creacion', text: ' Asignado', sort: true },
    { dataField: 'codigo_grupo', text: 'Grupo', sort: true, csvText: 'Codigo'},
    { dataField: 'empresa', text: 'Empresa', sort: true, csvText: 'Codigo'},

    { dataField: 'codigo', text: 'Código', sort: true, csvText: 'Codigo'},
    {
      dataField: 'nombres', text: 'Usuario', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {row["nombres"]} {row["apellido"]}
          </>
        )
      }
    },

    { dataField: 'correo_electronico', text: 'Email', sort: true },


    { dataField: 'fecha_inicio', text: 'Inicio', sort: true },
    { dataField: 'fecha_fin', text: 'Fin', sort: true },
  ];
  const exportToCSV = (csvData, fileName) => {
    const ddlreporte = [];
    for (var i = 0, l = csvData.length; i < l; i++) {
        ddlreporte.push({
            "plan": csvData[i].plan, 
            "fecha_creacion":  csvData[i].fecha_creacion ,
            "codigo_grupo":  csvData[i].codigo_grupo,
            "empresa":  csvData[i].empresa        ,
            "codigo":  csvData[i].codigo,
            "personal":  csvData[i].nombres,
            "correo_electronico":  csvData[i].correo_electronico,
            "fecha_inicio":  csvData[i].fecha_inicio,
            "fecha_fin":  csvData[i].fecha_fin 
        })
      }
      const ws = XLSX.utils.json_to_sheet(ddlreporte);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }
  const handleEmail = (row) => {
    const answer = window.confirm("Seguro de Enviar Notificacion de Asignacion al Usuario?");
    if (answer) {
      //console.log(row);
      const datausuario = []
      datausuario.push({
        "destinatario": row.correo_electronico,
        "nombre": row.nombres,
        "codigo": row.codigo,
        "plan": row.plan,
        "fecha_vencimiento": row.fecha_fin
      })
      //console.log(JSON.stringify(datausuario));
      EmailService.asignausuario(JSON.stringify(datausuario))
        .then(response => {
          //console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }


  }
const AsignacionLista = () => {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    const [empresasel, selEmpresa] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const [codigousuario, setCodigo] = useState("");
    const [filtro, setFiltro] = useState([]);
    const [eliminar, setEliminar] = useState([]);
    const selectedRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
          console.log('=== selectRow row ===', row);
          //setEliminar((empresa) => ({ ...prev, row}))
          //console.log(eliminar.length)
          if (empresa.length==0) {
            setEliminar(row);
          } else {
            setEliminar(empresa => [...empresa, row]);
          }
          console.log(eliminar)
          //console.log(eliminar.length)
      },
      onSelectAll: (isSelected, rows) => {
          console.log('=== selected All Row  ===', rows);
          setEliminar(rows)
          if (isSelected) {
               
          } else {

          }
          
      }
  };
    const handleClick = async () => {
        try {
            console.log(empresasel)
            setEliminar([])
            if (empresasel.length>0){
              setIsLoading(true)
              console.log(codigousuario)
              const response = await fetch(API_BASE_URL+'/sellst/reporte/'+empresasel+'/'+fechadesde+'/'+fechahasta+'/'+codigousuario );
              const result = await response.json();
              setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
              setIsLoading(false)
            }else {
              alert("Seleccione Empresa")
            }
            
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
  const handleClickEliminar = async () => {
      try {
        const answer = window.confirm("Esta seguro de Eliminar los registros seleccionados?");
        if (answer) {
          //console.log(eliminar)
          AsignacionDataService.eliminarasignacion(JSON.stringify(eliminar))
          .then(function (response) {
            alert("Se eliminaron " + eliminar.length + " registros!")
            console.log( response);
            handleClick();
          })
          .catch(function (error) {
            console.log(error);
          });
        }
          
           
          
      } catch (error) {
          console.error('Hubo un error al obtener los datos:', error);
      } 
  };
    const handleChangeSelect = (e) => {
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        selEmpresa(arrtemp.toString())
        console.log(empresasel)
    }
    const handleChange = (e) => {
      setCodigo(e.target.value);
      };
    useEffect(() => {
        const date = new Date();
        const futureDate = date.getDate() + 7;
        // 
        const defaultfecha = date.toLocaleDateString('en-CA');
        date.setDate(futureDate);
        const defaultfecha2 = date.toLocaleDateString('en-CA');

        setFechaDesde(defaultfecha)
        setFechaHasta(defaultfecha2)
        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
        }
        ReporteDataService.obtener_parametros(parempresa)
            .then(result => {
                setEmpresa(result.data);
                //console.log(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log(e);
            });


    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <div className="card card-custom">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">Listado de Usuarios Asignados</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                </h3>
                <div className="card-toolbar">
                    <button className='btn btn-primary btn-sm' onClick={handleClick}>
                    <i className="fa-solid fa-file "></i>Generar Listado</button>
                    <button className='btn btn-danger btn-sm' onClick={handleClickEliminar}>
                    <i className="fa-solid fa-trash "></i>Eliminar Seleccionados</button>

                    {datos?<>

                      <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(datos,'UCJ-Usuarios Asignados')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                    </>:<></>}
                    <Link  to="/asignacioneliminar"  className="btn btn-info btn-sm" >
                             <i className="fa-solid fa-trash "></i>Eliminar Masivamente</Link>
                </div>
            </div>

            <div className="card-body">
                <div className="form-group row">
                    <div className="col-lg-3   input-group-sm mb-5">
                        Seleccione Empresa:
                        <Select className=" "
                            placeholder="Empresas"
                            name="id"
                            isMulti
                            onChange={(e) => handleChangeSelect(e)}
                            options={empresa}
                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.codigo}
                            
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Codigo:
                        <input type="text" className="form-control" id="codigo_usuario"
                            placeholder="Codigo Usuario" name="codigo_usuario"
                            onChange={handleChange} ></input>
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Fecha Desde: <input type="date" className="form-control"
                         id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                         defaultValue={fechadesde} />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Hasta: <input type="date" className="form-control" 
                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                        defaultValue={fechahasta} />
                    </div>
                </div>
               
                <ToolkitProvider
                    keyField="id_asignacion"
                    data={datos}
                    columns={columns}
                    
                    search           >
                    {
                        props => (
                            <div className="table-responsive">
                                <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                                <BootstrapTable 
                                selectRow={selectedRow}
                                className="table table-bordered table-responsive"
                                 pagination={ paginationFactory(options) }
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
               
            </div>

        </div>
    );

};

export default AsignacionLista;
