
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PreguntaDataService from "../_services/pregunta";
import { useAuth } from "../app/modules/auth";
import ParametroDataService from "../_services/parametro"; 

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function PreguntaForm() {
  const { currentUser, logout } = useAuth()
  const [preguntas, setPregunta] = useState([]);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idpregunta = queryParameters.get("id")
  const [categoriapregunta, setCategoriaPregunta] = useState([]);

  const handledelete = async (e)=>{
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idpregunta !== null) {
        PreguntaDataService.deletepregunta(idpregunta)
        .then(result => {
            //setResult(result.data);
            console.log(result.data);
            if (result.data.result=='0'){
                alert("Registro Eliminado con Éxito" )
                navigate('/pregunta');
            }else {
                alert("Registro no se puede eliminar por dependencias : \n"+  result.data.message )
            }
            //console.log(resultado);

        })
        .catch(e => {
            console.log(e);
        });
         
      }
      
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const answer = window.confirm("Esta seguro de Guardar el Registro?");
    if (answer) {
      if (idpregunta == null) {
        const arrpregunta= [{
          pregunta:preguntas.pregunta,
          rpta_a:preguntas.rpta_a,
          rpta_b:preguntas.rpta_b,
          rpta_c:preguntas.rpta_c,
          rpta_d:preguntas.rpta_d,
          indicador_correcta:preguntas.indicador_correcta,
          usuario_creacion:currentUser?.username,
          codigo_estado:1,
          codigo_categoria:preguntas.codigo_categoria
        }]
       // preguntas.usuario_creacion = currentUser?.username
        console.log(arrpregunta);
        PreguntaDataService.createpregunta(arrpregunta)
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            alert("Registro Insertado correctamente");
            navigate('/pregunta');
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        const arrpregunta= ({
          id_pregunta: idpregunta,
          pregunta:preguntas.pregunta,
          rpta_a:preguntas.rpta_a,
          rpta_b:preguntas.rpta_b,
          rpta_c:preguntas.rpta_c,
          rpta_d:preguntas.rpta_d,
          indicador_correcta:preguntas.indicador_correcta,
          usuario_modificacion:currentUser?.username,
          codigo_estado:1,
          codigo_categoria:preguntas.codigo_categoria

        })
        //preguntas.usuario_modificacion = currentUser?.username
        console.log(JSON.stringify(arrpregunta));
        PreguntaDataService.updatepregunta(JSON.stringify(arrpregunta))
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            alert("Registro Actualizado correctamente");
            navigate('/pregunta');
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
  };
  const handleChange = (e) => {
    console.log();
    // console.log(e.target.value);
    // setTodo({ ...todo, [e.target.name]: e.target.value });
    setPregunta((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  
  useEffect(() => {
    if (idpregunta  !== null){
      PreguntaDataService.getpreguntaById(idpregunta)
        .then(response => response.json())
        .then(result => {
          setPregunta(result);
          //console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
    }
    ParametroDataService.getparametrosbycod('categoria_pregunta')
      .then(response => {
        setCategoriaPregunta(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);
  return (
    <div className="card card-custom">
      <form onSubmit={handleSubmit}>
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <h2>Editar Pregunta</h2>
          </div>
        </div>
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 mw-150px">
          </div>
          <Link to={"/pregunta"}
              className="btn btn-icon-white btn-text-white btn-danger btn-sm">
              <i className="fa-solid fa-reply "></i>
              Volver
            </Link>
            <button className='btn btn-primary btn-sm' type="submit">
              <i className="fa-solid fa-floppy-disk"></i>
              Guardar</button>
        </div>
      </div>
      <div className="card-body pt-10">
        
        <div className="form-group row">
        <div className="col-lg-12  input-group-sm mb-5">
          <div className="input-group input-group-sm mb-5">
            <span className="input-group-text" id="inputGroup-sizing-sm">Pregunta</span>
            <input type="text" name="pregunta" defaultValue={preguntas.pregunta}
              className="form-control " onChange={handleChange} required maxLength={250} />
          </div>
          </div>
          <div className="col-lg-6  input-group-sm mb-5">
          <div className="input-group input-group-sm mb-5">
            <span className="input-group-text" id="inputGroup-sizing-sm">Alternativa A</span>
            <input type="text" name="rpta_a" defaultValue={preguntas.rpta_a}
              className="form-control" onChange={handleChange} required maxLength={250}/>
          </div></div>
          <div className="col-lg-6   ">
          <div className="input-group input-group-sm mb-5">
            <span className="input-group-text" id="inputGroup-sizing-sm">Alternativa B</span>
            <input type="text" name="rpta_b" defaultValue={preguntas.rpta_b}
              className="form-control" onChange={handleChange} required maxLength={250}/>
          </div></div>
          <div className="col-lg-6  input-group-sm mb-5">
          <div className="input-group input-group-sm mb-5">
            <span className="input-group-text" id="inputGroup-sizing-sm">Alternativa C</span>
            <input type="text" name="rpta_c" defaultValue={preguntas.rpta_c}
              className="form-control" onChange={handleChange} required maxLength={250}/>
          </div></div>
          <div className="col-lg-6  input-group-sm mb-5">
          <div className="input-group input-group-sm mb-5">
            <span className="input-group-text" id="inputGroup-sizing-sm">Alternativa D</span>
            <input type="text" name="rpta_d" defaultValue={preguntas.rpta_d}
              className="form-control" onChange={handleChange} required maxLength={250}/>
          </div></div>
          <div className="col-lg-6  input-group-sm mb-5">
          <div className="input-group input-group-sm mb-5">
            <span className="input-group-text" id="inputGroup-sizing-sm">Alternativa Correcta</span>
            
              <select className="form-control" name="indicador_correcta" 
              value={preguntas.indicador_correcta} 
              required onChange={handleChange} >
              <option  >-- Seleccione --</option>
                <option value={"1"}>Respuesta A</option>
                <option value={"2"}>Respuesta B</option>
                <option value={"3"}>Respuesta C</option>
                <option value={"4"}>Respuesta D</option>
              </select>
          </div></div>
          <div className="col-lg-6   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Categoria Pregunta</span>
                <select value={preguntas.codigo_categoria}
                  className="form-control  "
                  name="codigo_categoria" required
                  onChange={handleChange}>
                  <option value=""> -- Seleccione -- </option>
                  {categoriapregunta.map((parametro) => <option value={parametro.codigo_parametro}>{parametro.parametro}</option>)}
                </select>
              </div>
            </div>
          <div className="mb-10">

            
          </div>
          </div>
        
      </div>
      </form>
      <div className="card-footer">
        <button type="button"  style={idpregunta>0? {display:"block"}:{ display: "none" }}  
            className="btn btn-outline btn-outline-dashed btn-outline-danger  btn-sm"  
            onClick={handledelete}                >
            Eliminar Registro
        </button>
      </div>
    </div>
  );
}
