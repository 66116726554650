

import { Link } from "react-router-dom"
import EvaluacionForm from "../../../_components/evaluacionForm";
  
export function EvaluacionEditar  () {
  
  
  return (
 <div className="  d-flex flex-column flex-root" id="kt_docs_content">
            <div className=" container  " id="kt_docs_content_container">
                
                        <EvaluacionForm></EvaluacionForm>
                     
            </div>
        </div>
  );
}