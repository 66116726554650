import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReporteDataService from "../_services/reporte";
import PlanDataService from "../_services/planestudio";
import LoadingSpinner from "../_components/LoadingSpinner";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import ReporteDptoCeCo_Detalle from "./reporte_dptoceco";
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const arrplan = []
const arrempresa = []

const ddlplan = [];
const ddlempresa = [];
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte/";

function ReporteDptoCeCo() {
    const [datos, setDatos] = useState([]);
    const [columnas, setReporteCols] = useState([]);
    const [isOnline, setIsOnline] = useState(null);
    const [plansel, selPlan] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const [plan, setPlan] = useState([]);
    const [grupo, setGrupo] = useState(null);
    const [empresa, setEmpresa] = useState([]);
    const [empresasel, selEmpresa] = useState([]);

    const [data, setData] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(datos);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handleChangeSelect = (e) => {
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        selEmpresa(arrtemp.toString())
        console.log(empresasel)
    }
    const handleChangePlanSelect = (e) => {
        console.log(e)
        selPlan(e.id)
       
    }
    const handleChange = (e) => {
        for (var i = 0, l = e.length; i < l; i++) {
            arrempresa.push(e[i].value)
        }
        console.log(arrempresa)
        console.log(arrempresa.toString())
    }
    const handleChangePlan = (e) => {

        console.log(e.id_planestudio);
    }
    const handleClick = async () => {
        console.log(plansel)
        if (plansel =='') {
            alert("seleccione un Plan de Estudio")
        } else {
            setIsLoading(true);
            console.log(empresasel)
            console.log(plansel)
            console.log(fechadesde)
            console.log(fechahasta)
            ReporteDataService.getgeneraldptoceco_cols(empresasel,plansel)
                .then(result => {
                    console.log(result.data);
                    setReporteCols(result.data)
                })
                const response = await fetch(API_BASE_URL+'/general/dpto/'+empresasel+'/'+plansel+'/'+fechadesde+'/'+fechahasta );
                const result = await response.json();
                console.log(result)
                setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
                setIsLoading(false)
            

        }

    };
    useEffect(() => {

        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
          }
          ReporteDataService.obtener_parametros(parempresa)
            .then(result => {
              setEmpresa(result.data);
              //setIsLoading(false)
            })
           
      
          const parplan = {
            "nombre_tabla": "tab_planestudio",
            "idresult": "convert(varchar,id_planestudio)",
            "codigo": "denominacion",
            "descripcion": "denominacion",
            "primer_valor": "",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
          }
          ReporteDataService.obtener_parametros(parplan)
            .then(result => {
              setPlan(result.data)
               
            })
            const date = new Date();
            const futureDate = date.getDate() + 7;
            const defaultfecha = date.toLocaleDateString('en-CA');
            date.setDate(futureDate);
            const defaultfecha2 = date.toLocaleDateString('en-CA');
            setFechaDesde(defaultfecha)
            setFechaHasta(defaultfecha2)
    }, [isLoading]);
    if (isLoading  ) {
        return <> <LoadingSpinner /> </>;
    } 
        return (
            <>
                <div className="row">


                    <div className="col-lg-12">
                        <div className="card card-custom">
                            <div className="card-header pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Reporte por Departamento y Centro de Costos</span>
                                    <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                                </h3>
                                <div className="card-toolbar">
                                     

                                    <button className="btn btn-primary btn-sm"

                                        onClick={handleClick}>
                                        <i className="fa-solid fa-print "></i>Generar Reporte</button>

                                    <button variant="warning" className="btn btn-success btn-sm"
                                        onClick={(e) => exportToCSV(datos, 'UCJ-Reporte Dpto CeCo')}>
                                        <i className="fa-solid fa-file-excel "></i>Exportar</button>

                                </div>
                            </div>

                        </div>
                    </div>
                   
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className="col-lg-3   input-group-sm mb-5">
                                    <span className="label text-info">Seleccione: </span>
                                        <Select  
                                            placeholder="Empresas"
                                            name="id"
                                            isMulti
                                            onChange={(e) => handleChangeSelect(e)}
                                            options={empresa}
                                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.codigo}
                                        />
                                    </div>
                                    <div className="col-lg-3   input-group-sm mb-5">
                                        <span className="label text-info">Seleccione: </span>
                                        <Select  
                                            placeholder="Plan de Estudio"
                                            name="id"
                                             
                                            onChange={(e) => handleChangePlanSelect(e)}
                                            options={plan}
                                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.descripcion}
                                        />
                                    </div>
                                    <div className="col-lg-3   input-group-sm mb-5">
                                        
                                        <span className="label text-info">Fecha Desde: </span>
                                        <input type="date" className="form-control"
                                        id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                                        defaultValue={fechadesde} />
                                    </div>
                                    <div className="col-lg-3   input-group-sm mb-5">
                                    <span className="label text-info">Hasta: </span>
                                        <input type="date" className="form-control" 
                                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                                        defaultValue={fechahasta} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="container">
                                <div className="table-responsive">
                                  
                                    {columnas.length > 0 ?
                                        <>

                                            <ToolkitProvider
                                                keyField="id"
                                                data={datos}
                                                columns={columnas}
                                                search           >
                                                {
                                                    props => (
                                                        <div>
                                                            <SearchBar {...props.searchProps} placeholder="Buscar..." />
                                                            <hr />
                                                            <BootstrapTable className="table table-striped table-bordered table-sm"
                                                                {...props.baseProps}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        </> :
                                        <>
                                            <span></span>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    export default ReporteDptoCeCo;