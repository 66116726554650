import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { Link, useNavigate } from "react-router-dom";

import AsignacionDataService from "../_services/asignacion";
import LoadingSpinner from "../_components/LoadingSpinner";
import PlanestudioDataService from "../_services/planestudio";

export default function PlanEstudioFin() {
    const { currentUser, logout } = useAuth()
    const [plan, setPlan] = useState([]);
    const [pendiente, setPendiente] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search)
    const idplanestudio = queryParameters.get("id")
    const [finplan, setFinPlan] = useState(false);

    const navigate = useNavigate();
    //console.log(queryParameters)
    useEffect(() => {
        PlanestudioDataService.getplanestudioUsuarioById(idplanestudio,currentUser?.id)
            .then(response => response.json())
            .then(result => {
                setPlan(result);
                console.log(result);
            })
            .catch(e => {
            console.log(e);
            });
        AsignacionDataService.getAsignacionByUsuarioPendiente(currentUser?.id,idplanestudio)
            .then(response => {
                setPendiente(response.data[0])
            //console.log(response.data[0])
               // console.log(pendiente.length)
               // console.log(response.data[0])
               // console.log(response.data.length)
               
          if (response.data.length==0){
                console.log('no redirecciona')
                setFinPlan(true)
                //navigate('/dashboardusuario');
          } else {
            console.log('redirecciona')
            //navigate('/dashboardusuario');
            setFinPlan(false)
            //navigate('/evaluacionresult?id=' + ideval);
            //logdataservice.registralog("dashboardusuario","","", currentUser.id, currentUser.username);

          }
      })
      .catch(e => {
          console.log(e);
      });
    }, [isLoading]);
    if (isLoading) {
       return <> <LoadingSpinner />  </>;
     }
    return (

        <>

            <div className='row'>
                <div className="card card-flush h-md-100">
                    <div className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0"
                    >
                        <img className="mx-auto h-150px h-lg-200px  theme-light-show"
                            src="/content/buhofinal.png" alt="" ></img>
                        <img className="mx-auto h-150px h-lg-200px  theme-dark-show"
                            src="/content/buhofinal.png" alt=""></img>
                        <div className="mb-10">
                            <div className="fs-2hx fw-bold text-gray-800 text-center mb-13">
                                <span className="me-2">
                                    <span className="position-relative d-inline-block text-danger">
                                        <a  className="text-danger opacity-75-hover">¡Felicitaciones!</a>
                                        <span className="position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100">
                                        </span>
                                    </span>
                                </span>
                                <br></br>
                                <span className="fs-1">Haz culminado el Plan de Estudio : </span>
                                <br></br>
                                <span className="label">{plan.denominacion}</span>
                                <br></br>
                                <span className="fs-1">Tu Puntaje promedio es  : <span className="label text-danger">{plan.promedio}%</span></span>
                            </div>
                            <div className="text-center">
                                <Link to={"/dashboardusuario"} className="btn btn-sm btn-danger fw-bold" >
                                    Continuar 
                                </Link>
                                <br></br>
                                <span className="label label-info fs-1"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}