
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import UsuarioDataService from "../_services/usuario";
import Select from 'react-select';
import EmpresaDataService from "../_services/empresa";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function EmpresaForm() {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const idempresa = queryParameters.get("id")
    const handleSubmit = async (e) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Guardar el Registro?");
        if (answer) {
            //console.log(dominios);
            if (idempresa == null) {
                EmpresaDataService.createempresa( JSON.stringify(empresa))
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        alert("Registro Insertado correctamente");
                        navigate('/empresa');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                empresa.usuario_modificacion = 'admin'
                empresa.id_empresa= idempresa
                console.log(empresa)
                 
                EmpresaDataService.updateempresa( JSON.stringify(empresa))
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));
                        alert("Registro Actualizado correctamente");
                        navigate('/empresa');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }
        }
    };
    const handleChange = (e) => {
        console.log();
        setEmpresa((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
          }));
    };
    const [empresa, setEmpresa] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [usuario, setUsuario] = useState([]);

    useEffect(() => {
        fetch(API_BASE_URL + 'empresa/sel/' + idempresa, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {
                setEmpresa(result);
                console.log(result);
            })
            .catch(e => {
                console.log(e);
            });
        UsuarioDataService.getUsuarios()
            .then(result => {
              setUsuario(result.data);
            });
    }, []);
    return (
        <div className="card card-custom">
            <form onSubmit={handleSubmit}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <h2>Editar Empresa</h2>
                        </div>
                    </div>

                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                        <div className="w-100 mw-150px">

                        </div>
                        <Link to={"/empresa"}
                            className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        <button className='btn btn-primary btn-sm' type="submit">
                            <i className="fa-solid fa-floppy-disk"></i>
                            Guardar</button>

                    </div>
                </div>
                <div className="card-body pt-10">
                    <div className="form-group row">
                        <div className="col-lg-3  input-group-sm mb-5">
                            <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Codigo</span>
                                <input type="text" name="codigo_empresa" defaultValue={empresa.codigo_empresa}
                                    className="form-control " onChange={handleChange} required maxLength={250} />
                            </div>
                        </div>
                        <div className="col-lg-9  input-group-sm mb-5">
                            <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Empresa</span>
                                <input type="text" name="empresa" defaultValue={empresa.empresa}
                                    className="form-control " onChange={handleChange} required maxLength={500} />
                            </div>
                        </div>
                        <div className="col-lg-6  input-group-sm mb-5">
                            <div className="input-group input-group-sm mb-5">
                            <span className="input-group-text" id="inputGroup-sizing-sm">Gerente</span>

                                <Select  className="form-control"  placeholder="Seleccione Gerente"
                                  name="codigo_gerente"
                                  options={usuario} 
                                  onChange={(e) => setEmpresa((prev) => ({
                                    ...prev,
                                    ["codigo_gerente"]: e.codigo_usuario,
                                  }))}
                                  getOptionValue={option => option.codigo_usuario }
                                  getOptionLabel={option =>  option.codigo_usuario+'-'+option.nombres }
                                  /> 
                             </div>
                        </div>
                        <div className="col-lg-6  input-group-sm mb-5">
                            <div className="input-group input-group-sm mb-5">
                                <Select  className="form-control"  placeholder="Seleccione Director"
                                  name="codigo_director"
                                  options={usuario} 
                                  onChange={(e) => setEmpresa((prev) => ({
                                    ...prev,
                                    ["codigo_director"]: e.codigo_usuario,
                                  }))}
                                  getOptionValue={option => option.codigo_usuario }
                                  getOptionLabel={option =>  option.codigo_usuario+'-'+option.nombres }
                                  /> 
                             </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
