import React, { Component, useEffect, useState } from "react";
import { useAuth } from "../app/modules/auth";
import { Link, useNavigate } from "react-router-dom";
import PlanDataService from "../_services/planestudio";
import UsuarioDataService from "../_services/usuario";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from "chart.js";
import { Bar } from "react-chartjs-2";
import Rating from '@mui/material/Rating';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const optionsa = {
    responsive: true,
    type: "bar",
    plugins: {
        legend: {
            position: "top"
        },
        title: {
            display: true,
            text: "Horas de Acceso"
        }
    }
};
const optionsb = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right',
        },
        title: {
            display: true,
            text: 'Top 5 -Avance Ultimo Plan Asignado',
        },
    },
};
const labels = ["00-08:00", "08-11:00", "11-14;00", "14-19:00", "19-23:00"];
const labelsb = ["UP GRADE", "TEXTCORP SAC", "SINERCORP", "SAMITEXT", "EL"];

const { SearchBar } = Search;
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";


export default function DashboardUsuarioAdminPanel() {
    const navigate = useNavigate();

    var bgColors = {
        "Default": "#81b71a",
        "Blue": "#00B1E1",
        "Cyan": "#37BC9B",
        "Green": "#8CC152",
        "Red": "#E9573F",
        "Yellow": "#F6BB42",
    };
    const columns = [
        {
            dataField: 'id_tema', text: 'Accion', formatter: (rowContent, row) => {
                return (
                    <>
                        <div className="btn-toolbar justify-content-between" role="toolbar"
                            aria-label="Toolbar with button groups">
                            <div className="btn-group" role="group" aria-label="First group">

                                {row["nota"] > 70 ? <>
                                    <Link to={"/certificadovisor?id=" + row["id_evaluacion"]}
                                        className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon "               >
                                        <i className="fa-solid fa-file-pdf fs-2x text-danger"></i>
                                    </Link>
                                </> : <>
                                    <Link data-tooltip-content="Hello to you too!" to={
                                        {
                                            pathname: "/evaluacionedit",
                                            search: '?id=' + row["id_tema"]
                                                + '&idpe=' + row["id_planestudio"]
                                                + '&idp=' + row["id_programa"]
                                                + '&idc=' + row["id_curso"]
                                                + '&idm=' + row["id_modulo"]
                                        }
                                    }
                                        className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon "               >
                                        <i className="fa-solid fa-list text-primary fs-2x"></i>
                                    </Link>
                                </>}

                            </div>
                        </div>
                    </>
                )
            }
        },
        { dataField: 'id', text: 'Id', sort: true },
        { dataField: 'plan', text: 'Plan', sort: true },
        { dataField: 'programa', text: 'Programa', sort: true },
        { dataField: 'curso', text: 'Curso', sort: true },
        { dataField: 'modulo', text: 'Modulo', sort: true },
        { dataField: 'titulo', text: 'Tema', sort: true },
        {
            dataField: 'codigo_estado', text: 'Estado', formatter: (rowContent, row) => {
                return (
                    <>
                        {rowContent === "Activo" ?
                            <>
                                <span className="badge badge-primary">{rowContent}</span>
                            </> :
                            <>
                                {rowContent === "Inactivo" ?
                                    <>
                                        <span className="badge badge-danger">{rowContent}</span>

                                    </> :
                                    <>
                                        <span className="badge badge-warning">{rowContent}</span>

                                    </>}
                            </>}
                    </>
                )
            }
        },
        { dataField: 'fecha_creacion', text: 'Publicado', sort: true },
        { dataField: 'fecha_fin', text: 'Vence', sort: true },
        {
            dataField: 'nota', text: 'Nota', formatter: (rowContent, row) => {
                return (
                    <>
                        {rowContent > 70 ?
                            <>
                                <span className="badge badge-primary">{rowContent}</span>
                            </> :
                            <>
                                <span className="badge badge-danger">{rowContent}</span>
                            </>}
                    </>
                )
            }
        },
    ];
    const { currentUser, logout } = useAuth()
    const [seguimiento, setSeguimiento] = useState([]);
    const [kpi, setKPI] = useState([]);
    const [isOnline, setIsOnline] = useState(null);
    const [empresa, setEmpresa] = useState([]);
    const [tema, setTema] = useState([]);
    const [hora, setHora] = useState([]);
    const [plan, setPlan] = useState([]);

    const [plan1, setPlan1] = useState([]);
    const [plan2, setPlan2] = useState([]);
    const [plan3, setPlan3] = useState([]);
     
    const arrempresas = empresa.map((emp) => emp.empresa);
    const arravance = empresa.map((emp) => emp.avance);
    const arrhora = hora.map((hh) => hh.avance);
    
    const data = {
        labels,
        datasets: [
            {
                label: "Datos:",
                data: arrhora,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 205, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(54, 162, 235, 0.8)'
                ],
            }
        ]
    };
    const dataempresa = {
        labels: arrempresas,
        datasets: [
            {
                label: "Datos:",
                data: arravance,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 205, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(54, 162, 235, 0.8)'
                ],
            }
        ]
    };
    
    useEffect(() => {
        //console.log(currentUser?.dias_vencimiento)
        console.log('dashboardadmin')
        
        if (currentUser?.indicador_soporte) {
            navigate('/planestudio')
        }
        if (!currentUser?.indicador_admin) {
            navigate('/dashboardusuario')
        }
        
        
        PlanDataService.getplandashboard(1)
            .then(result => {
                setPlan1(result.data);
            })
            .catch(e => {
                console.log(e);
            });
        PlanDataService.getplandashboard(2)
            .then(result => {
                setPlan2(result.data);
            })
            .catch(e => {
                console.log(e);
            });
        PlanDataService.getplanestudios()
            .then(response => {
                //console.log(response.data);
                const ultimoplan = response.data[response.data.length - 1]
                setPlan(ultimoplan)
                //const ultimoplan=response.data[response.data.length - 1]
                //console.log(plan)
                PlanDataService.getplandashboard(ultimoplan.id_planestudio)
                    .then(result => {
                        setPlan3(result.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                UsuarioDataService.getUsuariosKpiEmpresa(ultimoplan.id_planestudio)
                    .then(result => {
                        setEmpresa(result.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch(e => {
                console.log(e);
            });
        
        
        UsuarioDataService.getUsuariosKpiTema()
            .then(result => {
                setTema(result.data);
            })
            .catch(e => {
                console.log(e);
            });
        UsuarioDataService.getUsuariosKpiHora()
            .then(result => {
                setHora(result.data);
            })
            .catch(e => {
                console.log(e);
            });
        fetch(API_BASE_URL + 'usuario//sellst/kpi/admin/', {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
        })
            .then(response => response.json())
            .then(result => {
                setKPI(result);
                setIsOnline(true);
            })
            .catch(e => {
                console.log(e);
            });
    }, [isOnline]);
    if (isOnline === null) {
        //console.log(isOnline);
        return <>'Cargando...'</>;
    } else {
        return (

            <>
                <div className='row'>
                    <div className='col-lg-3 '>
                        <a className="card bg-danger hoverable card-xl-stretch mb-xl-2">
                            <div className="card-body">
                                <i className="fa fa-book text-white fs-2x  ">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">{kpi[0].tema_activo}
                                </div>
                                <div className="fw-semibold text-white">Temas Activos

                                </div>
                                <Link to={"/tema"}
                                    className="btn btn-sm  btn-light-dark">

                                    Ver Temas
                                </Link>
                            </div>
                        </a>

                    </div>
                    <div className='col-lg-3 '>
                        <a className="card bg-warning hoverable card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-users text-white fs-2x">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">{kpi[0].usuario_activo}
                                </div>
                                <div className="fw-semibold text-white">Usuarios Activos
                                </div>
                                <Link to={"/usuario"}
                                    className="btn btn-sm btn-light-dark">

                                    Ver Usuarios
                                </Link>
                            </div>
                        </a>

                    </div>
                    <div className='col-lg-3 '>
                        <a className="card bg-success hoverable card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-calendar text-white fs-2x">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">{kpi[0].tema_vencido}
                                </div>
                                <div className="fw-semibold text-white">Usuarios sin Asignar
                                </div>
                                <Link to={"/usuariosinasignar"}
                                    className="btn btn-sm btn-light-dark">

                                    Ver Usuarios
                                </Link>
                            </div>
                        </a>

                    </div>
                    <div className='col-lg-3 '>
                        <a className="card bg-primary hoverable card-xl-stretch mb-xl-8">
                            <div className="card-body">
                                <i className="fa fa-bookmark text-white fs-2x">
                                    <span className="path1">
                                    </span><span className="path2"></span>
                                    <span className="path3">
                                    </span>
                                    <span className="path4"></span></i>
                                <div className="text-white fw-bold fs-3">4
                                </div>
                                <div className="fw-semibold text-white"> Planes Activos
                                </div>
                                <Link to={"/planestudio"}
                                    className="btn btn-sm btn-light-dark">
                                    Ver Planes
                                 </Link>
                            </div>
                        </a>

                    </div>

                </div>
                <div className="separator my-1"></div>
                <div className='row'>
                    <div className='col-lg-6 '>
                        <div className="card card-custom card-stretch shadow mb-5"  >
                            <div className="card-header">
                                <h3 className="card-title">Induccion Corporativa</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-bordered"  >
                                    <thead>
                                        <tr className="fw-bold fs-6 border bg-primary text-white">
                                            <th>Empresa</th>
                                            <th>Usuarios Asignados</th>
                                            
                                            <th>Avance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {plan1.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{item.empresa}</td>
                                                    <td>{item.usuarios}</td>
                                                    <td>
                                                        {item.avance==='0'?
                                                        <><span className="badge badge-danger" >{item.avance}%</span></>:
                                                        <>
                                                            {item.avance==='100'?
                                                            <> 
                                                                <span className="badge badge-success" >{item.avance}%</span>
                                                            </>:
                                                            <> 
                                                                <span className="badge badge-warning text-info">{item.avance}%</span>
                                                            </>
                                                            }
                                                            
                                                        </>}
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                         <tr>
                                            <td className="fw-bold fs-6 border bg-secondary text-dark">
                                                Total Usuarios:
                                            </td>
                                            <td className="fw-bold">
                                                {plan1.reduce((acc, pilot) => acc + Number(pilot.usuarios), 0)}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 '>
                        <div className="card card-custom card-sm shadow mb-5"  >
                            <div className="card-header">
                                <h3 className="card-title">Induccion Retail</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-bordered"  >
                                    <thead>
                                        <tr className="fw-bold fs-6 border bg-primary text-white">
                                            <th>Empresa</th>
                                            <th>Usuarios Asignados</th>
                                             <th>Avance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {plan2.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{item.empresa}</td>
                                                    <td>{item.usuarios}</td>
                                                    <td>
                                                        {item.avance==='0'?
                                                        <><span className="badge badge-danger" >{item.avance}%</span></>:
                                                        <>
                                                            {item.avance==='100'?
                                                            <> 
                                                                <span className="badge badge-success" >{item.avance}%</span>
                                                            </>:
                                                            <> 
                                                                <span className="badge badge-warning text-info">{item.avance}%</span>
                                                            </>
                                                            }
                                                            
                                                        </>}
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                         <tr>
                                            <td className="fw-bold fs-6 border bg-secondary text-dark">
                                                Total Usuarios:
                                            </td>
                                            <td className="fw-bold">
                                                {plan2.reduce((acc, pilot) => acc + Number(pilot.usuarios), 0)}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 '>
                        <div className="card card-custom card-sm shadow mb-5"  >
                            <div className="card-header">
                                 
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold fs-3 mb-1">{plan.denominacion}</span>
                                    <span className="text-muted fw-semibold fs-7">Ultimo Plan Asignado</span>
                                </h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-bordered"  >
                                    <thead>
                                        <tr className="fw-bold fs-6 border bg-primary text-white">
                                            <th>Empresa</th>
                                            <th>Usuarios Asignados</th>
                                             <th>Avance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {plan3.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>{item.empresa}</td>
                                                    <td>{item.usuarios}</td>
                                                    <td>
                                                        {item.avance==='0'?
                                                        <><span className="badge badge-danger" >{item.avance}%</span></>:
                                                        <>
                                                            {item.avance==='100'?
                                                            <> 
                                                                <span className="badge badge-success" >{item.avance}%</span>
                                                            </>:
                                                            <> 
                                                                <span className="badge badge-warning text-info">{item.avance}%</span>
                                                            </>
                                                            }
                                                            
                                                        </>}
                                                    </td>

                                                </tr>
                                            </>
                                        ))}
                                        <tr>
                                            <td className="fw-bold fs-6 border bg-secondary text-dark">
                                                Total Usuarios:
                                            </td>
                                            <td className="fw-bold">
                                                {plan3.reduce((acc, pilot) => acc + Number(pilot.usuarios), 0)}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 '>
                        <div className="card card-custom card-sm "  >
                            <div className="card-header">
                                <h3 className="card-title">Top Ten Rating de Temas(Ultimos 6 meses)</h3>
                            </div>
                            <div className="card-body">
                                <table className="table table-responsive table-bordered"  >
                                    <thead>
                                        <tr className="fw-bold fs-6 border bg-primary text-white">
                                            <th>Tema</th>
                                            <th>Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tema.map((item, index) => (
                                            <>
                                                <tr>
                                                    
                                                    <td>{item.tema}</td>
                                                    <td>
                                                        <Rating precision={1} disabled={true}
                                                            value={item.rating}
                                                        /></td>


                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="separator my-1"></div>
                <div className='row'>
                    <div className='col-lg-6 '>
                        <div className="card "  >

                            <div className="card-body">
                                <Bar options={optionsb} data={dataempresa} />;
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 '>
                        <div className="card "  >

                            <div className="card-body">
                                <Bar options={optionsa} data={data} />;
                            </div>
                        </div>
                    </div>
                   
                </div>
            </>
        )
    }
}