import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";

import EmpresaDataService from "../_services/empresa";
import ParametroDataService from "../_services/parametro";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReporteDataService from "../_services/reporte";
import LoadingSpinner from "../_components/LoadingSpinner";

import UsuarioDataService from "../_services/usuario";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte/";

const columns = [
    { dataField: 'planestudio', text: 'Plan', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'empresa', text: 'Empresa', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'fecha_inicio', text: 'Fecha Inicio Prog', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'fecha_fin', text: 'Fecha Fin Prog', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'asignados', text: 'Usuarios Asignados', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'diasprogramado', text: 'Dias Programado', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'fechainiejecutado', text: 'Inicio Ejecutado', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'fechafinejecutado', text: 'Fin Ejecutado', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    { dataField: 'diasejecutado', text: 'Dias Ejecutado', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
    {
        dataField: 'diferencia', text: 'Diferencia Dias', sort: true,
        headerStyle: { color: '#fff', backgroundColor: '#009ef7' }, formatter: (rowContent, row) => {
            let prog = parseInt(row["diasprogramado"])
            let ejec = parseInt(row["diasejecutado"])
            //let pdetractor =Math.round(parseInt(rowContent) *100/total,2)
            return (
                <>
                    {ejec > prog ?
                        <>
                            <span className="badge badge-danger">{rowContent}</span>
                        </>
                        :
                        <>
                            <span className="badge ">{rowContent}</span>
                        </>}


                </>
            )
        }
    },


];
const arrempresa = []
const ddlgrupo = [];
const ddlempresa = [];
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
function ReporteNPS() {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [empresa, setEmpresa] = useState([]);
    const [plan, setPlan] = useState([]);
    const [empresasel, selEmpresa] = useState([]);
    const [plansel, selPlan] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const exportToCSV = (csvData, fileName) => {
        const ddlreporte = [];
        for (var i = 0, l = csvData.length; i < l; i++) {
            ddlreporte.push({
                "planestudio": csvData[i].planestudio,
                "empresa": csvData[i].empresa,
                "fecha_inicio": csvData[i].fecha_inicio,
                "fecha_fin": csvData[i].fecha_fin,
                "asignados": csvData[i].asignados,
                "diasprogramado": csvData[i].diasprogramado,
                "fechainiejecutado": csvData[i].fechainiejecutado,
                "fechafinejecutado": csvData[i].fechafinejecutado,
                "diasejecutado": csvData[i].diasejecutado,
                "diferencia": csvData[i].diferencia
            })
        }
        const ws = XLSX.utils.json_to_sheet(ddlreporte);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }
     
    const handleChangeSelect = (e) => {
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        selEmpresa(arrtemp.toString())
        console.log(empresasel)
    }
     
    const handleClick = async () => {
        try {
            console.log(empresasel)
            console.log(fechadesde)
            console.log(fechahasta)
            if (empresasel.length>0){
               
                setIsLoading(true)
                //const response = await axios.get('https://apiucj.azurewebsites.net/api/programa/sellst/usuario/graph/1661');
                const response = await fetch(API_BASE_URL+'linea_tiempo/'+empresasel+'/'+fechadesde+'/'+fechahasta );
                const result = await response.json();
                setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
                console.log(result)
                setIsLoading(false)
            }
           
            
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
    useEffect(() => {
        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
          }
          ReporteDataService.obtener_parametros(parempresa)
            .then(result => {
              setEmpresa(result.data);
              //setIsLoading(false)
            })
            const date = new Date();
        const futureDate = date.getDate() + 7;
        const defaultfecha = date.toLocaleDateString('en-CA');
        date.setDate(futureDate);
        const defaultfecha2 = date.toLocaleDateString('en-CA');
        setFechaDesde(defaultfecha)
        setFechaHasta(defaultfecha2)
    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <>
            <div className="row">


                <div className="col-lg-12">
                    <div className="card card-custom">
                        <div className="card-header pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Reporte Linea de Tiempo</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                            </h3>
                            <div className="card-toolbar">
                                <button className='btn btn-primary btn-sm' onClick={handleClick}>Generar Reporte</button>


                                <button variant="warning" className="btn btn-success btn-sm"
                                    onClick={(e) => exportToCSV(datos, 'UCJ-Reporte Linea de Tiempo')}>
                                    <i className="fa-solid fa-file-excel "></i>Exportar</button>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-lg-4   input-group-sm mb-5">
                                    <span className="label text-info">Seleccione: </span>
                                    <Select
                                        placeholder="Empresas"
                                        name="id"
                                        isMulti
                                        onChange={(e) => handleChangeSelect(e)}
                                        options={empresa}
                                        // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.codigo}
                                    />
                                </div>
                                <div className="col-lg-3   input-group-sm mb-5">
                                        
                                        <span className="label text-info">Fecha Ejecucion Desde: </span>
                                        <input type="date" className="form-control"
                                        id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                                        defaultValue={fechadesde} />
                                    </div>
                                    <div className="col-lg-3   input-group-sm mb-5">
                                    <span className="label text-info">Hasta: </span>
                                        <input type="date" className="form-control" 
                                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                                        defaultValue={fechahasta} />
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="container">

                            <ToolkitProvider
                                keyField="id"
                                data={datos}
                                columns={columns}
                                search           >
                                {
                                    props => (
                                        <div>
                                            <SearchBar {...props.searchProps} placeholder="Buscar..." />
                                            <hr />
                                            <BootstrapTable className="table table-bordered table-responsive"
                                                {...props.baseProps}
                                            />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>




                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default ReporteNPS;