import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import UsuarioDataService from "../_services/usuario";
import PlanDataService from "../_services/planestudio";
import AsignacioDataService from "../_services/asignacion";

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth } from "../app/modules/auth";


const options = {
    sizePerPageList: [{
      
      text: '100', value: 100
    } , {
        text: '250', value: 250
      }, {
        text: '500', value: 500
      }, {
        text: '1000', value: 1000
      }] // A numeric array is also available. the purpose of above example is custom the text
  };

const columns = [
    { dataField: 'empresa', text: 'Empresa', sort: true, filter: textFilter() },
    { dataField: 'codigo_usuario', text: 'Código', sort: true, filter: textFilter() },
    { dataField: 'nombres', text: 'Personal', formatter: (rowContent, row) => {
            return (
                <>
                    <span>{row["nombres"]} {row["apellido_paterno"]} {row["apellido_materno"]}</span>
                </>
            )
        }, filter: textFilter()
    },
    { dataField: 'correo_electronico', text: 'Email', sort: true, filter: textFilter() },
    { dataField: 'centro_costo', text: 'Centro Costo', sort: true, filter: textFilter() },
    { dataField: 'codigo_grupo', text: 'Grupo', sort: true, filter: textFilter() },
    { dataField: 'cargo', text: 'Cargo', sort: true, filter: textFilter()},
    { dataField: 'fecha_ingreso', text: 'Fecha Ingreso', sort: true},
];
 

export default function AsignacionPlanForm() {

    const { currentUser, logout } = useAuth()
    const [usuarios, setUsuarios] = useState([]);
    const [isOnline, setIsOnline] = useState(null);
    const [planes, setPlan] = useState([]);
    const [asignacion, setAsignacion] = useState([]);
    const navigate = useNavigate();
    const [seleccionados, setSelected] = useState([]);
    const [flag, setFlag] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setFlag(false);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const alertselected = () => {
        console.log(selectedRow)
        console.log(seleccionados)
    }
    const handleCheckedRow = (event, id) => {
         
        setFlag(true);
        asignacion.push({
            "id_planestudio": id
        })

    };
    const handleSubmit = async (e ) => {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Asignar los Usuarios?");
        if (answer) {
             handleClose();
            let arrasigna=[]
            if (flag) {
                console.log(seleccionados)
                console.log(asignacion)
                for (var i = 0, l = seleccionados.length; i < l; i++) {
                    for (var y = 0, l = asignacion.length; y < l; i++) {
                        arrasigna.push({
                            "id_planestudio":asignacion[y],
                            "id_usuario":seleccionados[i],
                            "codigo_estado":1,
                            "usuario_creacion": currentUser?.codigo_usuario
                        })
                    }
                }
            }
            console.log(arrasigna)
            //AsignacioDataService.createasignacion(arrasigna)
    
           
        }
    }
    function asignarusuarios   (e) {
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Asignar los Usuarios?");
        if (answer) {
             handleClose();
            let arrasigna=[]
            if (flag) {
                console.log(seleccionados)
                console.log(asignacion)
                for (var i = 0, ii = seleccionados.length; i < ii; i++) {
                    for (var y = 0, yy = asignacion.length; y < yy; y++) {
                        arrasigna.push({
                            "id_planestudio":asignacion[y].id_planestudio,
                            "id_usuario":seleccionados[i].id_usuario,
                            "codigo_estado":1,
                            "usuario_creacion": currentUser?.username
                        })
                    }
                }
            }
            console.log(arrasigna)
            AsignacioDataService.createasignacion(arrasigna)
                .then(function (response) {
                    console.log(response);
                    alert("Asignado correctamente");
                    navigate('/asignacion');
                })
                .catch(e => {
                    console.log(e);
                });
           
        }
        
    };
    const selectedRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            //console.log('=== selectRow row ===', row);
            if (isSelect) {
                seleccionados.push({
                    "id_usuario": row["id_usuario"]
                })
            } else {
                const idindex = seleccionados.findIndex(element => element.id_usuario === row["id_usuario"]);
                seleccionados.splice(idindex, 1)
            }


            //console.log('=== selectRow event ===', e);
        },
        onSelectAll: (isSelected, rows) => {
            //console.log('=== selectRow row ===', rows[0]);
            //console.log(isSelected)
            //console.log(rows)
            if (isSelected) {
                for (let i = 0; i < rows.length; i++) {
                    //console.log('=== selectRow row ===', rows[i]);
                    seleccionados.push({
                        "id_usuario": rows[i]["id_usuario"]
                    })
                }
            } else {
                //let filteredArray = seleccionados.filter(item => item.id_usuario !== idprograma)
                // let arrfiltro=[]
                for (let i = 0; i < rows.length; i++) {
                    // arrfiltro.push({
                    //    "id_usuario":rows[i]["id_usuario"]
                    //})
                    const idindex = seleccionados.findIndex(element => element.id_usuario === rows[i]["id_usuario"]);
                    seleccionados.splice(idindex, 1)
                }

                //console.log(arrfiltro)

                //console.log(filtrarusuarioasignado(seleccionados,arrfiltro))
            }
            console.log(seleccionados)
        }
    };

    function filtrarusuarioasignado(usuarios, filtros) {
        return usuarios.filter((item) => !filtros.includes(item.id_usuario));
    }
    useEffect(() => {
        UsuarioDataService.getUsuariosAsignar()
            .then(response => {
                setUsuarios(response.data);
                //console.log(response.data)
                setIsOnline(true)
            })
            .catch(e => {
                console.log(e);
            });
        PlanDataService.getplanestudios()
            .then(response => {
                setPlan(response.data);
                //console.log(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    }, [isOnline]);
    if (isOnline === null) {
        return <>'Cargando...'</>;
    }
    
    return (
        <>
            <div className="card card-custom">
                <form onSubmit={handleSubmit}>
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <h2>Enlazar Plan de Estudio y Usuarios</h2>
                        </div>
                    </div>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                        <div className="w-100 mw-150px">
                        </div>
                        <Link to={"/asignacion"}
                            className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        
                        <Button className="btn btn-primary btn-sm"
                            onClick={handleClickOpen}> 
                            <i className="fa-solid fa-floppy-disk"></i>
                            Asignar
                        </Button>
                    </div>
                </div>
                <div className='card-body pt-2'>
                    <div className='row g-5 g-xl-8'>
                        <div className='col-xl-12'>
                            <BootstrapTable
                                className={"table table-responsive  "}
                                headerWrapperClasses="foo"
                                bordered={true}
                                keyField='id_usuario'
                                data={usuarios}
                                columns={columns}
                                filter={filterFactory()}
                                selectRow={selectedRow}
                                pagination={ paginationFactory(options) }
                            />
                          

                        </div>


                    </div>
                </div>
                </form>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Seleccione los Planes de Estudio:"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <table className="table">
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800">
                                    <th>Sel</th>
                                    <th>Plan de Estudio</th>
                                </tr>
                            </thead>
                            <tbody>

                                {planes.map((item) => (
                                    <>
                                        <tr>
                                            <td><input type="checkbox"  value={item.id_planestudio} className="form-check"
                                                name={"id_planestudio" + item.id_planestudio}
                                                onChange={(event) => handleCheckedRow(event, item.id_planestudio)}></input>
                                            </td>
                                            <td>
                                                <div>
                                                {item.denominacion}
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                ))}

                            </tbody>
                        </table>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button className="btn btn-primary btn-sm" onClick={asignarusuarios} autoFocus>
                        Asignar Usuarios a Plan de Estudio
                    </Button>
                </DialogActions>
            </Dialog>
            
        </>
    );

}
