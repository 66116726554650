
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UsuarioDataService from "../_services/usuario";
import EmpresaDataService from "../_services/empresa";
import ParametroDataService from "../_services/parametro";
import { useAuth } from "../app/modules/auth";

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function UsuarioForm() {
  const { currentUser, logout } = useAuth()
  const [usuarios, setUsuarios] = useState([]);
  const [grupos, setGrupos] = useState([]);

  const [empresa, setEmpresa] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [centro_costo, setCentroCosto] = useState([]);

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idusuario = queryParameters.get("id")
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue1 === inputValue2 ) {
      const answer = window.confirm("Esta seguro de Guardar el Registro?");
      if (answer) {
          console.log(usuarios);
          usuarios.usuario_creacion = currentUser?.username
          usuarios.contrasenia=inputValue1
          console.log(usuarios)
          UsuarioDataService.createUsuario(usuarios)
            .then(function (response) {
              console.log(JSON.stringify(response.data));
              //alert("Registro Insertado correctamente");
              navigate('/acceso');
            })
            .catch(function (error) {
              console.log(error);
            });
         
      }
    }else {
      alert("Las contraseñas son diferentes");
    }
    
  };
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "contrasenia") {
      setInputValue1(value);
    } else if (name === "contrasenia_valida") {
      setInputValue2(value);
    }

     
  };
  const handleChange = (e) => {
    console.log();
    // console.log(e.target.value);
    // setTodo({ ...todo, [e.target.name]: e.target.value });
    setUsuarios((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  useEffect(() => {
    if (idusuario !== null) {
      UsuarioDataService.getUsuarioById(idusuario)
        .then(response => response.json())
        .then(result => {
          setUsuarios(result);
          console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
    }
    EmpresaDataService.getempresas()
      .then(response => {
        setEmpresa(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    ParametroDataService.getparametrosbycod('cargo')
      .then(response => {
        setCargo(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    ParametroDataService.getparametrosbycod('centro_costo')
      .then(response => {
        setCentroCosto(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    ParametroDataService.getparametrosbycod('grupo_usuario')
      .then(response => {
        setGrupos(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);
  return (
    <div className="card card-custom">
<form onSubmit={handleSubmit}>
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <h2>Nuevo Acceso a la Plataforma</h2>
          </div>
        </div>
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 mw-150px">
          </div>
          <Link to={"/acceso"}
              className="btn btn-icon-white btn-text-white btn-danger btn-sm">
              <i className="fa-solid fa-reply "></i>
              Volver
            </Link>
            <button className='btn btn-primary btn-sm' type="submit">
              <i className="fa-solid fa-floppy-disk"></i>
              Guardar</button>
        </div>
      </div>
      <div className="card-body pt-10">
        
          <div className="form-group row">
             
            <div className="col-lg-6 input-group-sm mb-1">
              <div className="input-group input-group-sm mb-5">
                <span className="input-group-text" id="inputGroup-sizing-sm">Codigo</span>
                <input type="text" maxLength={10} name="codigo_usuario" required defaultValue={usuarios.codigo_usuario}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
           
            
            <div className="col-lg-6 input-group-sm mb-1">
              <div className="input-group input-group-sm mb-5">
                <span className="input-group-text" id="inputGroup-sizing-sm">Nombres</span>
                <input type="text" name="nombres" maxLength={250} required defaultValue={usuarios.nombres}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
           
            <div className="col-lg-6 input-group-sm mb-1">
              <div className="input-group input-group-sm mb-5">
                <span className="input-group-text" id="inputGroup-sizing-sm">Correo Elecrónico</span>
                <input type="email" name="correo_electronico"  maxLength={250}  required defaultValue={usuarios.correo_electronico}
                  className="form-control" onChange={handleChange} />
              </div>
            </div>
            <div className="input-group input-group-sm mb-5">
                <span className="input-group-text" id="inputGroup-sizing-sm">  Contraseña</span>
                <input type="password" name="contrasenia" required  maxLength={20}
                  className="form-control" onChange={handleInputChange} />
              </div>
              <div className="input-group input-group-sm mb-5">
                <span className="input-group-text" id="inputGroup-sizing-sm">Repetir Contraseña</span>
                <input type="password" name="contrasenia_valida" required maxLength={20}
                  className="form-control" onChange={handleInputChange} />
              </div>
             
            <div className="col-lg-6 input-group-sm mb-1">
              <div className="input-group input-group-sm mb-5">
                <span className="input-group-text" id="inputGroup-sizing-sm">Rol</span>
                <select className="form-control"  
                   name="rol" id="rol" onChange={handleChange}>
                  <option value="0">[Seleccione]</option>
                  <option value="2">Soporte</option>
                  <option value="1">Administrador</option>
                </select>
              </div>
            </div>
          </div>
          <div className="mb-10">
            
          </div>

       
      </div>
      </form>
    </div>
  );
}
