 import React, { useState, useEffect } from 'react';
 import axios from 'axios';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

  const products = [
    { id: 'Shark', name: 'Shark',price:4 },
  ];
const { SearchBar } = Search;
 
const columns = [{
  dataField: 'id_programa',
  text: 'Product ID'
}, {
  dataField: 'codigo',
  text: 'Product Name'
}, {
  dataField: 'titulo',
  text: 'Product Price'
}];
export default function ProgramaTable() {
    const [data, setData] = useState([]);

    const getData = async () => {
        const result = await axios.get('https://apiucj.azurewebsites.net/api/programa/sellst');
        setData(result.data);
      };
    useEffect(() => {
        getData();
        console.log(data);
      }, []);
      
    return (
    <div>
    <ToolkitProvider
      keyField="id"
      data={ data }
      columns={ columns }
      search={ { defaultSearch: '2101' } }
    >
      {
        props => (
          <div>
            <h3>Input something at below input field:</h3>
            <SearchBar { ...props.searchProps } />
            <hr />
            <BootstrapTable
              { ...props.baseProps }
            />
          </div>
        )
      }
    </ToolkitProvider>
    
  </div>  
    )    
}