import React, { Component, useEffect, useState } from "react";
import { Document, Page, Text, View, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import EvaluacionDataService from "../_services/evaluacion";
import logdataservice from "../_services/log";
import { useAuth } from "../app/modules/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
    orientation: "landscape",
  },
  view: {
    width: "100%",
    height: "100%",

  },
  title: {
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 40,
    top: "150px", color: "#000"
  },
  subtitulo: {
    textAlign: "center",  
    textTransform: "uppercase",
    fontSize: 25,
    fontWeight: 800
  },
  image: {
    position: 'absolute',

    width: '100%',
    height: '100%',
  }
});

export default function PaginaPdf() {
  const { currentUser, logout } = useAuth()
  const analytics = getAnalytics();
  logEvent(analytics, 'select_content', {
      item_name: 'certificado',
      item_id: currentUser?.id,
      items: [{ name: currentUser?.username }]
  });
  const queryParameters = new URLSearchParams(window.location.search)
  const idevaluacion = queryParameters.get("id")
  const [evaluacion, setEvaluacion] = useState([]);
  const MyDoc = () => (

    <Document>
      <Page orientation='landscape' style={styles.page} size="A4">
        <View size="500" style={styles.view}>

          <Image style={styles.image} src="/media/fondo_certificado.png" />
          <Text style={styles.title}>CERTIFICADO</Text>
          <Text style={{ top: "200px", textAlign: "center", fontSize: 22, ...styles.text }}>
            para acreditar que :
          </Text>
          <Text style={{ top: "210px", textAlign: "center", fontSize: 22, fontWeight: "bold", ...styles.subtitulo }} >
            {evaluacion.nombres} {evaluacion.apellido}
          </Text>
          <Text style={{ top: "220px", textAlign: "center", ...styles.text }} >
            Completó y aprobó el tema de
          </Text>
          <Text style={{ top: "230px", textAlign: "center", fontSize: 22, ...styles.subtitulo }} >
            {evaluacion.titulo}
          </Text>
          <Text style={{ top: "280px", left: "500px", fontSize: 20, ...styles.text }} >
            Lima, {evaluacion.fecha}
          </Text>
        </View>

      </Page>
    </Document>
  );
  useEffect(() => {
    logdataservice.registralog("certificado","",idevaluacion,currentUser.id,currentUser.username);

    EvaluacionDataService.getevaluacionById(idevaluacion)
      .then(result => {
        setEvaluacion(result.data)
        console.log(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>

      <div className="card card-custom card-flush">

        <div className="card-body">
          <form >
            <div className="alert alert-dismissible bg-light-warning d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">


              <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>

              <div className="text-center">
                <h5 className="fw-bolder fs-1 mb-5">Certificado</h5>

                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                <div className="mb-9">
                  <h3 className="anchor fw-bolder mb-2" id="icon-colors"><a href="#icon-colors"></a>
                    Generación de certificado de tema:
                  </h3>
                  <h1>{evaluacion.titulo }</h1>
                </div>

                <div className="mb-10">
                  <div className="   ">

                  </div>
                  <div className="rating text-center">

                  </div>

                </div>
                <div className="d-flex flex-center flex-wrap">
                  <PDFDownloadLink className="btn btn-danger" document={<MyDoc />} 
                  fileName={"certificado-"+evaluacion.codigo+"-"+evaluacion.titulo+".pdf"}>
                    {({ blob, url, loading, error }) =>
                      loading ? 'Cargando...' : 'Descargar Certificado!'
                    }
                  </PDFDownloadLink>

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>


    </>
  )
}
