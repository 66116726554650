
import React, { Component ,useEffect,useState} from "react";
import PreguntaDataService from "../_services/pregunta";
import { Link ,useNavigate} from "react-router-dom";
import axios from 'axios';

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

function    PreguntaTemaLista  () {
   
  const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    var idtema = queryParameters.get("id")
    const [tema, setTema] = useState([]);
    const [preguntas, setPreguntas] = useState([]);
    
    useEffect(() => {
      //Carga de Preguntas
        fetch(API_BASE_URL+'pregunta/sellst/'+ idtema, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'}
        })
      .then(response => response.json())
      .then(result => {setPreguntas(result);console.log(result);})
      .catch(e => {console.log(e);});
      //Carga de Tema
      fetch(API_BASE_URL+'tema/sel/'+ idtema, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
          })
        .then(response => response.json())
        .then(result => {setTema(result);console.log(result);})
        .catch(e => {console.log(e);});
     }, []);
     function handleEliminarFila  (idpregunta)  {
      const answer = window.confirm("Esta seguro de Eliminar la Pregunta del Tema?");
      if (answer) {
        console.log(preguntas[getIndex(idpregunta)])
        preguntas.slice(getIndex(idpregunta),1)
        delete preguntas[getIndex(idpregunta)]
        setPreguntas(preguntas)
      }
      
     }
     function refreshPage() {
      window.location.reload(false);
    }
    function getIndex(idpregunta) {
      return preguntas.findIndex(obj => obj.id_pregunta === idpregunta);
    }
    return (
      <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Listado de Preguntas por Tema</h2>
                    </div>
                </div>

                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    
                    </div>
                        
                        <Link to={"/temapregunta?id="+idtema} 
            className="btn btn-icon-white btn-text-white btn-primary">
              <i className="fa-solid fa-right-to-bracket"></i>Asignar Preguntas
            </Link>
                </div>
            </div>
            <div className="card-body pt-10"> 
        <div className="col-lg-12">
            <div className="input-group input-group mb-5">
                                <span className="input-group-text" id="inputGroup-sizing">
                                    Tema</span>
                                <input type="text" readOnly name="pregunta" defaultValue={ tema.titulo}  
                                 className="form-control "   />
                                 </div>
           <table className="table table-bordered">
            <thead><tr>
                <th>Eliminar</th>
                <th>pregunta</th>              
                <th>rpta_a</th>              
                <th>rpta_b</th>              
                <th>rpta_c</th>              
                <th>rpta_d</th>              
             </tr></thead><tbody>
            {
              preguntas.map((pregunta, index) => (
                
                <tr>
                  <td>  
                    <a onClick={ () => handleEliminarFila( pregunta.id_pregunta )}
                      className="btn btn-outline btn-icon">
                     <i className="fa-solid fa-xmark fs-1qx text-danger"></i>
                    </a>
                  </td>
                  <td>{pregunta.pregunta}</td>
                  <td>{pregunta.rpta_a}</td>
                  <td>{pregunta.rpta_b}</td>
                  <td>{pregunta.rpta_c}</td>
                  <td>{pregunta.rpta_d}</td>
                </tr>
              ))}
              </tbody>
          </table>

          
        </div>
        </div>
        </div>
    );
  }

export default PreguntaTemaLista;