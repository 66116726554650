
 
import axios from 'axios';
import React, {Component} from 'react';

import { tema } from '../_models/tema';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/tema";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/tema";
class TemaService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    gettemas(tema){
        return axios.get( API_BASE_URL+ '/sellst/'+tema, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    gettemasestado(tema){
        return axios.get( API_BASE_URL+ '/sellst/estado/'+tema, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    gettemasmodulo(modulo){
        return axios.get( API_BASE_URL+ '/sellst/modulo/'+modulo, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    gettemasprograma(idprograma){
        return axios.get( API_BASE_URL+ '/sellst/programa/'+idprograma, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    gettemaspregunta(idtema){
        return axios.get( API_BASE_URL+ '/sellst/pregunta/'+idtema, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createtema(tema){
        return axios.post(API_BASE_URL+ '/ins', tema,{
            headers: {'Content-Type': 'application/json'}
        })}
    createtemapregunta(tema){
        return axios.post(API_BASE_URL+ '/pregunta', tema,{
            headers: {'Content-Type': 'application/json'}
        })}
    createtemamodulo(tema){
         return axios.post(API_BASE_URL+ '/modulo', tema,{
            headers: {'Content-Type': 'application/json'}
        })}
    gettemaById(temaId){
        return axios.get(API_BASE_URL + '/sel/' + temaId);
    }
    gettemadetalle(tema){
        return axios.post(API_BASE_URL + '/sel/detalle',  tema,{
            headers: {'Content-Type': 'application/json'}
        })}
    updatetema(tema){
        return axios.post(API_BASE_URL + '/upd/' , tema,{
            headers: {'Content-Type': 'application/json'}
        }) }

    deletetema(temaId){
        return axios.get(API_BASE_URL + '/del/' + temaId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new TemaService()