
 
import axios from 'axios';
import React, {Component} from 'react';

import { dominio } from '../_models/dominio';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/dominio";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/dominio";
class DominioService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getdominios(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        
        //.then((data) => console.log(data));
    }
    createdominio(dominio){
        return axios.post(API_BASE_URL+ '/ins', dominio,{
            headers: {'Content-Type': 'application/json'}
        })}
    getdominioById(dominioId){
        return axios.get(API_BASE_URL + '/sel/' + dominioId);
    }
    updatedominio(iddominio,dominio){
        return axios.post(API_BASE_URL + '/upd/'+iddominio , dominio,{
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            console.log(response);
          }).catch(err => console.error("Wasn't able to update user.", err));}

    deletedominio(dominioId){
        return axios.delete(API_BASE_URL + '/' + dominioId);
    }
}

export default new DominioService()