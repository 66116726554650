
import React, { Component ,useState} from "react";
import UsuarioDataService from "../_services/usuario";
import EmailService from "../_services/email";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search , CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const options = {
  sizePerPageList: [{
    
    text: '100', value: 100
  } , {
      text: '250', value: 250
    }, {
      text: '500', value: 500
    }, {
      text: '1000', value: 1000
    }] // A numeric array is also available. the purpose of above example is custom the text
};
const columns = [
{   dataField: 'id_usuario',   text: 'Editar' ,formatter: (rowContent, row) => {
  return (
    <div className="btn-toolbar" role="toolbar" aria-label="...">
    <div className="btn-group mr-2" role="group" aria-label="...">
      <Link className="btn btn-outline btn-icon btn-sm"      to={"/usuarioedit?id=" + rowContent}             >
              <i className="fa-solid fa-edit fs-1qx text-primary"></i> 
      </Link>
      <Link className="btn btn-outline btn-icon btn-sm"      to={"/usuariopassword?id=" + rowContent}             >
              <i className="fa-solid fa-key fs-1qx text-danger"></i> 
      </Link>
      {row["correo_electronico"]!=''? 
      <>
        <a  className="btn btn-outline btn-icon btn-sm"        onClick={() =>  handleEmail(row)}>
          <i className="fa fa-envelope   text-warning"> </i> 
        </a>
      </>:
      <> </>}
      

    </div></div>
  )
} }, 
{   dataField: 'codigo_estado',   text: 'Estado',csvText: 'Estado',sort:true,formatter: (rowContent, row) => {
  return (
     <>
       { rowContent === 'Activo'?
        <>
          <span className="badge badge-primary"> Activo</span>
        </>:
        <>
          <span className="badge badge-danger">Cesado</span>
        </>}
      </>
  )
} },   
      {   dataField: 'codigo_usuario',   text: 'Código',sort:true,csvText: 'Codigo' ,formatter: (rowContent, row) => {
        return (
           <>
            <Link className="breadcrumb-item pe-3"      to={"/usuarioreporte?id=" + row["id_usuario"]}             >
              
              {rowContent}
            </Link>
            </>
        )
      } },        
      {   dataField: 'personal',   text: 'Personal',sort:true },                             
      
      {   dataField: 'correo_electronico',   text: 'Email',sort:true ,formatter: (rowContent, row) => {
        return (
           <>
            {rowContent}
            </>
        )
      } },                   
      {   dataField: 'telefono',   text: 'Teléfono',csvText: 'Telefono',formatter: (rowContent, row) => {
        return (
           <>
            <a className="text-primary text-underline"  target={"_blank"} href={"https://wa.me/51"+rowContent}  >
              {rowContent}
            </a>
            </>
        )
      } },                  
      {   dataField: 'centro_costo',   text: 'CeCo',sort:true },                   
      {   dataField: 'genero',   text: 'Genero',sort:true },                   
      {   dataField: 'cargo',   text: 'Cargo',sort:true },      
      {   dataField: 'codigo_grupo',   text: 'Grupo',sort:true },                    
      {   dataField: 'fecha_ingreso',   text: 'Ingreso',sort:true },                   
      {   dataField: 'empresa',   text: 'Empresa',sort:true },                   
      {   dataField: 'tipo_documento',   text: 'TpoDoc',sort:true },                   
      {   dataField: 'codigo_jefe_inmediato',   text: 'Cod Jefe',sort:true },      
      {   dataField: 'jefe_inmediato',   text: 'Jefe',sort:true },                   
             
      {   dataField: 'fecha_cese',   text: 'Fecha Cese',sort:true },                   
      {   dataField: 'fecha_login',   text: 'Ultimo Acceso',sort:true },                   
      {   dataField: 'hora_login',   text: 'Hora Acceso',sort:true },                   

                    
];
const ddlreporte = [];
const exportToCSV = (csvData, fileName) => {
  for (var i = 0, l = csvData.length; i < l; i++) {
    ddlreporte.push({
        "codigo_estado": csvData[i].codigo_estado, 
        "codigo_usuario":  csvData[i].codigo_usuario ,
        "personal":  csvData[i].personal,
        "correo_electronico":  csvData[i].correo_electronico        ,
        "telefono":  csvData[i].telefono,
        "centro_costo":  csvData[i].centro_costo,
        "genero":  csvData[i].genero,
        "cargo":  csvData[i].cargo,
        "codigo_grupo":  csvData[i].codigo_grupo,
        "fecha_ingreso":  csvData[i].fecha_ingreso,
        "empresa":  csvData[i].empresa,
        "tipo_documento":  csvData[i].tipo_documento,
        "codigo_jefe_inmediato":  csvData[i].codigo_jefe_inmediato,
        "jefe_inmediato":  csvData[i].jefe_inmediato,
        "fecha_cese":  csvData[i].fecha_cese,
        "fecha_login":  csvData[i].fecha_login,
        "hora_login":  csvData[i].hora_login
    })
  }
  const ws = XLSX.utils.json_to_sheet(ddlreporte);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}
const handleEmail  = (row) => {
  const answer = window.confirm("Esta seguro de Enviar Notificacion de Bienvenida al Usuario?");
  if (answer) {
    console.log(row);
    const  datausuario=[]
    datausuario.push({
      "destinatario":row.correo_electronico,
      "nombre":row.nombres,
      "codigo":row.codigo_usuario
    })
    console.log(JSON.stringify(datausuario));
 
    EmailService.bienvenidausuario(JSON.stringify(datausuario))
      .then(response => {
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  
    
}
export default class UsuarioListaSinAsignar extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _usuarios: [],usuario :[]
    };
  }
  
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    UsuarioDataService.getUsuariosSinAsignar()
      .then(response => {
        this.setState({
          _usuarios: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _usuarios } = this.state;
    return (
        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_usuario"
            data={ _usuarios }
            columns={ columns }
            search     
            
            exportCSV={{  
              fileName: 'usuario.csv',
              separator: ';',
              noAutoBOM: false 
              }}           >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  
                  <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(_usuarios,'UCJ-Usuarios')}>
                      <i className="fa-solid fa-file-excel "></i>Exportar
                  </button>
                  <hr />
                  <BootstrapTable className="table table-bordered" 
                  pagination={ paginationFactory(options) }
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 