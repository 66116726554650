
import React, { Component } from "react";
import { Link } from "react-router-dom";

import ParametroLista from "../../../_components/parametro";

export function Parametro() {
    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <ParametroLista></ParametroLista>
                                
                    </div>
                </div>
            </div>
        </div>
    );
}