
 
import axios from 'axios';
import React, {Component} from 'react';

import { planestudio } from '../_models/planestudio';
const API_BASE_URL = process.env.REACT_APP_API_URL+"/planestudio";
class PlanestudioService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getplanestudios(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplandashboard(idplanestudio){
        return axios.get( API_BASE_URL+ '/sellst/dashboard/'+idplanestudio, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplandashboardultimo(){
        return axios.get( API_BASE_URL+ '/sellst/ultimo/', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanestructura(){
        return axios.get( API_BASE_URL+ '/sellst/estructura', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanseguimiento(){
        return axios.get( API_BASE_URL+ '/sellst/seguimiento', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanprogreso(){
        return axios.get( API_BASE_URL+ '/sellst/progreso', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanedt(parametros){ 
        return axios.post( API_BASE_URL+ '/sellst/edt/',parametros, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanimportar(data){ 
        return axios.post( API_BASE_URL+ '/importar/',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    planimportar(data){ 
        return axios.post( API_BASE_URL+ '/importar/ins',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanprograma(programa){
        return axios.get( API_BASE_URL+ '/sellst/programa/'+programa, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplancurso(idplan){
        return axios.get( API_BASE_URL+ '/sellst/curso/'+idplan, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplanmodulo(idplan){
        return axios.get( API_BASE_URL+ '/sellst/modulo/'+idplan, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getplantemas(idplan){
        return axios.get( API_BASE_URL+ '/sellst/tema/'+idplan, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createplanestudio(planestudio){
        return axios.post(API_BASE_URL+ '/ins', planestudio,{
            headers: {'Content-Type': 'application/json'}
        })}
    copiarplanestudio(planestudio){
        return axios.post(API_BASE_URL+ '/ins/copia', planestudio,{
            headers: {'Content-Type': 'application/json'}
    })}
    getplanestudioById(planestudioId){
        return fetch(API_BASE_URL + '/sel/' + planestudioId);
    }
    getplanestudioUsuarioById(planestudioId,usuarioId){
        return fetch(API_BASE_URL + '/sel/usuario/' + planestudioId+'/'+usuarioId);
    }
    updateplanestudio(planestudio){
        return axios.post(API_BASE_URL + '/upd/' , planestudio,{
            headers: {'Content-Type': 'application/json'}
        })}

    deleteplanestudio(planestudioId){
        return axios.get(API_BASE_URL + '/dimporta/' + planestudioId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new PlanestudioService()