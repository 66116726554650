
import React, { Component ,useEffect,useState} from "react";
import { Link ,useNavigate} from "react-router-dom";
import axios from 'axios';
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function ParametroForm()  {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idparametro = queryParameters.get("id")
  const iddominio = queryParameters.get("idd")
  const handleSubmit = async (e ) => {
            e.preventDefault();
            const answer = window.confirm("Esta seguro de Guardar el Registro?");
            if (answer) {
              console.log(parametros);
              if (idparametro  == null){
                parametros.id_dominio = iddominio
                 var config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: API_BASE_URL+'parametro/ins',
                  headers: { 
                    'Content-Type': 'application/json', 
                    'Cookie': ''
                  },
                  data : JSON.stringify(parametros)
                  };
                axios(config)
                .then(function (response) {
                  console.log(JSON.stringify(response.data));
                  alert("Registro Insertado correctamente");
                  navigate('/dominio');
                })
                .catch(function (error) {
                  console.log(error);
                });
              } else {
                parametros.id_parametro=idparametro
                 var config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: API_BASE_URL+'parametro/upd',
                  headers: { 
                    'Content-Type': 'application/json', 
                    'Cookie': ''
                  },
                   data : JSON.stringify(parametros)
                  };
                axios(config)
                .then(function (response) {
                  console.log(JSON.stringify(response.data));
                  alert("Registro Actualizado correctamente");
                  navigate('/dominio');
                })
                .catch(function (error) {
                  console.log(error);
                });
               
              }
            }
    };
    const handleChange = (e ) => {
             console.log();
            // console.log(e.target.value);
            // setTodo({ ...todo, [e.target.name]: e.target.value });
            setParametros((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
    };
    const [parametros, setParametros] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
        useEffect(() => {
          if (idparametro  !== null){
            fetch(API_BASE_URL+'parametro/sel/'+ idparametro, {
              method: 'GET',headers: {'Content-Type': 'application/json'}
            })
                .then(response => response.json())
                .then(result => {
                    setParametros(result);
                    console.log(result);
                })
                .catch(e => {
                console.log(e);
                });
          }
             
          }, []);
        return (
            <div className="card card-custom">
               <form   onSubmit={handleSubmit}>
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Editar Parametro</h2>
                    </div>
                </div>

                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    
                    </div>
                    <Link to={"/dominio"} 
                        className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                        <i className="fa-solid fa-reply "></i>
                        Volver
                        </Link>
                        <button className='btn btn-primary btn-sm' type="submit">
                        <i className="fa-solid fa-floppy-disk"></i>
                        Guardar</button>
                </div>
            </div>
            <div className="card-body pt-10">
               
                    <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Código</span>
                                <input type="text" name="codigo_parametro"   defaultValue={parametros.codigo_parametro} 
                                 className="form-control" required onChange={handleChange}  />
                        </div>
                        <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Parametro</span>
                                <input type="text" name="parametro"   defaultValue={parametros.parametro} 
                                 className="form-control" required  onChange={handleChange}  />
                        </div>
                        
                        
                        <div className="input-group input-group-sm mb-5">
                                 <input type="hidden" name="id_dominio"   defaultValue={parametros.id_dominio} 
                                 className="form-control"    />
                        </div>
                    <div className="mb-10">
                         
                       
                    </div>
                        
               
            </div>
            </form>
        </div>
        );
    }
