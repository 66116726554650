
import React, { Component ,useEffect,useState} from "react";
import { Link ,useNavigate} from "react-router-dom";
import AsignacionDataService from "../_services/asignacion";
import { useAuth } from "../app/modules/auth";
import ParametroDataService from "../_services/parametro";
import PlanDataService from "../_services/planestudio";
import Select from 'react-select';
const ddlgrupo = [];
const ddlplan = [];

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

export default function AsignacionGrupoForm()  {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idasignacion = queryParameters.get("id")
  const [asignacions, setAsignacions] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [plan, setPlan] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [idplanestudio, setIDPlan] = useState(null);

  const handleSubmit = async (e ) => {
            e.preventDefault();
            const answer = window.confirm("Esta seguro de Guardar el Registro?");
            if (answer) {
              //console.log(asignacions);
              if (idasignacion  == null){
                const arrasigna=[{
                  usuario_creacion : currentUser?.username,
                  codigo_estado : '1',
                  id_planestudio : idplanestudio,
                  codigo_grupo : grupo
                }]
                //console.log({arrasigna})
                //console.log(JSON.stringify(asignacions));
                AsignacionDataService.createasignaciongrupo(JSON.stringify(arrasigna))
                .then(function (response) {
                  //console.log(JSON.stringify(response.data));
                  alert("Registro Insertado correctamente");
                  navigate('/asignacion');
                })
                .catch(function (error) {
                  console.log(error);
                });
              }  
            }
    };
    

        useEffect(() => {
            ParametroDataService.getparametrosbycod('grupo_usuario')
          .then(result => {
            setUsuario(result.data);
            if (ddlgrupo.length<=0){
              for (var i = 0, l = result.data.length; i < l; i++) {
                ddlgrupo.push({
                  "value": result.data[i].codigo_parametro, "label":  result.data[i].parametro 
                })
              }
            
            }
          });
          PlanDataService.getplanestudios() 
          .then(result => {
            setPlan(result.data);
            if (ddlplan.length<=0){
              for (var i = 0, l = result.data.length; i < l; i++) {
                ddlplan.push({
                  "value": result.data[i].id_planestudio, "label":  result.data[i].denominacion
                })
              }
            }
           
          });
             
          }, []);
        return (
            <div className="card card-custom">
            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                <div className="card-title">
                    <div className="d-flex align-items-center position-relative my-1">
                        <h2>Asignar Plan de Estudio a Grupo</h2>
                    </div>
                </div>

                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                    <div className="w-100 mw-150px">
                    
                    </div>
                        
                        
                </div>
            </div>
            <div className="card-body pt-10">
                <form   onSubmit={handleSubmit}>
                        <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Grupos</span>
                                <Select  className="form-control"  placeholder="Seleccione Grupo"
                                  name="codigo_grupo"
                                  options={ddlgrupo} onChange={(e) => setGrupo(e.value)}
                                  /> 
                        </div>
                        <div className="input-group input-group-sm mb-5">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Plan de Estudio</span>
                                <Select className="form-control"  placeholder="Seleccione Plan de Estudio"
                                  name="id_planestudio"
                                  options={ddlplan}  onChange={(e) => setIDPlan(e.value)}
                                  /> 
                        </div>
                    <div className="mb-10">
                         
                        <Link to={"/asignacion"} 
                        className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                        <i className="fa-solid fa-reply "></i>
                        Volver
                        </Link>
                        <button className='btn btn-primary btn-sm' type="submit">
                        <i className="fa-solid fa-floppy-disk"></i>
                        Asignar</button>
                    </div>
                        
                </form>
            </div>
        </div>
        );
    }
