
import React, { Component, Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TemaDataService from "../_services/tema";
import ContenidoDataService from "../_services/contenido";
import logdataservice from "../_services/log";
import EvaluacionDataService from "../_services/evaluacion";
import DetalleDataService from "../_services/detalle_evaluacion";
import PreguntaDataService from "../_services/pregunta";
import { useAuth } from "../app/modules/auth";
import evaluacion from "../_services/evaluacion";
import LoadingSpinner from "../_components/LoadingSpinner";
import { getAnalytics, logEvent } from "firebase/analytics";
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
const marks = [
    { value: 10, label: '1', },
    { value: 20, label: '2', },
    { value: 30, label: '3', },
    { value: 40, label: '4', },
    { value: 50, label: '5', },
    { value: 60, label: '6', },
    { value: 70, label: '7', },
    { value: 80, label: '8', },
    { value: 90, label: '9', },
    { value: 100, label: '10', },
];
export default function EvaluacionForm() {
    const { currentUser, logout } = useAuth()
    const analytics = getAnalytics();
    logEvent(analytics, 'select_content', {
        item_name: 'evaluacion',
        item_id: currentUser?.id,
        items: [{ name: currentUser?.username }]
    });
    const [flag, setFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [evaluacions, setEvaluacion] = useState([]);
    const [tema, setTema] = useState([]);
    const [respuestas, setRespuestas] = useState([]);
    const [preguntas, setPregunta] = useState([]);
    const [contenido, setContenido] = useState([]);
    const [parametros, setParametro] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const idplanestudio = queryParameters.get("idpe")
    const idprograma = queryParameters.get("idp")
    const idcurso = queryParameters.get("idc")
    const idmodulo = queryParameters.get("idm")
    const idtema = queryParameters.get("id")
    const [spinner, setSpinner] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (feedback.length == 0) {
            alert('Debe al menos elegir un valor de Feedback');
        } else {
            const answer = window.confirm("¿Esta seguro de Continuar?");
            if (answer) {
                setIsLoading(true);
                const dataeval = ({
                    id_tema: idtema,
                    id_planestudio: idplanestudio,
                    id_programa: idprograma,
                    id_curso: idcurso,
                    id_modulo: idmodulo,
                    codigo_estado: 1,
                    id_usuario: currentUser?.id,
                    usuario_creacion: currentUser?.username
                })
                evaluacions.id_tema = idtema
                evaluacions.codigo_estado = 1
                evaluacions.usuario_creacion = currentUser?.username
                evaluacions.escala=feedback.escala
                //console.log(evaluacions) 
                if (respuestas.length > 0) {
                    //console.log('Con respuestas') 
                    for (var i = 0, l = respuestas.length; i < l; i++) {
                        respuestas[i].escala=feedback.escala
                        respuestas[i].id_tema = idtema
                        respuestas[i].id_planestudio = idplanestudio
                        respuestas[i].id_programa = idprograma
                        respuestas[i].id_curso = idcurso
                        respuestas[i].id_modulo = idmodulo
                        respuestas[i].codigo_estado = 1
                        respuestas[i].id_usuario = currentUser?.id
                        respuestas[i].usuario_creacion = currentUser?.username
                        
                        //respuestas[i].id_evaluacion = response.data.id_evaluacion
                    }
                    console.log(respuestas)
                    EvaluacionDataService.createevaluaciondetalle(respuestas)
                        .then(function (response) {
                            setIsLoading(false)
                            //console.log(response);
                            //console.log(response.data.id_evaluacion);
                            let ideval = response.data.id_evaluacion
                            //if (flag) {
                                navigate('/evaluacionresultado?id=' + ideval);
                            //navigate('/planfin?id='+idplanestudio);
                            //  } else {
                            //navigate('/temafeedback?id=' + ideval);
                            //navigate('/planfin?id=' + idplanestudio);
                            // }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            }
        }
    };
    const handleChange = (e) => {
        const rptaexiste = respuestas.filter((t) => t.id_pregunta === e.target.name);
        //const index = respuestas.findIndex((emp) => emp.id_pregunta === event.target.name);
        if (rptaexiste.length > 0) {
            //alert('Ya se respondio')
            const index = respuestas.map((itemf) => {
                if (itemf.id_pregunta == e.target.name) {
                    itemf.respuesta = e.target.value;
                }
            })
            //console.log('d')
        } else {
            respuestas.push({
                "id_pregunta": e.target.name,
                "respuesta": e.target.value,
                "id_evaluacion": 0
            })
        }
    };
    const handleChangeSlider = (event, newValue) => {
        //console.log(event.target);
        //console.log(newValue);
        setFeedback((prev) => ({
            ...prev,
            [event.target.name]: newValue,
        }));
    };
    useEffect(() => {
        if (idtema !== null) {
            const parametros = ({
                "id_tema": idtema,
                "id_planestudio": idplanestudio,
                "id_programa": idprograma,
                "id_curso": idcurso,
                "id_modulo": idmodulo
            })
            logdataservice.registralog("evaluacion", "inicio", JSON.stringify(parametros), currentUser.id, currentUser.username);
            //console.log(parametros)
            // fetch(API_BASE_URL + 'pregunta/sellst/tema/' + idtema, {
            //  method: 'GET', headers: { 'Content-Type': 'application/json' }
            // })
            //.then(response => response.json())
            const arrpreguntas = ({
                "id_tema": idtema,
                "id_modulo": idmodulo
            })
            //console.log(JSON.stringify(arrpreguntas))
            PreguntaDataService.getpreguntastema(JSON.stringify(arrpreguntas))
                .then(result => {
                    //console.log(result.data);
                    setPregunta(result.data);
                    //console.log(result);
                    if (result.data.length > 0) {
                        setFlag(true);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            //console.log(JSON.stringify(parametros))
            TemaDataService.gettemadetalle(JSON.stringify(parametros))
                .then(result => {
                    setTema(result.data);
                    //console.log(result.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <div className="card mb-2 py-1 ">
            <div className="card-header align-items-center  ">
                <div className="card-title d-flex py-5 pt-0">
                    <h3 className="card-label d-flex">
                        <i className="las la-chalkboard-teacher text-danger fs-2x"></i>
                        <div className="bullet bg-secondary h-35px w-1px mx-5"></div>
                        <span className="card-label fw-bold text-danger fs-2x">{tema.titulo}</span>
                    </h3>
                </div>
            </div>
            <div className="card-body pt-0 "><div className="pt-0">
                <span className="  me-1">
                    <i className="bi bi-file-check-fill fs-2x text-active-danger" ></i>
                </span>{tema.plan}<br />
                <div className="d-flex align-items-center  ">
                    <span className="   me-1">
                        <i className="bi bi-file-check-fill fs-2x text-active-danger" ></i>
                    </span>{tema.programa}
                </div>
                <div className="d-flex align-items-center  ">
                    <span className="  me-1">
                        <i className="bi bi-file-check-fill fs-2x text-active-danger" ></i>
                    </span>{tema.curso}
                </div>
                <div className="d-flex align-items-center  ">
                    <span className="  me-1">
                        <i className="bi bi-file-check-fill fs-2x text-active-danger" ></i>
                    </span>{tema.modulo}
                </div>
            </div> </div>
            <div className="    ">
                <form onSubmit={handleSubmit}>
                    <div className='   '>
                        <iframe className=' ' width={'100%'} controls height={350}
                            title='Contenido'
                            src={tema.contenido} >
                        </iframe>
                        <div className="card card-custom" >
                            <div className="card-body  pt-0  ">
                                {tema.contenido_complementario ?
                                    <>
                                        <div className="ms-auto fw-bolder text-gray-700 text-end">
                                            <a className="btn btn-danger btn-lg" target="_blank"
                                                href={tema.contenido_complementario}>Descargar Material</a>
                                        </div>
                                    </> :
                                    <>
                                    </>}
                            </div>
                        </div>
                        <div className="mb-10">
                            {preguntas.map((preg, index) => (
                                <div className="form-group">
                                    <label className="text-danger">{index + 1} .{preg.pregunta}</label>
                                    <div className="d-flex ">
                                        <div className=" p-1">
                                            <input className="form-check-input border border-primary    " type="radio" value={"1"}
                                                name={preg.id_pregunta} onChange={handleChange} required />
                                        </div>
                                        <div className="d-flex flex-column flex-row-fluid">
                                            <div className="d-flex align-items-center  ">
                                                <span className="  text-hover-primary  ">
                                                    {preg.rpta_a}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="d-flex  ">
                                        <div className=" p-1">
                                            <input className="form-check-input border border-primary    "
                                                type="radio" value={"2"}
                                                name={preg.id_pregunta} onChange={handleChange} required />
                                        </div>
                                        <div className="d-flex flex-column flex-row-fluid">
                                            <div className="d-flex align-items-center  ">
                                                <span className="  text-hover-primary  ">
                                                    {preg.rpta_b}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="d-flex  ">
                                        <div className=" p-1">
                                            <input className="form-check-input border border-primary    "
                                                type="radio" value={"3"}
                                                name={preg.id_pregunta} onChange={handleChange} required />
                                        </div>
                                        <div className="d-flex flex-column flex-row-fluid">
                                            <div className="d-flex align-items-center  ">
                                                <span className="  text-hover-primary  ">
                                                    {preg.rpta_c}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="d-flex ">
                                        <div className=" p-1">
                                            <input className="form-check-input border border-primary    "
                                                type="radio" value={"4"}
                                                name={preg.id_pregunta} onChange={handleChange} required />
                                        </div>
                                        <div className="d-flex flex-column flex-row-fluid">
                                            <div className="d-flex align-items-center  ">
                                                <span className="  text-hover-primary  ">
                                                    {preg.rpta_d}
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div className="card" >
                            <div className="card-body  ">
                                <div className=" bg-light-primary d-flex flex-center flex-column pt-0">
                                    <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>
                                    <div className="text-center">
                                        <h5 className="fw-bolder fs-1 mb-5">Feedback</h5>
                                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                        <div className="mb-9">
                                            <h3 className="anchor fw-bolder mb-2" id="icon-colors"><a href="#icon-colors"></a>
                                                En base  a tu experiencia,¿Recomendarías el tema a tus compañeros?:
                                            </h3>

                                        </div>
                                        <div className="mb-10">
                                            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                                <span className="fs-3x">😞</span>
                                                <Slider
                                                    defaultValue={10}
                                                    onChange={handleChangeSlider}
                                                    name="escala"
                                                    step={10}
                                                    valueLabelDisplay="auto"
                                                    marks={marks}
                                                />
                                                <span className="fs-3x">😍</span>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-10">

                        <Link to={"/dashboardusuario"}
                            className="btn btn-icon-white btn-text-white btn-primary">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        <button className='btn btn-danger' type="submit">
                            <i className="fa-solid fa-floppy-disk"></i>
                            {flag ? "Enviar Respuestas" : "Continuar"}</button>
                    </div>

                </form>
            </div>
        </div>
    );
}
