
import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProgramaDataService from "../_services/programa";
import CursoDataService from "../_services/curso";
import { useAuth } from "../app/modules/auth";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function ProgramaForm() {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idprograma = queryParameters.get("id")
  const [programas, setProgramas] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [temas, setTemas] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [arrtemas, setCursosPrograma] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setFlag(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handledelete = async (e) => {
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idprograma !== null) {
        ProgramaDataService.deleteprograma(idprograma)
          .then(result => {
            if (result.data.result == '0') {
              alert("Registro Eliminado con Éxito")
              navigate('/programa');
            } else {
              alert("Registro no se puede eliminar por dependencias : \n" + result.data.message)
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }
  const handleSubmit = async (e) => {

    e.preventDefault();
    const answer = window.confirm("Esta seguro de Guardar el Registro?");
    if (answer) {
      console.log(programas);
      if (idprograma == null) {
        programas.usuario_creacion = currentUser?.username
        
        console.log(programas)
        ProgramaDataService.createprograma(programas)
          .then(function (response) {
            for (var i = 0, l = respuestas.length; i < l; i++) {
              respuestas[i].id_programa = response.data.id_programa
            }
            CursoDataService.createcursoprograma(respuestas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Insertado correctamente");
            navigate('/programa');
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        programas.usuario_modificacion = currentUser?.username
        programas.id_programa = idprograma
        for (var i = 0, l = respuestas.length; i < l; i++) {
          respuestas[i].id_programa = idprograma
        }
        ProgramaDataService.updateprograma(programas)
          .then(function (response) {
            CursoDataService.createcursoprograma(respuestas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Actualizado correctamente");
            navigate('/programa');
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
  };
  const handleChange = (e) => {
    setProgramas((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckedRow = (event, id, titulo) => {
    setFlag(true);
    arrtemas.push({
      "id_programa_curso": 0,
      "id_curso": id,
      "titulo": titulo
    })

  };
  function filtrarTemaAsignado(temas_, filtros) {
    return temas_.filter((curso) => !filtros.includes(curso.id_curso));
  }
  function asignartemas(temas, filtros) {
    return temas.filter((item) =>
      filtros.some((filtro) => filtro.id_curso === item.id_curso)
    );
  }
  function seleccionartema() {
    if (flag) {
      var arrfiltro = []
      var arrcontenido = []
      //console.log(asignartemas(temas,arrtemas))
      for (var i = 0, l = arrtemas.length; i < l; i++) {
        let idcurso = arrtemas[i].id_curso
        let index = arrcontenido.findIndex(object => object.id_curso === idcurso);
        if (index === -1) {
          arrcontenido.push({
            "id_programa_curso": 0,
            "id_curso": arrtemas[i].id_curso,
            "titulo": arrtemas[i].titulo
          });
          arrfiltro.push(idcurso)
          setRespuestas(arrcontenido)
        }

      }
      console.log(arrfiltro)
      console.log(cursos)
      console.log(filtrarTemaAsignado(cursos, arrfiltro))
      setCursos(filtrarTemaAsignado(cursos, arrfiltro))
    }


    handleClose();
  };

  const handleClick = (event, idcurso, title) => {
    event.preventDefault();
    //console.log(idcurso);
    let filteredArray = respuestas.filter(item => item.id_curso !== idcurso)
    console.log(filteredArray);
    setRespuestas(filteredArray);
    cursos.push({
      "id_curso": idcurso,
      "titulo": title
    })

  };
  useEffect(() => {
    if (idprograma !== null) {
      CursoDataService.getprogramacurso(idprograma)
        .then(result => {
          setCursos(result.data)
          console.log(result.data)
        });
      ProgramaDataService.getprogramaById(idprograma)
        .then(response => response.json())
        .then(result => {
          setProgramas(result);
          //console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
      ProgramaDataService.getprogramascurso(idprograma)
        .then(function (response) {
          console.log(response.data)
          setCursosPrograma(response.data)
          for (var i = 0, l = response.data.length; i < l; i++) {
            respuestas.push({
              "id_programa_curso": response.data[i].id_tema_modulo,
              "id_curso": response.data[i].id_curso,
              "titulo": response.data[i].titulo
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      CursoDataService.getcursos()
        .then(result => {
          setCursos(result.data)
          console.log(result.data)
        });
    }
  }, []);
  return (
    <div className="card card-custom">
      <form onSubmit={handleSubmit}>
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <h2>Editar Programa</h2>
            </div>
          </div>

          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-150px">

            </div>

            <Link to={"/programa"}
              className="btn btn-icon-white btn-text-white btn-danger btn-sm">
              <i className="fa-solid fa-reply "></i>
              Volver
            </Link>
            <button className='btn btn-primary btn-sm' type="submit">
              <i className="fa-solid fa-floppy-disk"></i>
              Guardar</button>
            <Button className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success btn-sm" onClick={handleClickOpen}>
              <><i className="fa-solid fa-floppy-disk"></i></>
              Agregar  Curso
            </Button>
          </div>
        </div>
        <div className="card-body pt-10">
          <div className="form-group row">
            <div className="col-lg-3   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Codigo</span>
                <input type="text" name="codigo" maxLength={"20"} required defaultValue={programas.codigo}
                  className="form-control" onChange={handleChange} />
              </div></div>
            <div className="col-lg-6   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Titulo</span>
                <input type="text" name="titulo" maxLength={"250"} required defaultValue={programas.titulo}
                  className="form-control" onChange={handleChange} />
              </div></div>
            <div className="col-lg-3   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Estado</span>
                <select className="form-control" onChange={handleChange}
                  value={programas.codigo_estado} required name="codigo_estado">
                  <option >-- Seleccione --</option>
                  <option value="0">En Desarrollo</option>
                  <option value="1">Activo</option>
                  <option value="2">Inactivo</option>
                </select>
              </div>
            </div>
            
            <div className="col-lg-12   input-group-sm mb-5">
              <div className="input-group input-group-sm  ">
                <span className="input-group-text" id="inputGroup-sizing-sm">Descripcion</span>
                <input type="text" name="descripcion" maxLength={"250"} required
                  defaultValue={programas.descripcion}
                  className="form-control" onChange={handleChange} />
              </div></div>




          </div>
        </div>
      </form>
      <div className="card-footer bg-gray-100 border-top-0 text-right">
        <div className="col-lg-12 ">
          <form  >
            <div className="table table-rounded table-striped border table-responsive">
              <table className="table"  >
                <thead>
                  <tr className="fw-bold fs-6 border border-gray-200">
                    <th>Nro</th>
                    <th>curso</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {respuestas.map((item, index) => (
                    <>
                      <tr>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          <Link to={"/cursoedit?id=" + item.id_curso}
                            className="text-primary">
                            {item.titulo}
                          </Link>
                        </td>
                        <td>
                          <button className='btn btn-icon btn-danger'
                            onClick={event => handleClick(event, item.id_curso, item.titulo)}
                            type="submit" >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <button type="button" style={idprograma > 0 ? { display: "block" } : { display: "none" }}
          className="btn btn-outline btn-outline-dashed btn-outline-danger  btn-sm"
          onClick={handledelete}
        >
          Eliminar Programa
        </button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Seleccione los cursos asociados al programa"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <table className="table">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800">
                  <th>Sel</th>
                  <th>curso</th>
                </tr>
              </thead>
              <tbody>

                {cursos.map((item) => (
                  <>
                    <tr>
                      <td><input type="checkbox" value={item.id_curso} className="form-check"
                        name={"id_curso" + item.id_curso}
                        onChange={(event) => handleCheckedRow(event, item.id_curso, item.titulo)}></input></td>
                      <td>{item.titulo}</td>
                    </tr>
                  </>
                ))}

              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button className="btn btn-primary btn-sm" onClick={seleccionartema} autoFocus>
            Seleccionar Cursos
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
