

import { Link } from "react-router-dom"
import PreguntaForm from "../../../_components/preguntaForm";
  
export function PreguntaEditar  () {
  return (
 <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <PreguntaForm></PreguntaForm>
                    </div>
                </div>
            </div>
        </div>
  );
}