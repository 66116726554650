
import React, { Component, useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TemaDataService from "../_services/tema";
import { useAuth } from "../app/modules/auth";
import ModuloDataService from "../_services/modulo";
import ParametroDataService from "../_services/parametro";
import PreguntaDataService from "../_services/pregunta";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { left } from "@popperjs/core";
const { SearchBar } = Search;



export default function TemaForm() {
  const { currentUser, logout } = useAuth()
  const [temas, setTemas] = useState([]);
  const [temapregunta, setTemasPregunta] = useState([]);
  const [preguntas, setPregunta] = useState([]);
  const [temasreferencia, setTemasReferencia] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [categoriapregunta, setCategoriaPregunta] = useState([]);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idtema = queryParameters.get("id")
  //const SelPregunta=[];
 
  function filtrarItems(data, filtros) {
    return data.filter((item) => !filtros.includes(item.id_pregunta));
  }
  const handleCheckedRow = ( event,idpregunta) => {
    const idindex = temapregunta.findIndex(element => element.id_pregunta === idpregunta);
    var arrfiltro=[]
    if (idindex>=0){
      temapregunta.splice(idindex,1)
      console.log(temapregunta)
    }else {

      temapregunta.push({
        "id_pregunta":idpregunta,"id_tema":0
      })
      console.log(temapregunta)
    }
    
  };
  const columns = [
    {   dataField: 'id_pregunta',   text: 'Seleccionar' ,formatter: (rowContent, row) => {
      let indexf = 0;
      for (var i = 0, l = temapregunta.length; i < l; i++) {
        if (temapregunta[i].id_pregunta== rowContent){
          indexf=temapregunta[i].id_tema
        }
      }
    return (
      <>
        {indexf>0? 
        <>
          <input type="checkbox" id={rowContent} className="form-check" defaultChecked={true}   
          onChange={(event) => handleCheckedRow(event,rowContent)} ></input> 
        </>:
        <>
          <input type="checkbox" id={rowContent} className="form-check"   
          onChange={(event) => handleCheckedRow(event,rowContent)} ></input> 
        </>}
        
      </>
    )
    } }, 
    {   dataField: 'pregunta',   text: 'Pregunta',sort:true }    ,     
    {   dataField: 'fecha_creacion',   text: 'Fecha Creacion',sort:true }    ,         
    {   dataField: 'codigo_categoria',   text: 'Categoría',sort:true }    ,         

  ];
  const handledelete = async (e) => {
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idtema !== null) {
        TemaDataService.deletetema(idtema)
          .then(result => {
            //setResult(result.data);
            //console.log(result.data);
            if (result.data.result == '0') {
              alert("Registro Eliminado con Éxito")
              navigate('/tema');
            } else {
              alert("Registro no se puede eliminar por dependencias : \n" + result.data.message)
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const answer = window.confirm("Esta seguro de Guardar el Registro?");
    if (answer) {
      console.log(temas);
      if (idtema == null) {
       
        temas.usuario_creacion = currentUser?.username
        //console.log(temas);
        TemaDataService.createtema(temas)
          .then(function (response) {
            console.log( response);
            for (var i = 0, l = temapregunta.length; i < l; i++) {
              temapregunta[i].id_tema = response.data.id_tema
            }
            console.log( temapregunta);
            TemaDataService.createtemapregunta(JSON.stringify(temapregunta))
                    .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            alert("Registro Actualizado correctamente");
                            navigate('/tema');
                        })
                    .catch(function (error) {
                            console.log(error);
                        });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        temas.usuario_modificacion = currentUser?.username
        temas.id_tema = idtema
        TemaDataService.updatetema(temas)
          .then(function (response) {
            for (var i = 0, l = temapregunta.length; i < l; i++) {
              temapregunta[i].id_tema = response.data.id_tema
            }
            console.log( temapregunta);
            TemaDataService.createtemapregunta(JSON.stringify(temapregunta))
                    .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            alert("Registro Actualizado correctamente");
                            navigate('/tema');
                        })
                    .catch(function (error) {
                            console.log(error);
                        });
           // alert("Registro Actualizado correctamente");
           // navigate('/tema');
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
  };
  const handleChange = (e) => {
    //console.log();
    setTemas((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (idtema !== null) {
      TemaDataService.gettemaById(idtema)
        .then(result => {
          setTemas(result.data);
          //console.log(result.data);
        })
        .catch(e => {
          console.log(e);
        });
      TemaDataService.gettemaspregunta(idtema)
        .then(result => {
          //setTemasPregunta(result.data);
          console.log(temapregunta);
          for (var i = 0, l = result.data.length; i < l; i++) {
            temapregunta.push({
              "id_pregunta": result.data[i].id_pregunta,
              "id_tema":result.data[i].id_tema
            })

          }
          console.log(temapregunta);
        })
        .catch(e => {
          console.log(e);
        });
      TemaDataService.gettemaById(idtema)
        .then(result => {
          setTemas(result.data);
          //console.log(result.data);
        })
        .catch(e => {
          console.log(e);
        });
      PreguntaDataService.getpreguntasasignado(idtema)
        .then(response => {
          console.log(response.data)
          setPregunta(response.data)
        })
        .catch(e => {
          console.log(e);
        });
    }
    PreguntaDataService.getpreguntasasignado(0)
    .then(response => {
      console.log(response.data)
      setPregunta(response.data)
    })
    .catch(e => {
      console.log(e);
    });
    TemaDataService.gettemas('1')
      .then(result => {
        setTemasReferencia(result.data)
        //console.log(result.data)
      });
    
    ParametroDataService.getparametrosbycod('categoria')
      .then(response => {
        setCategoria(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    ParametroDataService.getparametrosbycod('categoria_pregunta')
      .then(response => {
        setCategoriaPregunta(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <>
 <form onSubmit={handleSubmit}>
      <div className="card card-custom">
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <h3 className="card-title">Editar Tema</h3>
          <div className="card-toolbar">
            <Link to={"/tema"}
              className="btn btn-icon-white btn-text-white btn-danger">
              <i className="fa-solid fa-reply "></i>
              Volver
            </Link>
            <button className='btn btn-primary' type="submit">
              <i className="fa-solid fa-floppy-disk"></i>
              Guardar</button>
          </div>
        </div>
        <div className="card-body">
          <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_4"
              >
                Categoria
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#kt_tab_pane_5"
              >
                Preguntas Vinculadas
              </a>
            </li>
            
          </ul>
          <div className="tab-content" id="myTabContent">
           
            <div               className="tab-pane fade show active"
              id="kt_tab_pane_4"
              role="tabpanel">
              
                <div className="form-group row">
                  <div className="col-lg-6   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Título</span>
                      <input type="text" name="titulo" required defaultValue={temas.titulo}
                        maxLength={50}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-3   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Categoria</span>
                      <select value={temas.codigo_categoria}
                        className="form-control  "
                        name="codigo_categoria" required
                        onChange={handleChange}>
                        <option value=""> -- Seleccione -- </option>
                        {categoria.map((parametro) => <option value={parametro.id_parametro}>{parametro.codigo_parametro}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Estado</span>
                      <select className="form-control" onChange={handleChange}
                        value={temas.codigo_estado} required name="codigo_estado">
                        <option >-- Seleccione --</option>
                        <option value="0">En Desarrollo</option>
                        <option value="1">Activo</option>
                        <option value="2">Inactivo</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Descripción</span>
                      <input type="text" name="descripcion" defaultValue={temas.descripcion}
                        maxLength={250}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-12   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" required placeholder="Ingrese la URL o Hipervínculo al contenido" id="inputGroup-sizing-sm">Contenido</span>
                      <input type="text" name="contenido" defaultValue={temas.contenido}
                        maxLength={250}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-12   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" 
                      placeholder="Ingrese la URL o Hipervínculo al contenido complementario" 
                      id="input_contenido_complementario">Contenido Complementario</span>
                      <input type="text" name="contenido_complementario" defaultValue={temas.contenido_complementario}
                        maxLength={250}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-4  input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Etiquetas</span>
                      <input type="text" name="etiquetas" defaultValue={temas.etiquetas}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-4   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Duración (minutos)</span>
                      <input type="number" name="duracion" required defaultValue={temas.duracion}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  
                  <div className="col-lg-4   input-group-sm mb-5">
                    <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Numero Preguntas a Visualizar</span>
                      <input type="number" name="numero_preguntas" required defaultValue={temas.numero_preguntas}
                        maxLength={2}
                        className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="mb-10">
                  <div className="form-check form-check-custom form-check-solid form-check-lg">
                    <input className="form-check-input" type="checkbox"
                      name="indicador_obligatorio"
                      defaultChecked={temas.indicador_obligatorio}
                      onChange={handleChange} id="indicador_obligatorio" />
                    <label className="form-check-label" >
                      Tema Obligatorio
                    </label>
                  </div>
                </div>
             
            </div>
            <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
              <ToolkitProvider
                keyField="id_pregunta"
                data={ preguntas }
                columns={ columns }
                search           >
                {
                  props => (
                    <div>
                      <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                      <hr />
                      <BootstrapTable className="table table-responsive table-bordered"
                        { ...props.baseProps }
                      />
                    </div>
                  )
                }
              </ToolkitProvider>
            </div>
             
          </div>
           
        </div>
        <div className="card-footer">
          <button type={"button"} style={idtema>0? {display:"block"}:{ display: "none" }}  
              className="btn btn-outline btn-outline-dashed btn-outline-danger btn-sm"  
              onClick={handledelete}                >
              Eliminar Tema
          </button>
        </div>
      </div>
     
      </form>
    </>
  );
}
