
import React, { Component, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import ModuloDataService from "../_services/modulo";
import { useAuth } from "../app/modules/auth";
import ParametroDataService from "../_services/parametro";
import TemaDataService from "../_services/tema";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

export default function ModuloForm() {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idmodulo = queryParameters.get("id")
  const [modulos, setModulos] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [temas, setTemas] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [arrtemas, setTemasModulo] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handledelete = async (e) => {
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idmodulo !== null) {
        console.log(idmodulo)
        ModuloDataService.deletemodulo(idmodulo)
          .then(result => {
            console.log(result.data)
            if (result.data.result == '0') {
              alert("Registro Eliminado con Éxito")
              navigate('/modulo');
            } else {
              alert("Registro no se puede eliminar por dependencias : \n" + result.data.message)
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }
  const handleClickOpen = () => {
    setFlag(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const answer = window.confirm("Esta seguro de Guardar el Registro?");
    if (answer) {
      console.log(modulos);
      if (idmodulo == null) {
        modulos.usuario_creacion = currentUser?.username
        ModuloDataService.createtemamodulo(modulos)
          .then(function (response) {
            for (var i = 0, l = arrtemas.length; i < l; i++) {
              arrtemas[i].id_modulo = response.data.id_modulo
            }
            console.log(arrtemas)
            TemaDataService.createtemamodulo(arrtemas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
              navigate('/modulo');
          })
          .catch(function (error) {
            console.log(error);
          });

      } else { // Update Modulo
        modulos.usuario_modificacion = currentUser?.username
        modulos.id_modulo = idmodulo
        for (var i = 0, l = respuestas.length; i < l; i++) {
          respuestas[i].id_modulo = idmodulo
        }
        //console.log(JSON.stringify(respuestas))
        
        ModuloDataService.updatemodulo(modulos)
          .then(function (response) {
            //console.log(JSON.stringify(arrtemas))
            TemaDataService.createtemamodulo(JSON.stringify(respuestas))
              .then(function (response) {
                //console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Actualizado correctamente");
            navigate('/modulo');
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
  };
  const handleChange = (e) => {
    // console.log(e);
    setModulos((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

  };
  const columns = [
    {   dataField: 'id_tema',   text: 'Seleccionar' ,formatter: (rowContent, row) => {
      return (
      <>
          <input type="checkbox" id={rowContent} className="form-check"   
           onChange={(event) => handleCheckedRow(event,row["id_tema"], row["titulo"])}  ></input> 
      </>
    )
    } },
    { dataField: 'titulo', text: 'Tema', sort: true  },
    { dataField: 'codigo_categoria', text: 'Categoria', sort: true  },  
  ];
  const handleCheckedRow = (event, id, title) => {
    //console.log(event.target)
    //console.log(id)
   // console.log(title)
    setFlag(true);
    arrtemas.push({
      "id_tema_modulo": 0,
      "id_tema": id,
      "titulo": title, 
      "cuestionario": 0,
      "numero_orden":0
    })

  };
  const handleCuestionarioRowTema = (event, idtema) => {
    const idindex = arrtemas.findIndex(element => element.id_tema === idtema);
    arrtemas[idindex].cuestionario = event.target.value
    const idindexp = respuestas.findIndex(element => element.id_tema === idtema);
    respuestas[idindexp].cuestionario = event.target.value
    //console.log(event.target.value)
    
    //console.log(arrtemas)
  };
  const handleOrdenRowTema = (event, idtema) => {
    const idindex = arrtemas.findIndex(element => element.id_tema === idtema);
    arrtemas[idindex].numero_orden = event.target.value
    const idindexp = respuestas.findIndex(element => element.id_tema === idtema);
    respuestas[idindexp].numero_orden = event.target.value
    //console.log(event.target.value)
    
    //console.log(arrtemas)
  };
  const handleCheckedRowTema = (event, idtema) => {
    const idindex = arrtemas.findIndex(element => element.id_tema === idtema);

    if (arrtemas[idindex].cuestionario == 0) {
      arrtemas[idindex].cuestionario = 1
    } else {
      arrtemas[idindex].cuestionario = 0
    }

    console.log(arrtemas)
  };
  function filtrarTemaAsignado(temas_, filtros) {
    return temas_.filter((curso) => !filtros.includes(curso.id_tema));
  }
  function asignartemas(temas, filtros) {
    return temas.filter((item) =>
      filtros.some((filtro) => filtro.id_tema === item.id_tema)
    );
  }
  function seleccionartema() {
    if (flag) {
      var arrfiltro = []
      var arrcontenido = []
      for (var i = 0, l = arrtemas.length; i < l; i++) {
        let idtema = arrtemas[i].id_tema
        let index = arrcontenido.findIndex(object => object.id_tema === idtema);
        let irow=0;
        if (index === -1) {
          arrcontenido.push({
            "id_tema_modulo": 0,
            "id_tema": arrtemas[i].id_tema,
            "titulo": arrtemas[i].titulo, 
            "cuestionario": 1,
            "numero_orden":irow++
          });
          arrfiltro.push(idtema)
          setRespuestas(arrcontenido)
        }
      }
      //console.log(respuestas)
      //console.log(arrtemas)
      // console.log(filtrarTemaAsignado(temas,arrfiltro))
      setTemas(filtrarTemaAsignado(temas, arrfiltro))
    }
    handleClose();
  };
  
  const handleClick = (event, idtema, title) => {
    event.preventDefault();
    console.log(idtema);
    let filteredArray = respuestas.filter(item => item.id_tema !== idtema)
    console.log(filteredArray);
    setRespuestas(filteredArray);
    const idindex = arrtemas.findIndex(element => element.id_tema === idtema);
    arrtemas.splice(idindex, 1)
    const idindexr = respuestas.findIndex(element => element.id_tema === idtema);
    respuestas.splice(idindex, 1)
    temas.push({
      "id_tema": idtema,
      "titulo": title
    })

  };
  useEffect(() => {
    if (idmodulo !== null) {
      TemaDataService.gettemasmodulo(idmodulo)
        .then(result => {
          setTemas(result.data)
          
        });
      ModuloDataService.getmoduloById(idmodulo)
        .then(response => response.json())
        .then(result => {
          setModulos(result);
          //console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
      ModuloDataService.getmodulostema(idmodulo)
        .then(function (response) {
          console.log(response.data)
          setTemasModulo(response.data)
          for (var i = 0, l = response.data.length; i < l; i++) {
            respuestas.push({
              "id_tema_modulo": response.data[i].id_tema_modulo,
              "id_tema": response.data[i].id_tema,
              "titulo": response.data[i].titulo,
              "cuestionario":response.data[i].cuestionario,
              "numero_orden":response.data[i].numero_orden
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      TemaDataService.gettemasestado('1')
        .then(result => {
          setTemas(result.data)
          console.log(result.data)
        });
    }
    ParametroDataService.getparametrosbycod('categoria')
      .then(response => {
        setCategoria(response.data)
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

  }, []);
  const selectedRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
        //console.log('=== selectRow row ===', row);
        if (isSelect) {

        } else {
        }
    },
    onSelectAll: (isSelected, rows) => {

        if (isSelected) {
            for (let i = 0; i < rows.length; i++) {

            }
        } else {

            for (let i = 0; i < rows.length; i++) {

            }
        }
        //console.log(seleccionados)
    }
};
  return (
    <>
      <div className="card card-custom">
        <form onSubmit={handleSubmit}>
          <div className="card-header align-items-center py-5 gap-2 gap-md-5">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1">
                <h2>Editar Modulo</h2>
              </div>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
              <Link to={"/modulo"}
                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                <i className="fa-solid fa-reply "></i>
                Volver
              </Link>
              <button className='btn btn-primary btn-sm' type="submit">
                <i className="fa-solid fa-floppy-disk"></i>
                Guardar</button>

              <Button className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success btn-sm " onClick={handleClickOpen}>
                <><i className="fa-solid fa-floppy-disk"></i></>
                Agregar Tema
              </Button>
            </div>
          </div>
          <div className="card-body pt-10">
            <div className="form-group row">
              <div className="col-lg-3   input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                  <label className="input-group-text">Código:</label>
                  <input type="text" name="codigo" defaultValue={modulos.codigo}
                    className="form-control" onChange={handleChange} required maxLength={50} />
                </div></div>
              <div className="col-lg-6 input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                  <label className="input-group-text">Título:</label>
                  <input type="text" name="titulo" defaultValue={modulos.titulo}
                    className="form-control" onChange={handleChange} required maxLength={50} />
                </div>
              </div>
              <div className="col-lg-3   input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Estado</span>
                      <select className="form-control" onChange={handleChange}
                        value={modulos.codigo_estado} required name="codigo_estado">
                        <option >-- Seleccione --</option>
                        <option value="0">En Desarrollo</option>
                        <option value="1">Activo</option>
                        <option value="2">Inactivo</option>
                      </select>
                </div>
              </div>
              
              <div className="col-lg-12 input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                  <span className="input-group-text"  >Descripción</span>
                  <input type="text" name="descripcion" defaultValue={modulos.descripcion}
                    className="form-control" onChange={handleChange} maxLength={250} />
                </div></div>
              <div className="col-lg-4 input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                  <span className="input-group-text"  >Categoria</span>
                  <select value={modulos.codigo_categoria}
                    className="form-control mb-2"
                    name="codigo_categoria" required
                    onChange={handleChange}>
                    <option value=""> -- Seleccione -- </option>
                    {categoria.map((parametro) => <option value={parametro.id_parametro}>{parametro.codigo_parametro}</option>)}
                  </select>
                </div></div>

              <div className="col-lg-8 input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                  <span className="input-group-text"  >Etiquetas</span>
                  <input type="text" name="etiquetas" defaultValue={modulos.etiquetas}
                    className="form-control" onChange={handleChange} />
                </div></div>


            </div>

          </div>
        </form>

        <div className="card-footer bg-gray-100 border-top-0 text-right">
          <div className="col-lg-12 ">
            <form  >
              <div className="table table-rounded table-striped border table-responsive">
 
                <table className="table"  >
                  <thead>
                    <tr className="fw-bold fs-6 border border-gray-200">
                      <th>Nro</th>
                      <th>Tema</th>
                      <th>Cuestionario</th>
                      <th>Orden</th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {respuestas.map((item, index) => (
                      <>
                        <tr>
                          <td>
                            {index + 1}
                          </td>
                          <td>
                            <Link to={"/temaedit?id=" + item.id_tema}
                              className="text-primary">
                              {item.titulo}
                            </Link>
                          </td>
                          <td>
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                              <input type="number" id={'cuestionario'+item.id_tema} className="form-control"
                                defaultValue={item.cuestionario}
                                onChange={(event) => handleCuestionarioRowTema(event, item.id_tema)} ></input>
                            </div>
                          </td>
                          <td>
                            <input type="number" id={'orden'+item.id_tema} className="form-control"
                              defaultValue={item.numero_orden}
                              onChange={(event) => handleOrdenRowTema(event, item.id_tema)} ></input>
                          </td>
                          <td>
                            <button className='btn btn-icon btn-danger btn-sm'
                              onClick={event => handleClick(event, item.id_tema, item.titulo)}
                              type="submit" >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
          <button type="button" style={idmodulo > 0 ? { display: "block" } : { display: "none" }}
            className="btn btn-outline btn-outline-dashed btn-outline-danger  btn-sm"
            onClick={handledelete}
          >
            Eliminar Modulo
          </button>
          <span className="text-info">Cuestionario: 1-Si 0-No</span>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Seleccione los temas asociados al módulo"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <ToolkitProvider
            keyField="id_tema"
            data={ temas }
            columns={ columns }
            search              >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                   
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
            
              
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
            <Button className="btn btn-primary btn-sm" onClick={seleccionartema} autoFocus>
              Seleccionar Temas
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </>

  );
}
