/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()

  return (
    <>
      { currentUser?.indicador_admin ?
          <>
            <SidebarMenuItem
              to='/dashboard'
              icon='/media/icons/duotune/art/art002.svg'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-app-indicator'/> 
          </> : 
          <>
            {currentUser?.indicador_soporte?
            <>
            
            </>:<></>}
          </>}

      
      { currentUser?.indicador_admin ? 
        <>
          <div className='menu-item'   >
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administración</span>
            </div>
          </div>
          <SidebarMenuItemWithSub
            to='/apps/chat'
            title='Maestros'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/files/fil002.svg'
          >
            <SidebarMenuItem to='/preguntaimportar' title='Carga Masiva Preguntas' hasBullet={true} />
            <SidebarMenuItem to='/tema' title='Tema' hasBullet={true} />
            <SidebarMenuItem to='/modulo' title='Modulo' hasBullet={true} />
            <SidebarMenuItem to='/curso' title='Curso' hasBullet={true} />
            <SidebarMenuItem to='/programa' title='Programa' hasBullet={true} />
            <SidebarMenuItem to='/planestudio' title='Plan de Estudios' hasBullet={true} />

            <SidebarMenuItem to='/pregunta' title='Base de Preguntas' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItemWithSub
            to='/apps/chat'
            title='Gestion'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/files/fil012.svg'
          >
            <SidebarMenuItem to='/acceso' title='Accesos' hasBullet={true} />
            <SidebarMenuItem to='/usuario' title='Usuario' hasBullet={true} />
            <SidebarMenuItem to='/usuariogrupo' title='Asignar Grupo a Usuario' hasBullet={true} />
            <SidebarMenuItem to='/asignacionplan' title='Asignar Plan de Estudio y Usuario' hasBullet={true} />
            <SidebarMenuItem to='/asignacion' title='Asignaciones' hasBullet={true} />

          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/apps/chat'
            title='Parametros'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/general/gen055.svg'
          >
            <SidebarMenuItem to='/dominio' title='Parametros' hasBullet={true} />
            <SidebarMenuItem to='/empresa' title='Empresa' hasBullet={true} />
            <SidebarMenuItem to='/configuracion' title='Configuración' hasBullet={true} />

          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub
            to='/apps/file'
            title='Reportes'
            fontIcon='bi-file'
            icon='/media/icons/duotune/general/gen054.svg'
          >
             <SidebarMenuItem to='/reporteusuarioxempresa' title='Reporte Usuario x Empresa' hasBullet={true} />
            <SidebarMenuItem to='/reportenps' title='Reporte NPS' hasBullet={true} />
            <SidebarMenuItem to='/reportegralempresa' title='Reporte de Avance por Plan de Estudio' hasBullet={true} />
            <SidebarMenuItem to='/reportedptoceco' title='Reporte x Dpto y CeCo' hasBullet={true} />
            <SidebarMenuItem to='/reportelineatiempo' title='Reporte Linea de Tiempo' hasBullet={true} />
            <SidebarMenuItem to='/reportenoasignado' title='Reporte de Usuarios no Asignados' hasBullet={true} />

            <SidebarMenuItem to='/evaluacion' title='Reporte Cuestionarios' hasBullet={true} />


          </SidebarMenuItemWithSub>
        </>
          : 
          <>
            {currentUser?.indicador_soporte?
            <>
              {console.log('login soporte')}
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Opciones</span>
                </div>
              </div>
              <SidebarMenuItem to='/planestudio' title='Plan de Estudios' hasBullet={true} />

            </>:<>
              {console.log('login usuario')}
              <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Opciones</span>
                </div>
              </div>
              <SidebarMenuItem
                to='/dashboardusuario'
                icon='/media/icons/duotune/general/gen019.svg'
                title='Dashboard Usuario'
                fontIcon='bi-layers'
              />
              <SidebarMenuItem
                to='/planmislogros'
                icon='/media/icons/duotune/general/gen020.svg'
                title='Mis Logros'
                fontIcon='bi-layers'
              />
            </>}
          </>
      }
      <div className='menu-item'>
        <div className='menu-item  '>
          <a onClick={logout} className='menu-link text-danger'>
            <span className='menu-icon'>
              <i className="fa fa-person text-danger"></i>
            </span>
            Cerrar Sesión
          </a>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'

        >
          <span className='menu-icon'>

            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Version {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}

export { SidebarMenuMain }
