import React, { Component ,useEffect,useState} from "react";
import { useAuth } from "../app/modules/auth";
import { Link } from "react-router-dom";
import PlanDataService from "../_services/planestudio";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
 
const { SearchBar } = Search;
export default function PlanEstudioEDT() {
    
  const columns = [
    {   dataField: 'id',   text: 'Id' ,sort:true }, 
    {   dataField: 'nivel',   text: 'Editar' ,formatter: (rowContent, row) => {
        return (
          <>
            {row["nivel"]==='1'?
            <>
              <Link to={"/planestudioedit?id=" + rowContent}
                  className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                  <i className="fa-solid fa-eye   text-primary"></i>
                </Link>
            </>
            : 
            <>
               {row["nivel"]==='2'?
              <>
                <Link to={"/programaedit?id=" + rowContent}
                    className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                    <i className="fa-solid fa-eye   text-primary"></i>
                  </Link>
              </>
              : 
              <>
                {row["nivel"]==='3'?
                <>
                  <Link to={"/cursoedit?id=" + rowContent}
                      className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                      <i className="fa-solid fa-eye   text-primary"></i>
                    </Link>
                </>
                : 
                <>
                  {row["nivel"]==='4'?
                  <>
                    <Link to={"/moduloedit?id=" + rowContent}
                        className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                        <i className="fa-solid fa-eye   text-primary"></i>
                      </Link>
                  </>
                  : 
                  <>
                    <Link to={"/temaedit?id=" + rowContent}
                        className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
                        <i className="fa-solid fa-eye   text-primary"></i>
                      </Link>
                  </>
                  }
                </>
                }
              </>
              }
            </>
            }
              
                
                 
          </>
        )
      } },  
    {   dataField: 'edt',   text: 'EDT',sort:true },                   
    {   dataField: 'parent_edt',   text: 'EDT Padre',sort:true },
    {   dataField: 'tipo',   text: 'Tipo',sort:true ,formatter: (rowContent, row) => {
      return (
        <>
            {row["nivel"]==='1'?
            <>
            <span className="badge badge-info fw-bold">{rowContent}</span>
            </>:
            <>
            <span>{rowContent}</span>
            </>
            }
        </>
      )
    } },  
    {   dataField: 'titulo',   text: 'Titulo',sort:true  ,formatter: (rowContent, row) => {
      return (
        <>
          {row["nivel"]===pnivel?
          <>
            <span className="badge badge-warning fw-bold">{rowContent}</span>
          </>
          : 
          <>
            <span>{rowContent}</span>
          </>
          }
           
               
        </>
      )
    } },  
     ];
  const { currentUser, logout } = useAuth()
  const [progreso, setProgreso] = useState([]);
  const [kpi, setKPI] = useState([]);
  const [isOnline, setIsOnline] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search)
  const pnivel = queryParameters.get("id")
  const idreferencia = queryParameters.get("ref")
  useEffect(() => {
    const arrpar = ({
      "nivel":pnivel,
      "id_referencia":idreferencia
    })
    PlanDataService.getplanedt(JSON.stringify(arrpar))
      .then(result => {
        setProgreso(result.data);
        //console.log(result);
        setIsOnline(true)
    })
    .catch(e => {
        console.log(e);
    });
  }, [isOnline]);
  if (isOnline === null) {
    console.log(isOnline);
    return <>'Cargando...'</>;
  } else {
  return (
    
    <>
       
      <div className='row'>
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
          <div className="   " id="kt_docs_content_container">

            <div className="row  mb-5 mb-xl-8">
              <div className="col-xl-12">
                <div className="card card-flush">
                  <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <h1>Plan de Estudio EDT</h1>
                    <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                      <div className="w-100 mw-150px">

                      </div>

                    </div>
                  </div>

                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <ToolkitProvider
                        keyField="id"
                        data={ progreso }
                        columns={ columns }
                        search           >
                        {
                          props => (
                            <div>
                              <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                              <hr />
                              <BootstrapTable  className="table table-bordered table-responsive"
                                { ...props.baseProps }
                              />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>   
        </div>
      </div>
    </>
  )
}
}