import React, { useCallback, Component, useEffect, useState } from "react";
import axios from 'axios';
import LoadingSpinner from "../_components/LoadingSpinner";
import Select from 'react-select';
import ReporteDataService from "../_services/reporte";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import EmailService from "../_services/email";
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';
const options = {
    sizePerPageList: [{
      
      text: '100', value: 100
    } , {
        text: '250', value: 250
      }, {
        text: '500', value: 500
      }, {
        text: '1000', value: 1000
      }] // A numeric array is also available. the purpose of above example is custom the text
  };
const columns = [
    {
      dataField: 'id_usuario', text: 'Notifica', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {row["correo_electronico"] != '' ?
              <>
                <a className="btn btn-outline btn-icon btn-sm"
                  onClick={() => handleEmail(row)}>
                  <i className="fa fa-envelope   text-warning"> </i>
                </a>
              </> :
              <> </>}
          </>
        )
      }
    },
    { dataField: 'plan', text: 'Plan', sort: true },
    { dataField: 'fecha_creacion', text: ' Asignado', sort: true },
    { dataField: 'codigo_grupo', text: 'Grupo', sort: true, csvText: 'Codigo'},
    { dataField: 'empresa', text: 'Empresa', sort: true, csvText: 'Codigo'},

    { dataField: 'codigo', text: 'Código', sort: true, csvText: 'Codigo'},
    {
      dataField: 'nombres', text: 'Usuario', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {row["nombres"]} {row["apellido"]}
          </>
        )
      }
    },

    { dataField: 'correo_electronico', text: 'Email', sort: true },


    { dataField: 'fecha_inicio', text: 'Inicio', sort: true },
    { dataField: 'fecha_fin', text: 'Fin', sort: true },
  ];
  const handleEmail = (row) => {
    const answer = window.confirm("Seguro de Enviar Notificacion de Asignacion al Usuario?");
    if (answer) {
      console.log(row);
      const datausuario = []
      datausuario.push({
        "destinatario": row.correo_electronico,
        "nombre": row.nombres,
        "codigo": row.codigo,
        "plan": row.plan,
        "fecha_vencimiento": row.fecha_fin
      })
      console.log(JSON.stringify(datausuario));
      EmailService.asignausuario(JSON.stringify(datausuario))
        .then(response => {
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }


  }
const ComponenteEjemplo = () => {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    const [empresasel, selEmpresa] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const [filtro, setFiltro] = useState([]);
    const handleClick = async () => {
        try {
            
            setIsLoading(true)
            console.log(empresasel)
            console.log(fechadesde)
            console.log(fechahasta)
            //const response = await axios.get('https://apiucj.azurewebsites.net/api/programa/sellst/usuario/graph/1661');
            const response = await fetch('https://apiucj.azurewebsites.net/api/asignacion/sellst/reporte/'+empresasel+'/'+fechadesde+'/'+fechahasta );
            const result = await response.json();
            setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
            console.log(result)
            setIsLoading(false)
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
    const handleChangeSelect = (e) => {
        console.log(e)
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        //console.log(arrtemp)
        selEmpresa(arrtemp.toString())
        //console.log(empresasel)
       /// setFiltro((prev) => ({
         //  ...prev,
         // ['id_empresa']: arrtemp.toString(),
         // }));
          console.log(empresasel)
    }
    const handleChange = (e) => {
        //console.log(e);
        //console.log(e.target.value);
        // setTodo({ ...todo, [e.target.name]: e.target.value });
        setFiltro((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
        console.log(filtro)
      };
    useEffect(() => {
        const date = new Date();
        const futureDate = date.getDate() + 7;
        // 
        const defaultfecha = date.toLocaleDateString('en-CA');
        date.setDate(futureDate);
        const defaultfecha2 = date.toLocaleDateString('en-CA');

        setFechaDesde(defaultfecha)
        setFechaHasta(defaultfecha2)
        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
        }
        ReporteDataService.obtener_parametros(parempresa)
            .then(result => {
                setEmpresa(result.data);
                //console.log(result.data)
                setIsLoading(false)
            })
            .catch(e => {
                console.log(e);
            });


    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <div className="card card-custom">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">Reporte Usuarios Asignados</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                </h3>
                <div className="card-toolbar">
                    <button className='btn btn-primary' onClick={handleClick}>Generar Reporte</button>
                </div>
            </div>

            <div className="card-body">
                <div className="form-group row">
                    <div className="col-lg-3   input-group-sm mb-5">
                        <Select className="form-control"
                            placeholder="Empresas"
                            name="id"
                            isMulti
                            onChange={(e) => handleChangeSelect(e)}
                            options={empresa}
                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.codigo}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Codigo:
                        <input type="text" className="form-control" id="codigo_usuario"
                            placeholder="Codigo Usuario" name="codigo_usuario"
                            onChange={handleChange} ></input>
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Fecha Desde: <input type="date" className="form-control"
                         id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                         defaultValue={fechadesde} />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Hasta: <input type="date" className="form-control" 
                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                        defaultValue={fechahasta} />
                    </div>
                </div>
               
                <ToolkitProvider
                    keyField="id_asignacion"
                    data={datos}
                    columns={columns}
                    search           >
                    {
                        props => (
                            <div className="table-responsive">
 
                                <BootstrapTable className="table table-bordered table-responsive"
                                 pagination={ paginationFactory(options) }
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
               
            </div>

        </div>
    );

};

export default ComponenteEjemplo;
