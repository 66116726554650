
 
import axios from 'axios';
import React, {Component} from 'react';
import { Programa } from '../_models/programa';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/programa";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/programa";
class ProgramaService extends Component{
    constructor(){
        super(); 
        this.state = {
            users: []
        };
    }
    getprogramas(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createprograma(Programa){
        return axios.post(API_BASE_URL+ '/ins', Programa,{
            headers: {'Content-Type': 'application/json'}
        })}
    createprogramaplan(programa){
            return axios.post(API_BASE_URL+ '/ins/plan', programa,{
                headers: {'Content-Type': 'application/json'}
        })}
    getprogramaById(ProgramaId){
        return fetch(API_BASE_URL + '/sel/' + ProgramaId, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
        })  }
    getProgramaDetalleById(ProgramaId){
        return fetch(API_BASE_URL + '/sel/detalle/' + ProgramaId, {
             method: 'GET', headers: { 'Content-Type': 'application/json' }
        })  }
    getprogramadetalleusuarioById(idusuario,idplan,idprograma){
        return fetch(API_BASE_URL + '/sellst/usuario/det/' + idusuario+'/'+idplan+'/'+idprograma, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
        })  }
    getprogramausuariographById(idusuario){
        return fetch(API_BASE_URL + '/sellst/usuario/graph/' + idusuario, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
        })  }
    getprogramascurso(programa){
            return axios.get( API_BASE_URL+ '/sellst/curso/'+ programa, {
                headers: {                'Content-Type': 'application/json'            }
        }) 
    }
    getprogramasusuario(programa){
        return axios.get( API_BASE_URL+ '/sellst/usuario/'+ programa, {
            headers: {                'Content-Type': 'application/json'            }
    }) 
}
    getplanprograma(plan){
        return axios.get( API_BASE_URL+ '/sellst/plan/'+ plan, {
            headers: {                'Content-Type': 'application/json'            }
    }) 
}
    updateprograma(Programa){
        return axios.post(API_BASE_URL + '/upd/' , Programa,{
            headers: {'Content-Type': 'application/json'}
        })}

    deleteprograma(programaId){
        return axios.get(API_BASE_URL + '/del/' + programaId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new ProgramaService()