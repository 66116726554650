import React, { useState } from 'react';
import { read, utils, writeFile } from 'xlsx';
import { Link ,useNavigate} from "react-router-dom";
import PreguntaDataService from '../_services/pregunta';
import { useAuth } from "../app/modules/auth";

function PreguntaImportarForm() {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth()
  const [fileData, setFileData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [errores, setErrores] = useState([]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, {type: 'array'});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(sheet, {header: 1});
      setFileData(file);
      setTableData(json);
    }
    reader.readAsArrayBuffer(file);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validac = "1, 2 3, 4";

    const answer = window.confirm("Esta seguro de Importar las preguntas?");
    if (answer) {
      console.log(tableData)
      var preguntas =[]
      for (var i = 1, l = tableData.length; i < l; i++) {    
        let icorr = tableData[i][5];   
        console.log(icorr) 
        if (
          (icorr==1) || 
          (icorr==2) || 
          (icorr==3) || 
          (icorr==4) )
        {
          preguntas.push({
            pregunta: tableData[i][0] ,
            rpta_a:tableData[i][1] ,
            rpta_b:tableData[i][2] ,
            rpta_c:tableData[i][3] ,
            rpta_d:tableData[i][4] , 
            indicador_correcta:tableData[i][5] ,
            codigo_categoria:tableData[i][6] ,
            codigo_estado:"1",
            usuario_creacion:currentUser?.username
          })
        } 
        else {
          const parerror = {
            "pregunta":  tableData[i][0],
            "indicador_correcta": tableData[i][5]
          }
          setErrores(parerror)
        }
        
      }
      console.log(errores)
      console.log(preguntas)
      PreguntaDataService.createpregunta(preguntas)
      .then( (response) =>{
        //console.log(JSON.stringify(response.data));
        //alert("Registro Actualizado correctamente");
        navigate('/pregunta');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  return (
    <div className="card card-custom">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <h2>Carga Masiva de Preguntas</h2>
          </div>
        </div>
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 mw-150px">
          
          </div>
          <Link to={"/pregunta"}
                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                <i className="fa-solid fa-reply "></i>
                                Volver
                            </Link>
                            <button className='btn btn-primary btn-sm' onClick={handleSubmit}  >
                                <i className="fa-solid fa-floppy-disk"></i>
                                Guardar</button>
        </div>
      </div>
      <div className="card-body pt-10">
        <form>
          <label>
            Cargar archivo xlsx:
            <input type="file" onChange={handleFileChange} />
          </label>
        </form>
        {tableData && (
          <table className="table table-bordered">
            <thead>
              <tr>
                {tableData[0].map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    
                    
                    <td key={`${rowIndex}-${cellIndex}`}>
                      
                      {cell==1 || cell==2 || cell==3 || cell==4?
                        <>
                        <span>{cell}</span>
                        </>:
                        <> 
                        {cellIndex==5?<>
                          <span className='label text-danger'>Error:{cell }</span>
                        </>:
                        <>
                        
                        <span>{cell}</span>
                        </>}
                        </>}
                     
                      </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="card-footer">
        <a href="https://ucj2.azurewebsites.net/content/plantilla-carga-preguntas.xlsx" 
        target='_blank' className='text-success'>
           <i className="fa fa-file text-success "></i>
          Descarga Plantilla</a>
          <br></br>
         
          <span className="badge badge-light-danger fs-base">
             <span className="path1"></span><span className="path2"></span>                
              Alternativa Correcta( 1-2-3-4)
        </span>
      </div>
    </div>
  );
}

export default PreguntaImportarForm;
