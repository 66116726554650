
 
import axios from 'axios';
import React, {Component} from 'react';

import { pregunta } from '../_models/pregunta';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/pregunta";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/pregunta";
class PreguntaService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getpreguntas(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getpreguntastema(parametros){
        return axios.post( API_BASE_URL+ '/sellst/tema/',parametros, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getpreguntasasignado(idtema){
        return axios.get( API_BASE_URL+ '/sellst/asignado/'+idtema, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createpregunta(pregunta){
        return axios.post(API_BASE_URL+ '/ins', pregunta,{
            headers: {'Content-Type': 'application/json'}
        })}
    getpreguntaById(preguntaId){
        return fetch(API_BASE_URL + '/sel/' + preguntaId);
    }
    updatepregunta(pregunta){
        return axios.post(API_BASE_URL + '/upd/' , pregunta,{
            headers: {'Content-Type': 'application/json'}
        })}

    deletepregunta(preguntaId){
        return axios.get(API_BASE_URL + '/del/' + preguntaId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new PreguntaService()