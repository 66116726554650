
 
import axios from 'axios';
import React, {Component} from 'react';
import { log } from '../_models/log';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/log";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/log";

const enumtipo  = {
    cargar: 'cargar',
    insertar: 'insertar',
    eliminar: 'eliminar',
    actualizar: 'actualizar',
  } 
 
class LogService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getlogs(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    registralog(tabla,evento,detalle,idusuario,usuario){
        const data = {
            "id_usuario": idusuario,
            "usuario_creacion":usuario,
            "tabla":tabla,
            "tipo":evento,
            "detalle":detalle
        }
        return axios.post(API_BASE_URL+ '/ins',data,{
            headers: {'Content-Type': 'application/json'}
        })}
    createlog(log){
        return axios.post(API_BASE_URL+ '/ins', log,{
            headers: {'Content-Type': 'application/json'}
        })}
    getlogById(logId){
        return fetch(API_BASE_URL + '/sel/' + logId);
    }
    updatelog( log){
        return axios.post(API_BASE_URL + '/upd/' , log,{
            headers: {'Content-Type': 'application/json'}
        }) }

    deletelog(logId){
        return axios.delete(API_BASE_URL + '/' + logId);
    }
}

export default new LogService()