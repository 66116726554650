 import PaginaPdf from "../../../_components/certificadopdf"
 import CertificadoForm from "../../../_components/certificadoForm"
 export function CertificadoPDF  () {
    return (
        <div className="row  ">
                    <div className="col-xl-12">
             <CertificadoForm></CertificadoForm>
            </div>
        </div>
    )
    
}