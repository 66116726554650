import React, { useCallback, Component, useEffect, useState } from "react";
import { read, utils, writeFile } from 'xlsx';
import { Link ,useNavigate} from "react-router-dom";
import PlanDataService from '../_services/planestudio';
import { useAuth } from "../app/modules/auth";
import LoadingSpinner from "../_components/LoadingSpinner";

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/planestudio/";

function PlanEstudioImportarForm() {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth()
  const [fileData, setFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [importar, setImportar] = useState([]);
  const [cargar, setCargar] = useState([]);
  const [plan, setPlan] = useState([]);
  const queryParameters = new URLSearchParams(window.location.search)
  const idplanestudio = queryParameters.get("id")
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, {type: 'array'});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(sheet, {header: 1});
      setFileData(file);
      ///setTableData(json);
      //console.log(json)
      for (var i = 1, l = json.length; i < l; i++) {
        importar.push({
          "id_planestudio": idplanestudio,
          "codigo": json[i][0]
        })
      }
      
      PlanDataService.getplanimportar(importar)
      .then(function (response) {
         console.log(response.data)
         setTableData(response.data)
         //navigate("/planestudio")
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    reader.readAsArrayBuffer(file);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const answer = window.confirm("Esta seguro de Asignar los Usuarios al Plan de eStudio?");
    if (answer) {
      //alert("Validar data para importar")
      //console.log(JSON.stringify(tableData))
      const count = tableData.filter(element => {
        if (element.codigo_usuario) {
          return true;
        }
        return false;
      }).length;
      //console.log(count)
      PlanDataService.planimportar(tableData)
        .then(function (response) {
          console.log(response.data)
          //setTableData(response.data)
          alert("Se asigno " + count + " usuarios al plan de estudio")
          navigate("/planestudiousuario?id=" + idplanestudio)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  useEffect(() => {
      //setIsLoading(true)
      PlanDataService.getplanestudioById(idplanestudio)
        .then(response => response.json())
        .then(result => {
          setPlan(result);
          console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
       
  }, [isLoading]);
  if (isLoading) {
      return <> <LoadingSpinner />  </>;
  }
  return (
    <div className="card card-custom">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title align-items-start flex-column">
        <span className="card-label fw-bold text-dark">Asignación Masiva de Usuarios a Plan de Estudio:
                     
                     </span>
                     <span className="text-danger mt-1 fw-semibold fs-6"> { plan?.denominacion}</span>
          
        </div>
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 mw-150px">
          
          </div>
          <Link to={"/planestudio"}
                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                <i className="fa-solid fa-reply "></i>
                                Volver
                            </Link>
                            <button className='btn btn-primary btn-sm' onClick={handleSubmit}  >
                                <i className="fa-solid fa-floppy-disk"></i>
                                Asignar Masivamente</button>
        </div>
      </div>
      <div className="card-body pt-10">
        <form>
         <div className="col-lg-3   input-group-sm mb-5">
                        
                    </div>
          <label>
            Cargar archivo xlsx:
            <input type="file" onChange={handleFileChange} />
          </label>
        </form>
        {tableData && (
         <div>
            <table className="table table-bordered">
            <thead>
              <tr>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>id</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Codigo</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Codigo Usuario</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Nombres</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Apellido</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Empresa</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Correo</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Centro Costo</td>
                <td style={{fontSize: '10px',color:'#fff', backgroundColor: '#009ef7'}}>Plan</td>
              </tr>
            </thead>
            <tbody>
                {tableData.map((usuario, index) => (
                    <tr key={index}>
                        <td>{usuario.id}</td>
                        <td>{usuario.codigo}</td>
                        <td>

                          {usuario.codigo_usuario?<>
                          <span>{usuario.codigo_usuario}</span>
                          </>:<>
                          <span className='text-danger'>No Existe</span>
                          </>}
                          </td>
                        <td>{usuario.nombres}</td>
                        <td>{usuario.apellido_paterno}</td>
                        <td>{usuario.empresa}</td>
                        <td>{usuario.correo_electronico}</td>
                        <td>{usuario.centro_costo}</td>
                        <td>{usuario.denominacion}</td>
                    </tr>
                ))}
 
            </tbody>
          </table>
                
          </div>
        )}
       
      </div>
      <div className="card-footer">
        <a href="content/plantilla-carga-usuarios-plan.xlsx" 
        target='_blank' className='text-success'>
           <i className="fa fa-file text-success "></i>
          Descarga Plantilla</a>
      </div>
    </div>
  );
}

export default PlanEstudioImportarForm;
