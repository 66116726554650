import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardUsuarioAdminPage } from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
 
 
import { Programa } from '../pages/programa/programa'
import { ProgramaEditar } from '../pages/programa/ProgramaEdit'
import { ProgramaTablaLista } from '../pages/programa/programatabla'
import { ProgramaSeguimientoPage } from '../pages/programa/programaseguimiento'
import { Usuario } from '../pages/usuario/Usuario'
import { UsuarioAcceso } from '../pages/usuario/acceso'
import { UsuarioEditar } from '../pages/usuario/UsuarioEdit'
import { UsuarioPasswordForm } from '../pages/usuario/usuariopassword'
import { UsuarioImportar } from '../pages/usuario/usuarioimportar'
import { UsuarioGrupoPage } from '../pages/usuario/usuariogrupo'
import { UsuarioReportePage } from '../pages/usuario/usuarioreporte'
import { UsuarioSinAsignar } from '../pages/usuario/usuariopendiente'


import { Empresa } from '../pages/empresa/empresa'
import { EmpresaEditar } from '../pages/empresa/empresaEdit'

import { Tema } from '../pages/tema/tema'
import { Temas } from '../pages/tema/temalista'
import { TemaEditar } from '../pages/tema/temaedit'
import { TemaUsuarioForm } from '../pages/tema/temausuario'
import { TemaPreguntaAsignar } from '../pages/tema/temapregunta'
import { TemaResultadoForm } from '../pages/tema/temaresultado'
import { TemaFeedbackForm } from '../pages/tema/temafeedback'
import { TemaPreview } from '../pages/tema/temapreview'
import { TemaReviewPage } from '../pages/tema/temareview'

import { Curso } from '../pages/curso/curso'
import { CursoEditar } from '../pages/curso/cursoEdit'
import { Modulo } from '../pages/modulo/modulo'
import { ModuloEditar } from '../pages/modulo/moduloEdit' 
import { Pregunta } from '../pages/pregunta/pregunta'
import { PreguntaEditar } from '../pages/pregunta/preguntaEdit'
import { PreguntaTema } from '../pages/pregunta/preguntatema'
import { Contenido } from '../pages/contenido/contenido'
import { ContenidoEditar } from '../pages/contenido/contenidoEdit'
import { DashboardUsuarioPage } from '../pages/dashboard/DashboardUsuario'
import { DashboardTemaPage } from '../pages/dashboard/DashboardTema'
import { Asignacion } from '../pages/asignacion/asignacion'
import { AsignacionEditar } from '../pages/asignacion/asignacionEdit'
import { AsignacionUsuario } from '../pages/asignacion/asignacionusuario'
import { AsignacionGrupoPage } from '../pages/asignacion/asignaciongrupo'
import { PreguntaImportar } from '../pages/pregunta/preguntaimportar'
import { Evaluacion } from '../pages/evaluacion/evaluacion'
import { EvaluacionEditar } from '../pages/evaluacion/evaluacionEdit'
import { EvaluacionSimplePage } from '../pages/evaluacion/evaluacionSimple'
import { EvaluacionResultadoPage } from '../pages/evaluacion/evaluacionresultado'

import { EvaluacionResult } from '../pages/evaluacion/evaluacionResult'
import { ResultadoPage } from '../pages/evaluacion/resultado'


import { Dominio } from '../pages/parametro/dominio'
import { Parametro } from '../pages/parametro/parametro'
import { ParametroEditar } from '../pages/parametro/parametroEdit'
import { CertificadoPDF } from '../pages/tema/certificado'
import { CertificadoVisorPDF } from '../pages/tema/certificadovisor'

import { Planestudio } from '../pages/planestudio/planestudio'
import { PlanestudioEditar } from '../pages/planestudio/planestudioEdit'
import { PlanEstudioFlujo } from '../pages/planestudio/planestudiotree'
import { PlanEstudioUsuarioPage } from '../pages/planestudio/planestudiousuario'
import { PlanEstudioSeguimientoPage } from '../pages/planestudio/planestudioseguimiento'
import { PlanEstudioProgresoPage } from '../pages/planestudio/planestudioprogreso'
import { PlanEstudioEDTPage } from '../pages/planestudio/planestudioedt'
import { PlanMisLogros } from '../pages/planestudio/planestudiologros'
import { PlanFin } from '../pages/planestudio/planestudiofin'
import { PlanEstudioImportar } from '../pages/planestudio/planestudioimportar'

import { ReporteEmpresaPage } from '../pages/reporte/reporteempresa'
import { ReporteUsuarioXEmpresaPage } from '../pages/reporte/reporteusuarioxempresa'
import { ReporteNPSPage } from '../pages/reporte/reportenps'
import { ReporteGralEmpresaPage } from '../pages/reporte/reportegralempresa'
import { ReporteDptoCeCoPage } from '../pages/reporte/reportedptoceco'
import { ReporteLineaTiempoPage } from '../pages/reporte/reportelineatiempo'
import { ReporteGralPage } from '../pages/reporte/reportegral'
import { ReporteUsuarioNoAsignadoPage } from '../pages/reporte/reporteusuarionoasignado'

import { ConfiguracioPage } from '../pages/configuracion/configuracion'

import { AsignacionPlan } from '../pages/asignacion/asignacionplan'
import { AsignacionEliminarPage } from '../pages/asignacion/asignacioneliminar'
import { TemaModuloAsociacion } from '../pages/tema/temamodulo'
import { Feedback } from '../pages/feedback/feedback'
  const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path="/"  element={<Navigate to='/dashboard' />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/empresaedit" element={< EmpresaEditar />} />

        <Route path="/dashboardusuario" element={<DashboardUsuarioPage />} />
        <Route path="/dashboardtema" element={<DashboardTemaPage />} />

        <Route path="/usuario" element={< Usuario />} />
        <Route path="/acceso" element={< UsuarioAcceso />} />
        <Route path="/usuarioedit" element={< UsuarioEditar />} />
        <Route path="/usuariopassword" element={< UsuarioPasswordForm />} />
        <Route path="/usuarioimportar" element={< UsuarioImportar />} />
        <Route path="/usuariogrupo" element={< UsuarioGrupoPage />} />
        <Route path="/usuarioreporte" element={< UsuarioReportePage />} />
        <Route path="/usuariosinasignar" element={< UsuarioSinAsignar />} />


        <Route path="/planestudio" element={< Planestudio />} />
        <Route path="/planestudioedit" element={< PlanestudioEditar />} />
        <Route path="/planestudioflujo" element={< PlanEstudioFlujo />} />
        <Route path="/planestudiousuario" element={< PlanEstudioUsuarioPage />} />
        <Route path="/planestudioseguimiento" element={< PlanEstudioSeguimientoPage />} />
        <Route path="/planestudioprogreso" element={< PlanEstudioProgresoPage />} />
        <Route path="/planestudioedt" element={< PlanEstudioEDTPage />} />
        <Route path="/planmislogros" element={< PlanMisLogros />} />
        <Route path="/planfin" element={< PlanFin />} />
        <Route path="/planimportar" element={< PlanEstudioImportar />} />

        <Route path="/reporteempresa" element={< ReporteEmpresaPage />} />
        <Route path="/reporteusuarioxempresa" element={< ReporteUsuarioXEmpresaPage />} />
        <Route path="/reportenps" element={< ReporteNPSPage />} />
        <Route path="/reportegralempresa" element={< ReporteGralEmpresaPage />} />
        <Route path="/reportedptoceco" element={< ReporteDptoCeCoPage />} />
        <Route path="/reportelineatiempo" element={< ReporteLineaTiempoPage />} />
        <Route path="/reportegral" element={< ReporteGralPage />} />
        <Route path="/reportenoasignado" element={< ReporteUsuarioNoAsignadoPage />} />

        <Route path="/programa" element={<Programa />} />
        <Route path="/programaedit" element={< ProgramaEditar />} />
        <Route path="/programatabla" element={< ProgramaTablaLista />} />
        <Route path="/programaseguimiento" element={< ProgramaSeguimientoPage />} />


        <Route path="/curso" element={< Curso />} />
        <Route path="/cursoedit" element={< CursoEditar />} />

        <Route path="/modulo" element={< Modulo />} />
        <Route path="/moduloedit" element={< ModuloEditar />} />

        <Route path="/tema" element={< Tema />} />
        <Route path="/temaedit" element={< TemaEditar />} />
        <Route path="/temausuario" element={< TemaUsuarioForm />} />
        <Route path="/temaresultado" element={< TemaResultadoForm />} />
        <Route path="/temafeedback" element={< TemaFeedbackForm />} />
        <Route path="/temamodulo" element={< TemaModuloAsociacion />} />
        <Route path="/temapreview" element={< TemaPreview />} />
        <Route path="/temareview" element={< TemaReviewPage />} />

        <Route path="/contenido" element={< Contenido />} />
        <Route path="/contenidoedit" element={< ContenidoEditar />} />

        <Route path="/pregunta" element={< Pregunta />} />
        <Route path="/preguntaedit" element={< PreguntaEditar />} />
        <Route path="/preguntatema" element={< PreguntaTema />} />
        <Route path="/temapregunta" element={< TemaPreguntaAsignar />} />

        <Route path="/asignacion" element={< Asignacion />} />
        <Route path="/asignacionedit" element={< AsignacionEditar />} />
        <Route path="/asignacionplan" element={< AsignacionPlan />} />
        <Route path="/asignacionusuario" element={< AsignacionUsuario />} />
        <Route path="/asignaciongrupo" element={< AsignacionGrupoPage />} />
        <Route path="/asignacioneliminar" element={< AsignacionEliminarPage />} />

        <Route path="/preguntaimportar" element={< PreguntaImportar />} />
        <Route path="/evaluacion" element={< Evaluacion />} />
        <Route path="/evaluacionedit" element={< EvaluacionEditar />} />
        <Route path="/evaluacionsimple" element={< EvaluacionSimplePage />} />
        <Route path="/evaluacionresult" element={< EvaluacionResult />} />
        <Route path="/evaluacionresultado" element={< EvaluacionResultadoPage />} />
        <Route path="/resultado" element={< ResultadoPage />} />


        <Route path='dashboard' element={<DashboardUsuarioAdminPage />} />
        
        <Route path="/dominio" element={< Dominio />} />
        <Route path="/parametro" element={< Parametro />} />
        <Route path="/parametroedit" element={< ParametroEditar />} />
        <Route path="/certificado" element={< CertificadoPDF />} />
        <Route path="/certificadovisor" element={< CertificadoVisorPDF />} />
        <Route path="/configuracion" element={< ConfiguracioPage />} />

        <Route path="/feedback" element={< Feedback />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
