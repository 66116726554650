import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import LoadingSpinner from "../_components/LoadingSpinner";
import EvaluacionDataService from "../_services/evaluacion";
 

const EvaluacionResultadoForm = () => {
    const [flgAprobado, setAprobado] = useState(false);
    const [evaluacion, setEvaluacion] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [respuestas, setRespuestas] = useState([]);
    const [resultado, setResultado] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const idevaluacion = queryParameters.get("id")
    useEffect(() => {
        EvaluacionDataService.getevaluacionResultadoById(idevaluacion )
        .then(result => {
                setEvaluacion(result.data);
                //console.log(result.data)
                setResultado(result.data)
                console.log(result.data)
                console.log(resultado.nota)
                setAprobado(resultado.aprobado)
                 
                //console.log(evaluacion.respuestas)
                const json = evaluacion.respuestas;
                const obj = JSON.parse(json);
                //console.log(obj)
                 
                setRespuestas( obj)
                //console.log(respuestas)
                setIsLoading(false)
        })
        .catch(e => {
            console.log(e);
        });
      
    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
    <>
      <div className="card card-custom card-px-1">
                <div className="card-header align-items-center  ">
                    <div className="card-title">
                    </div>
                    <div className="card-toolbar  ">
                        <span className="badge badge-light-success fs-base">
                                <i className="ki-duotone ki-arrow-up fs-5px mb-5 text-info  ">
                                    <span className="path1"></span><span className="path2"></span></i>
                                Intento:
                            <span className="badge badge-square badge-white"> {evaluacion.numero_intento}</span>
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <div className="alert alert-dismissible bg-light-success d-flex flex-center flex-column  ">
                        <span className="svg-icon svg-icon-5tx svg-icon-danger  ">...</span>
                        <div className="text-center">
                            <h5 className="fw-bolder fs-1 mb-5">Resultado de Cuestionario</h5>
                            <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                            <div className="mb-9">
                                {evaluacion.aprobado?
                                <>
                                Felicitaciones! completó con éxito el tema :<><b> {evaluacion.titulo} </b> </>
                                </>:
                                <>
                                No logró completar el tema:<><b> {evaluacion.titulo} </b> </>, porfavor :
                                </>
                                }
                                
                            </div>
                            <div className="d-flex flex-center flex-wrap">
                                
                                {evaluacion.aprobado ? <>
                                    {evaluacion.pendientes==0?<>
                                        <Link to={"/planfin?id="+evaluacion.id_planestudio }
                                        className="btn btn-primary m-2">
                                        <i className="fa-solid fa-home "></i>
                                        Continuar
                                    </Link>
                                    </>:<>
                                    <Link to={"/dashboardusuario" }
                                        className="btn btn-primary m-2">
                                        <i className="fa-solid fa-home "></i>
                                        Continuar
                                    </Link>
                                    </>}
                                    
                                </> : <>
                                <Link to={
                                    {
                                        pathname: "/evaluacionedit" ,
                                        search:   '?id='+ evaluacion.id_tema
                                      + '&idpe='+ evaluacion.id_planestudio
                                      +'&idp='+ evaluacion.id_programa
                                      +'&idc='+ evaluacion.id_curso
                                      +'&idm='+ evaluacion.id_modulo
                                      }

                                }
                                    className="btn btn-danger m-2">
                                    <i className="fa-solid fa-reply "></i>
                                    Intentar Nuevamente
                                </Link>
                                </> }
                              

                            </div>
                        </div>
                    </div>

                    <h1 className="anchor fw-bolder mb-3" id="icon-colors"><a href="#icon-colors"></a>Resumen de Respuestas
                    </h1>
                    <div className="separator my-10"></div>
                    {evaluacion.aprobado ?
                            <>
                               <div className="d-flex align-items-center bg-light-primary rounded p-5 mb-7">
                                    <span className=" text-danger me-5">
                                        <i className="fa-solid fa-user text-primary fs-1 me-5">
                                            </i>
                                    </span>
                                    <div className="flex-grow-1 me-2">
                                        <h2 className="fw-bold text-primary text-hover-primary fs-6">
                                                Aprobado
                                        </h2>                                
                                    </div>
                                    <span className="badge   fs-2x badge-primary">{evaluacion.nota}%
                                    </span>
                                </div>
                            </> :
                            <>
                            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
                                    <span className=" text-danger me-5">
                                        <i className="fa-solid fa-xmark text-danger fs-1 me-5">
                                            </i>
                                    </span>
                                    <div className="flex-grow-1 me-2">
                                        <h2 className="fw-bold text-primary text-hover-primary fs-6">
                                                Desaprobado
                                        </h2>                                
                                    </div>
                                    <span className="badge   fs-2x badge-danger">{evaluacion.nota}%
                                    </span>
                            </div>
                            </>}
                    
                    
                    <div className="d-flex align-items-center bg-light-primary rounded p-5 mb-7">
                            <span className=" text-danger me-5">
                                <i className="fa-solid fa-check text-primary fs-1 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span></i>
                            </span><div className="flex-grow-1 me-2">
                                <span  className="fw-bold text-gray-800 text-hover-primary fs-6">Respuestas Correctas</span>
                                <span className="text-muted fw-semibold d-block"> </span>
                                
                            </div>
                            <span className="badge   fs-2x badge-primary">{evaluacion.correcta}
                            </span>
                    </div>
                    <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
                            <span className=" text-danger me-5">
                                <i className="fa-solid fa-xmark text-danger fs-1 me-5">
                                    <span className="path1"></span>
                                    <span className="path2"></span></i>
                            </span><div className="flex-grow-1 me-2">
                                <a   className="fw-bold text-gray-800 text-hover-primary fs-6">Respuestas Incorrectas</a>
                                <span id="rptinco" className="text-muted fw-semibold d-block">Preguntas:</span>
                                <ul>
                                {respuestas.map((rpta) => <li id={rpta.id}>{rpta.pregunta}</li>)}
                                </ul>
                            </div>
                            <span className="badge   fs-2x badge-danger">{evaluacion.incorrecta}
                            </span>
                    </div>
                     
                </div>
            </div>
    </>
  )
}

export default EvaluacionResultadoForm;