
 
import axios from 'axios';
import React, {Component} from 'react';

import { parametro } from '../_models/parametro';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/parametro";
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/parametro";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/parametro";
class ParametroService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getparametros(dominio){
        return axios.get( API_BASE_URL+ '/sellst/'+dominio, {
            headers: {'Content-Type': 'application/json'}
        }) }
    getparametrosbycod(dominio){
            return axios.get( API_BASE_URL+ '/sellst/dominio/'+dominio, {
                headers: {'Content-Type': 'application/json'}
            }) }
    createparametro(parametro){
        return axios.post(API_BASE_URL+ '/ins', parametro,{
            headers: {'Content-Type': 'application/json'}
        })}
    getparametroById(parametroId){
        return axios.get(API_BASE_URL + '/sel/' + parametroId);
    }
    updateparametro(parametro){
        return axios.post(API_BASE_URL + '/upd/' , parametro,{
            headers: {'Content-Type': 'application/json'}
        })}

    deleteparametro(parametroId){
        return axios.delete(API_BASE_URL + '/' + parametroId);
    }
}

export default new ParametroService()