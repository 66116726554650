
import React, { Component ,useState} from "react";
import DominioDataService from "../_services/dominio";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

const columns = [
{   dataField: 'id_dominio',   text: 'Parametros' ,formatter: (rowContent, row) => {
  return (
    <>
      <Link to={"/parametro?id=" + rowContent} 
      className="btn btn-outline btn-outline-dashed btn-outline-primary  btn-sm"            >
              <i className="far fa-file fs-1qx text-primary"></i> 
              Editar Parametros
      </Link>
    </>
  )
} }, 
      {   dataField: 'dominio',   text: 'Tabla',sort:true },                   
];
export default class DominioLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _dominios: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    DominioDataService.getdominios()
      .then(response => {
        this.setState({
          _dominios: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _dominios } = this.state;
    return (
        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_dominio"
            data={ _dominios }
            columns={ columns }
            search           >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  <hr />
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 