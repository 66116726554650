
 
import axios from 'axios';
import React, {Component} from 'react';
import { feedback } from '../_models/feedback';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/feedback";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/feedback";
class FeedbackService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getfeedbacks(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createfeedback(feedback){
        return axios.post(API_BASE_URL+ '/ins', feedback,{
            headers: {'Content-Type': 'application/json'}
        })}
    getfeedbackById(feedbackId){
        return fetch(API_BASE_URL + '/sel/' + feedbackId);
    }
    updatefeedback( feedback){
        return axios.post(API_BASE_URL + '/upd/' , feedback,{
            headers: {'Content-Type': 'application/json'}
        }) }

    deletefeedback(feedbackId){
        return axios.delete(API_BASE_URL + '/' + feedbackId);
    }
}

export default new FeedbackService()