import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmpresaDataService from "../_services/empresa";
import UsuarioDataService from "../_services/usuario";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const columns = [
    { dataField: 'plan', text: 'Plan', sort: true, csvText: 'Codigo', headerStyle: { color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'tema', text: 'Tema', sort: true, csvText: 'Titulo', headerStyle: { color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'fecha_creacion', text: 'Fecha', sort: true, csvText: 'Descripcion', headerStyle: { color:'#fff', backgroundColor: '#009ef7'} },
    { dataField: 'hora_creacion', text: 'Hora', sort: true , headerStyle: { color:'#fff', backgroundColor: '#009ef7'}},
  
    { dataField: 'nota', text: 'Certificado', sort:true,headerStyle: { color:'#fff', backgroundColor: '#009ef7'} ,
    formatter: (rowContent, row) => {
        return (
            <>
            {rowContent >= row["notaminima"] ?
              <>
                <span className="badge badge-primary">Si</span>
              </> :
              <>
                <span className="badge badge-danger">No</span>
              </>}
          </>
          )
        }
    },
];
export default function UsuarioReporte() {
    const [reporte, setReporte] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [empresa, setEmpresa] = useState([]);

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const queryParameters = new URLSearchParams(window.location.search)
    const idusuario = queryParameters.get("id")
    useEffect(() => {
        UsuarioDataService.getUsuarioReporteById(idusuario)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setReporte(result)
            })
            .catch(function (error) {
                console.log(error);
            });
        UsuarioDataService.getUsuarioById(idusuario)
            .then(response => response.json())
            .then(result => {
              setUsuarios(result);
              //console.log(result);
            })
            .catch(e => {
              console.log(e);
            });
        EmpresaDataService.getempresas()
            .then(response => {
                setEmpresa(response.data)
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);
    return (
        <>
        <div className=" ">

        
        <div className="col-lg-12">
            <div className="card card-flush h-lg-100">
                <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-dark">Reporte por Usuario</span>
                        <span className="text-gray-400 mt-1 fw-semibold fs-6"> {date}</span>
                    </h3>
                    <div className="card-toolbar">
                    <Link to={"/usuario"}
                            className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                            <i className="fa-solid fa-reply "></i>
                            Volver
                        </Link>
                        <button className="btn btn-primary btn-sm" onClick={() => window.print()}>
                        <i className="fa-solid fa-print "></i>Imprimir</button>
                    </div>
                </div>
                <div className="card-body d-flex align-items-end pt-6">
                    <div className="row align-items-center mx-0 w-100">
                        <div className="col-7 px-0">
                            <div className="d-flex flex-column content-justify-center">
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <div className="bullet bg-success me-3" ></div>
                                    <div className="fs-5 fw-bold text-gray-600 me-5">Codigo Usuario:</div>
                                    <div className="ms-auto fw-bolder text-gray-700 text-end">{usuarios.codigo_usuario}</div>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center my-4">
                                    <div className="bullet bg-primary me-3" ></div>
                                    <div className="fs-5 fw-bold text-gray-600 me-5">Nombre del Usuario:</div>
                                    <div className="ms-auto fw-bolder text-gray-700 text-end">{usuarios.nombres } {usuarios.apellido_paterno}</div>
                                </div>
                                <div className="d-flex fs-6 fw-semibold align-items-center">
                                    <div className="bullet me-3"
                                    ></div>
                                    <div className="fs-5 fw-bold text-gray-600 me-5">Empresa:</div>
                                    <div className="ms-auto fw-bolder text-gray-700 text-end">
                                        <select value={usuarios.id_empresa}
                                        className="form-control mb-2"
                                        name="id_empresa" readonly >
                                        
                                        {empresa.map((parametro) => <option value={parametro.id_empresa}>{parametro.empresa}</option>)}
                                        </select>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 d-flex justify-content-end px-0">
                            <div id="kt_card_widget_19_chart" className="min-h-auto h-150px w-150px" data-kt-size="150"
                                data-kt-line="25">
                                <span></span><canvas height="150" width="150"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                    <BootstrapTable keyField='id_tema' data={ reporte } columns={ columns } />  
                    </div>

                </div>

            </div>
            </div>
        </>
    )
}
