

import { Link } from "react-router-dom"
import PreguntaImportarForm from "../../../_components/preguntaimportar";

export function PreguntaImportar  () {
  return (
 <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <PreguntaImportarForm></PreguntaImportarForm>
                     </div>
                </div>
            </div>
        </div>
  );
}