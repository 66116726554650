
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import TemaLista from "../../../_components/tema";

export function Tema() {
    // Function to export data as a file
  const exportData = (data: BlobPart, fileName: string, type: string) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
function exportcsv(){
    alert('demo')
    // Fetch data from API endpoint
    axios.get('https://apiucj.azurewebsites.net/api/usuario/sellst/whatsapp')
    .then(response => {
        console.log(response);
    // Convert data to CSV format
    const jsonData = JSON.stringify(response.data);
    exportData(jsonData, 'data.csv', 'text/csv;charset=utf-8;');
    })
    .catch(error => {
    // Handle errors
    console.error(error);
    });
}

    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <div className="card card-flush">
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        Listado de Temas
                                    </div>
                                </div>

                                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                    <div className="w-100 mw-150px">
                                         
                                    </div>
                                    <Link to={"/temaedit"} 
                                       className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary btn-sm">
                                       <i className="fa-solid fa-file "></i>
                                            Nuevo tema
                                        </Link>
                                       
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="table-responsive">
                                    <TemaLista></TemaLista>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}