
import React, { Component } from "react";
import { Link } from "react-router-dom";

import AsignacionLista from "../../../_components/asignacion";

export function Asignacion() {
    return (
        <> <AsignacionLista></AsignacionLista>
        </>
                                   
                               
    );
}