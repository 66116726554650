
import React, { Component } from "react";
import { Link } from "react-router-dom";

import AsignacionEliminarLista from "../../../_components/asignacioneliminar";

export function AsignacionEliminarPage() {
    return (
        <> <AsignacionEliminarLista></AsignacionEliminarLista>
        </>
                                   
                               
    );
}