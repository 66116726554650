
import React, { Component ,useState} from "react";
import EmpresaDataService from "../_services/empresa";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search , CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;

const columns = [
  {   dataField: 'id_empresa',   text: 'Editar' ,formatter: (rowContent, row) => {
    return (
      <>
        <div className="btn-toolbar justify-content-between" role="toolbar"
          aria-label="Toolbar with button groups">
          <div className="btn-group" role="group" aria-label="First group">
            <Link to={"/empresaedit?id=" + rowContent}
              className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
              <i className="fa-solid fa-edit   text-primary"></i>
            </Link>
          </div>
        </div>
      </>
    )
  } }, 
                        
        {   dataField: 'codigo_empresa',   text: 'Código',sort:true,csvText: 'Codigo' },                   
        {   dataField: 'empresa',   text: 'Empresa',sort:true,csvText: 'Titulo' },           
        {   dataField: 'codigo_gerente',   text: 'cod Gerente',sort:true,csvText: 'Descripcion' },  
        
        {   dataField: 'gerente',   text: 'Gerente',sort:true,csvText: 'Descripcion' },  
        {   dataField: 'codigo_director',   text: 'cod Director',sort:true,csvText: 'Descripcion' },  

        {   dataField: 'director',   text: 'Director',sort:true },                   
  ];
export default class EmpresaLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _empresas: []
    };
  }
  componentDidMount() {
    this.retrieveTutorials();
  }
  retrieveTutorials() {
    EmpresaDataService.getempresas()
      .then(response => {
        this.setState({
          _empresas: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _empresas } = this.state;
    return (
      <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_empresa"
            data={ _empresas }
            columns={ columns }
            search         >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                  
                  <BootstrapTable  className="table table-bordered table-responsive"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
 
    );
  }
}
 