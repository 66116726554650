
import React, { Component ,useState} from "react";
import ModuloDataService from "../_services/modulo";
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const ddlreporte = [];
const exportToCSV = (csvData, fileName) => {
  for (var i = 0, l = csvData.length; i < l; i++) {
    ddlreporte.push({
        "codigo": csvData[i].codigo, 
        "curso":  csvData[i].titulo ,
        "descripcion":  csvData[i].descripcion,
        "categoria":  csvData[i].codigo_categoria        ,
        "fecha_creacion":  csvData[i].fecha_creacion,
        "etiquetas":  csvData[i].etiquetas
    })
  }
  const ws = XLSX.utils.json_to_sheet(ddlreporte);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}
const columns = [
{   dataField: 'id_modulo',   text: 'Editar' ,formatter: (rowContent, row) => {
  return (
    <>
      <div className="btn-toolbar justify-content-between" role="toolbar"
        aria-label="Toolbar with button groups">
        <div className="btn-group" role="group" aria-label="First group">
          <Link to={"/moduloedit?id=" + rowContent}
            className="btn btn-outline btn-outline-dashed btn-outline-primary btn-icon btn-sm"               >
            <i className="fa-solid fa-edit   text-primary"></i>
          </Link>
          <Link to={"/planestudioedt?id=4&ref="+ rowContent}
                className="btn btn-outline btn-outline-dashed btn-outline-danger btn-icon btn-sm "            >
                <i className="bi bi-diagram-2 text-warning fs-2x"></i>
              </Link>
        </div>
      </div>
    </>
  )
} }, 
                       
      {   dataField: 'codigo',   text: 'Código',sort:true ,csvText: 'Codigo'},                   
      {   dataField: 'titulo',   text: 'Título',sort:true,csvText: 'Titulo' },                   
      {   dataField: 'descripcion',   text: 'Descripción',sort:true,csvText: 'Descripcion' },                   
      { dataField: 'codigo_estado', text: 'Estado', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {rowContent === "Activo" ?
              <>
                <span className="badge badge-primary">{rowContent}</span>
              </> :
              <>
                {rowContent === "Inactivo" ?
                  <>
                    <span className="badge badge-danger">{rowContent}</span>
                  </> :
                  <>
                    <span className="badge badge-warning">{rowContent}</span>
                  </>}
              </>}
          </>
        )
      }
    },
      {   dataField: 'fecha_creacion',   text: 'Creado'  ,sort:true },  
      {   dataField: 'fecha_modificacion',   text: 'Modificado'  ,sort:true },             
      {   dataField: 'codigo_categoria',   text: 'Categoria',sort:true },   
      { dataField: 'curso', text: 'Curso', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {rowContent == "0" ?
              <>
                <span className="badge badge-danger">{rowContent}</span>
              </> :
              <>
                <span className="badge badge-primary">{rowContent}</span>
              </>}
          </>
        )}
      }, 
      { dataField: 'tema', text: 'Tema', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {rowContent == "0" ?
              <>
                <span className="badge badge-danger">{rowContent}</span>
              </> :
              <>
                <span className="badge badge-primary">{rowContent}</span>
              </>}
          </>
        )}
      },                 
       {   dataField: 'etiquetas',   text: 'Etiquetas',sort:true },                   
];
export default class ModuloLista extends Component {
  constructor(props) {
    super(props);
     this.state = {
      _modulos: []
    };
  }
  componentDidMount() {
    this.obtenerregistros();
  }
  obtenerregistros() {
    ModuloDataService.getmodulos()
      .then(response => {
        this.setState({
          _modulos: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }
  render() {
    const {  _modulos } = this.state;
    return (
        <div className="col-lg-12">
            <ToolkitProvider
            keyField="id_modulo"
            data={ _modulos }
            columns={ columns }
            search    
            exportCSV={{  
              fileName: 'modulo.csv',
              separator: ';',
              noAutoBOM: false 
              }}            >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } placeholder="Buscar..." />
                 
                 
                    <button variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(_modulos,'UCJ-Modulos')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                  <hr />
                  <BootstrapTable className="table table-bordered"
                    { ...props.baseProps }
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </div>
    );
  }
}
 