import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageTitle } from '../../../_metronic/layout/core'
import PlanEstudioMisLogros from "../../../_components/planestudiomislogros";
export   function PlanMisLogros() {
  const [cliente, setCliente] = useState([]);
  useEffect(() => {
    
  }, []);
  return (
    <>
         <PlanEstudioMisLogros></PlanEstudioMisLogros>
    </>
  )
}
 