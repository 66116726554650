
 
import axios from 'axios';
import React, {Component} from 'react';

import { modulo } from '../_models/modulo';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/modulo";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/modulo";

class ModuloService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getmodulos(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getmodulosplan(idplanestudio){
        return axios.get( API_BASE_URL+ '/sellst/plan/'+idplanestudio, {
            headers: {
                'Content-Type': 'application/json'
            } 
        }) 
    }
    getmoduloscurso(curso){
        return axios.get( API_BASE_URL+ '/sellst/curso/'+curso, {
            headers: {
                'Content-Type': 'application/json'
            } 
        }) 
    }
    getmodulosprograma(idprograma){
        return axios.get( API_BASE_URL+ '/sellst/programa/'+idprograma, {
            headers: {
                'Content-Type': 'application/json'
            } 
        }) 
    }
    getmodulostema(modulo){
        return axios.get( API_BASE_URL+ '/sellst/tema/'+modulo, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createmodulo(modulo){
        return axios.post(API_BASE_URL+ '/ins', modulo,{
            headers: {'Content-Type': 'application/json'}
        })}
    createmodulo(modulo){
        return axios.post(API_BASE_URL+ '/ins', modulo,{
                headers: {'Content-Type': 'application/json'}
        })}
    createtemamodulo(modulo){
        return axios.post(API_BASE_URL+ '/ins/tema', modulo,{
                    headers: {'Content-Type': 'application/json'}
        })}
    createmodulocurso(modulo){
        return axios.post(API_BASE_URL+ '/ins/curso', modulo,{
                headers: {'Content-Type': 'application/json'}
    })}
    getmoduloById(moduloId){
        return fetch(API_BASE_URL + '/sel/' + moduloId);
    }
    updatemodulo(modulo){
        return axios.post(API_BASE_URL + '/upd/' , modulo,{
            headers: {'Content-Type': 'application/json'}
        })}

    deletemodulo(moduloId){
        return axios.get(API_BASE_URL + '/del/' + moduloId,{
            headers: {'Content-Type': 'application/json'}
        });
    }
}

export default new ModuloService()