

import { Link } from "react-router-dom"
import EmpresaForm from "../../../_components/empresaForm";
 
export function EmpresaEditar  () {
  return (
 <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <EmpresaForm></EmpresaForm>
                    </div>
                </div>
            </div>
        </div>
  );
}