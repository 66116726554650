import TemaModuloForm from "../../../_components/temamodulo";

export function TemaModuloAsociacion() {
   return (
       <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
           <div className="   " id="kt_docs_content_container">
               <div className="  ">
                    <TemaModuloForm></TemaModuloForm>
               </div>
           </div>
       </div>
   );
}