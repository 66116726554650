import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CursoDataService from "../_services/curso";
import ModuloDataService from "../_services/modulo";
import { useAuth } from "../app/modules/auth";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
export default function CursoForm() {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const idcurso = queryParameters.get("id")
  const [cursos, setCursos] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [temas, setTemas] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [arrtemas, setModulosCurso] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setFlag(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handledelete = async (e) => {
    const answer = window.confirm("Esta seguro de Eliminar el Registro?");
    if (answer) {
      if (idcurso !== null) {
        CursoDataService.deletecurso(idcurso)
          .then(result => {
            if (result.data.result == '0') {
              alert("Registro Eliminado con Éxito")
              navigate('/curso');
            } else {
              alert("Registro no se puede eliminar por dependencias : \n" + result.data.message)
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }
  const handleSubmit = async (e) => {

    e.preventDefault();
    const answer = window.confirm("Esta seguro de Guardar el Registro?");
    if (answer) {
      console.log(cursos);
      if (idcurso == null) {
        cursos.usuario_creacion = currentUser?.username
        //cursos.codigo_estado = '1'
        console.log(respuestas);
        CursoDataService.createcurso(cursos)
          .then(function (response) {
            console.log( response.data);
            for (var i = 0, l = respuestas.length; i < l; i++) {
              respuestas[i].id_curso = response.data.id_curso
            }
            ModuloDataService.createmodulocurso(respuestas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Insertado correctamente");
            navigate('/curso');
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        cursos.usuario_modificacion = currentUser?.username
        cursos.id_curso = idcurso
        for (var i = 0, l = respuestas.length; i < l; i++) {
          respuestas[i].id_curso = idcurso
        }
        CursoDataService.updatecurso(cursos)
          .then(function (response) {
            ModuloDataService.createmodulocurso(respuestas)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            alert("Registro Actualizado correctamente");
            navigate('/curso');
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    }
  };
  const handleChange = (e) => {
    setCursos((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckedRow = (event, id, titulo) => {
    setFlag(true);
    arrtemas.push({
      "id_curso_modulo": 0,
      "id_modulo": id,
      "titulo": titulo
    })

  };
  function filtrarTemaAsignado(temas_, filtros) {
    return temas_.filter((curso) => !filtros.includes(curso.id_modulo));
  }
  function asignartemas(temas, filtros) {
    return temas.filter((item) =>
      filtros.some((filtro) => filtro.id_modulo === item.id_modulo)
    );
  }
  function seleccionartema() {
    if (flag) {
      var arrfiltro = []
      var arrcontenido=[]
      //console.log(asignartemas(temas,arrtemas))
      for (var i = 0, l = arrtemas.length; i < l; i++) {
        let idmodulo = arrtemas[i].id_modulo
        let index = arrcontenido.findIndex(object => object.id_modulo === idmodulo);
        if (index === -1) {
          arrcontenido.push({
            "id_curso_modulo": 0,
            "id_modulo": arrtemas[i].id_modulo,
            "titulo": arrtemas[i].titulo
          });
          arrfiltro.push(idmodulo)
          setRespuestas(arrcontenido)
        }
      }
      console.log(respuestas)
      console.log(arrtemas)
      //console.log(filtrarTemaAsignado(temas,arrfiltro))
      setModulos(filtrarTemaAsignado(modulos, arrfiltro))
    }


    handleClose();
  };

  const handleClick = (event, idmodulo, title) => {
    event.preventDefault();
    //console.log(idmodulo);
    let filteredArray = respuestas.filter(item => item.id_modulo !== idmodulo)
    //console.log(filteredArray);
    setRespuestas(filteredArray);
    modulos.push({
      "id_modulo": idmodulo,
      "titulo": title
    })

  };

  useEffect(() => {
    if (idcurso !== null) {
      ModuloDataService.getmoduloscurso(idcurso)
        .then(result => {
          setModulos(result.data)
          console.log(result.data)
        });
      CursoDataService.getcursoById(idcurso)
        .then(response => response.json())
        .then(result => {
          setCursos(result);
          console.log(result);
        })
        .catch(e => {
          console.log(e);
        });
      CursoDataService.getcursosmodulo(idcurso)
        .then(function (response) {
          console.log(response.data)
          setModulosCurso(response.data)
          for (var i = 0, l = response.data.length; i < l; i++) {
            respuestas.push({
              "id_curso_modulo": response.data[i].id_curso_modulo,
              "id_modulo": response.data[i].id_modulo,
              "titulo": response.data[i].titulo
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      ModuloDataService.getmodulos()
        .then(result => {
          setModulos(result.data)
          //console.log(result.data)
        });
    }
  }, []);
  return (
    <div className="card card-custom">
      <form onSubmit={handleSubmit}>
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <h2>Editar Curso</h2>
            </div>
          </div>

          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <div className="w-100 mw-150px">

            </div>

            <Link to={"/curso"}
              className="btn btn-icon-white btn-text-white btn-danger btn-sm">
              <i className="fa-solid fa-reply "></i>
              Volver
            </Link>
            <button className='btn btn-primary btn-sm' type="submit ">
              <i className="fa-solid fa-floppy-disk"></i>
              Guardar</button>
            <Button className="btn btn-outline btn-outline-dashed btn-outline-success btn-active-light-success btn-sm" onClick={handleClickOpen}>
              <><i className="fa-solid fa-floppy-disk"></i></>
              Agregar  Modulo
            </Button>
          </div>
        </div>
        <div className="card-body pt-10">
          <div className="form-group row">
            <div className="col-lg-3   input-group-sm mb-5">
            <div className="input-group input-group-sm  ">
              <span className="input-group-text" id="inputGroup-sizing-sm">Codigo</span>
              <input type="text" name="codigo" maxLength={"20"} required defaultValue={cursos.codigo}
                className="form-control" onChange={handleChange} />
            </div>
            </div>
            <div className="col-lg-6   input-group-sm mb-5">
            <div className="input-group input-group-sm  ">
              <span className="input-group-text" id="inputGroup-sizing-sm">Titulo</span>
              <input type="text" name="titulo" maxLength={"250"} required defaultValue={cursos.titulo}
                className="form-control" onChange={handleChange} />
            </div>
            </div>
            <div className="col-lg-3   input-group-sm mb-5">
                <div className="input-group input-group-sm  ">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Estado</span>
                      <select className="form-control" onChange={handleChange}
                        value={cursos.codigo_estado} required name="codigo_estado">
                        <option >-- Seleccione --</option>
                        <option value="0">En Desarrollo</option>
                        <option value="1">Activo</option>
                        <option value="2">Inactivo</option>
                      </select>
                </div>
              </div>
            
            <div className="col-lg-12   input-group-sm mb-5">
            <div className="input-group input-group-sm  ">
              <span className="input-group-text" id="inputGroup-sizing-sm">Descripcion</span>
              <input type="text" name="descripcion" maxLength={"250"} required defaultValue={cursos.descripcion}
                className="form-control" onChange={handleChange} />
            </div></div>
           
            
            <div className="col-lg-10   input-group-sm mb-5">
            <div className="input-group input-group-sm  ">
              <span className="input-group-text" id="inputGroup-sizing-sm">Etiquetas</span>
              <input type="text" name="etiquetas" maxLength={"250"} required defaultValue={cursos.etiquetas}
                className="form-control" onChange={handleChange} />
            </div></div>
             

          </div>
        </div>
      </form>
      <div className="card-footer bg-gray-100 border-top-0 text-right">
        <div className="col-lg-12 ">
          <form  >
            <div className="table table-rounded table-striped border table-responsive">
              <table className="table"  >
                <thead>
                  <tr className="fw-bold fs-6 border border-gray-200">
                    <th>Nro</th>
                    <th>Modulo</th>
                    <th>Accion</th>
                  </tr>
                </thead>
                <tbody>
                  {respuestas.map((item, index) => (
                    <>
                      <tr>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                        <Link to={"/moduloedit?id="+ item.id_modulo}
                              className="text-primary">
                              {item.titulo}
                            </Link>
                          </td>
                        <td>
                          <button className='btn btn-icon btn-danger'
                            onClick={event => handleClick(event, item.id_modulo, item.titulo)}
                            type="submit" >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <button type="button"   style={idcurso>0? {display:"block"}:{ display: "none" }}  
            className="btn btn-outline btn-outline-dashed btn-outline-danger  btn-sm" 
            onClick={handledelete} 
                       >
            Eliminar Curso
        </button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Seleccione los modulos asociados al curso"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <table className="table">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800">
                  <th>Sel</th>
                  <th>modulo</th>
                </tr>
              </thead>
              <tbody>

                {modulos.map((item) => (
                  <>
                    <tr>
                      <td><input type="checkbox" value={item.id_modulo} className="form-check"
                        name={"id_modulo" + item.id_modulo}
                        onChange={(event) => handleCheckedRow(event, item.id_modulo, item.titulo)}></input></td>
                      <td>{item.titulo}</td>
                    </tr>
                  </>
                ))}

              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button className="btn btn-primary" onClick={seleccionartema} autoFocus>
            Seleccionar Modulos
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
