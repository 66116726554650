import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, Component, useEffect, useState } from "react";
import EmpresaDataService from "../_services/empresa";
import ParametroDataService from "../_services/parametro";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReporteDataService from "../_services/reporte";
import PlanDataService from "../_services/planestudio";
import UsuarioDataService from "../_services/usuario";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import LoadingSpinner from "../_components/LoadingSpinner";
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const arrplan = []
//const arrempresa=[]
const ddlplan = [];
const ddlempresa = [];
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
//const API_BASE_URL = "http://localhost:65389//api/reporte/";
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte/";
export default function ReporteGralEmpresa() {
    const [datos, setDatos] = useState([]);
    const [columnas, setReporteCols] = useState([]);

    const [plansel, selPlan] = useState([]);
    const [empresasel, selEmpresa] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const [plan, setPlan] = useState([]);
    const [empresa, setEmpresa] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const exportToCSV = (csvData, fileName) => {
        
        //const newArr = datos.map(({ id_empresa, empresa }) => ({ companyId: id_empresa, companyName: empresa}));
        //var col2=" Total Usuarios";
        const data_header = [];
        for (var i = 0, l = columnas.length; i < l; i++) {
            data_header.push(columnas[i].dataField )
        }
        console.log(data_header)
        //const new_header = data_header
        data_header[0] = "Empresa"
        data_header[1] = "Usuarios_Activos"
        data_header[2] = "Usuarios_Asignados"
        data_header[3] = "Usuarios_Aprobados"
        data_header[4] = "Usuarios_Pendientes"
        data_header[5] = "Avance_Total"
        var newArr=csvData.map(({ 
            empresa: Empresa,
            usuarios: Usuarios_Activos,
            asignados: Usuarios_Asignados,
            aprobado: Usuarios_Aprobados,
            pendiente: Usuarios_Pendientes,
            avance: Avance_Total,...rest }) => ({ 
                Empresa,
                Avance_Total,
                Usuarios_Aprobados,
                Usuarios_Asignados,
                Usuarios_Pendientes,
                Usuarios_Activos, ...rest }));
        //delete newArr[0].id_
        //console.log(newArr) 
        const ws = XLSX.utils.json_to_sheet(newArr, { header: data_header });


        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        //XLSX.utils.sheet_add_aoa(ws, [new_header], { origin: "A1" });
 

        FileSaver.saveAs(data, fileName + fileExtension);
    }
    const handleChange = (e) => {
        console.log(e)
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
            arrtemp.push(e[i].id)
        }
        console.log(arrtemp)
        selEmpresa(arrtemp.toString())
        console.log(empresasel)
    }
    
    
    const handleClick = async () => {
        setIsLoading(true);
        try {
            //console.log(empresasel)
            if (empresasel.length>0){
                if (plansel.length>0){
                    setIsLoading(true)
                    const arrparam = ({
                        "id_planestudio": plansel.toString(),
                        "id_empresa": empresasel.toString()
                    })
                    ReporteDataService.getgeneralempresa_cols(JSON.stringify(arrparam))
                        .then(result => {
                            setReporteCols(result.data)
                            console.log(result.data)
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    const response = await fetch(API_BASE_URL+'/general/empresa/'+empresasel+'/'+plansel+'/'+fechadesde+'/'+fechahasta );
                    const result = await response.json();
                    console.log(result)
                    setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
                    setIsLoading(false)
                }else {
                    alert("Seleccione Plan")
                }
            }else {
                alert("Seleccione Empresa")
            }
            
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
    useEffect(() => {
        //setIsLoading(true)  
        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
        }
        ReporteDataService.obtener_parametros(parempresa)
            .then(result => {
                setEmpresa(result.data);
                //console.log(result.data)
            })
            .catch(e => {
                console.log(e);
            });
        const parplan = {
            "nombre_tabla": "tab_planestudio",
            "idresult": "convert(varchar,id_planestudio)",
            "codigo": "denominacion",
            "descripcion": "denominacion",
            "primer_valor": "",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
        }
        ReporteDataService.obtener_parametros(parplan)
            .then(result => {
                setPlan(result.data)

                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
            });
            const date = new Date();
            const futureDate = date.getDate() + 7;
            const defaultfecha = date.toLocaleDateString('en-CA');
            date.setDate(futureDate);
            const defaultfecha2 = date.toLocaleDateString('en-CA');
            setFechaDesde(defaultfecha)
            setFechaHasta(defaultfecha2)
    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }

    return (
        <>
            <div className="row">


                <div className="col-lg-12">
                    <div className="card card-custom">
                        <div className="card-header pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Reporte de Avance por Plan de Estudio</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                            </h3>
                            <div className="card-toolbar">
                              
                                <button className="btn btn-primary btn-sm" disabled={isLoading} 
                                    onClick={handleClick}>
                                    <i className="fa-solid fa-print "></i>Generar Reporte</button>

                                <button variant="warning" disabled={isLoading} className="btn btn-success btn-sm"
                                    onClick={(e) => exportToCSV(datos, 'UCJ-Reporte General Empresa')}>
                                    <i className="fa-solid fa-file-excel "></i>Exportar</button>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-lg-3   input-group-sm mb-5">
                                     
                                        <span className="label text-info"  >Empresa</span>
                                        <Select  
                                            placeholder="Empresas"
                                            name="id"
                                            isMulti
                                            onChange={(e) => handleChange(e)}
                                            options={empresa}
                                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.codigo}
                                        />
                                     
                                </div>
                                <div className="col-lg-3   input-group-sm mb-5">
                                    
                                        <span className="label text-info" id="inputGroup-sizing-sm">Plan de Estudio</span>
                                        <Select  
                                            placeholder="Seleccione Plan Estudio"
                                            name="id"
                                            onChange={(e) => selPlan(e.id)}
                                            options={plan}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={option => option.descripcion}
                                        />
                                     
                                </div>
                                <div className="col-lg-3   input-group-sm mb-5">

                                    <span className="label text-info">Fecha Desde: </span>
                                    <input type="date" className="form-control"
                                        id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                                        defaultValue={fechadesde} />
                                </div>
                                <div className="col-lg-3   input-group-sm mb-5">
                                    <span className="label text-info">Hasta: </span>
                                    <input type="date" className="form-control"
                                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                                        defaultValue={fechahasta} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="container">
                            <div className="table-responsive">
                                {columnas.length > 0 ?
                                    <>
                                        <ToolkitProvider
                                            keyField="id" 
                                            data={datos}
                                            columns={columnas}
                                            search           >
                                            {
                                                props => (
                                                    <div>
                                                        <SearchBar {...props.searchProps} placeholder="Buscar..." />
                                                        <hr />
                                                        <BootstrapTable className="table table-striped table-bordered table-sm"
                                                            {...props.baseProps}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </ToolkitProvider>
                                    </> :
                                    <>
                                        <span> </span>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
