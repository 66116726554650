import { Link } from "react-router-dom"
import UsuarioFormPassword from "../../../_components/usuariopassword"

export function UsuarioPasswordForm  () {
    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <div className="card ">
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        <h2>Reiniciar Contraseña Usuario</h2>
                                    </div>
                                </div>

                                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                    <div className="w-100 mw-150px">

                                    </div>
                                  
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <UsuarioFormPassword></UsuarioFormPassword>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}