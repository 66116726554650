import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import EvaluacionDataService from "../_services/evaluacion";
import FeedbackDataService from "../_services/feedback";
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { useAuth } from "../app/modules/auth";
import logdataservice from "../_services/log";

const marks = [
    {
      value: 10,
      label: '1',
    },
    {
      value: 20,
      label: '2',
    },
    {
      value: 30,
      label: '3',
    },
    {
      value: 40,
      label: '4',
    },
    {
        value: 50,
        label: '5',
    },
    {
        value: 60,
        label: '6',
    },
    {
        value: 70,
        label: '7',
      },
      {
          value: 80,
          label: '8',
      },
      {
          value: 90,
          label: '9',
      },
      {
        value: 100,
        label: '10',
    },
  ];
  
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";
function TemaFeedback() {
    const { currentUser, logout } = useAuth()

    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const idevaluacion = queryParameters.get("id")
    const [evaluacion, setEvaluacion] = useState([]);
    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
        logdataservice.registralog("feedback","",idevaluacion, currentUser.id, currentUser.username);

        if (idevaluacion !== null){
            EvaluacionDataService.getevaluacionById(idevaluacion) 
                .then(result => {
                    setEvaluacion(result.data);
                    console.log(result.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }
       
    }, []);
    const handleSubmit = async (e) => {
        
        e.preventDefault();
        const answer = window.confirm("Esta seguro de Enviar el Feedback?");
        if (answer) {
            feedback.usuario_creacion='admin';
            feedback.id_evaluacion=idevaluacion
            console.log(feedback)
            FeedbackDataService.createfeedback(feedback)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                alert("Feedback Enviado correctamente");
                navigate('/dashboardusuario');
              })
              .catch(function (error) {
                console.log(error);
              });
        }
        
       
    }
    const handleChangeSlider = (event, newValue) => {
        //console.log(event.target);
        //console.log(newValue);
        setFeedback((prev) => ({
            ...prev,
            [event.target.name]: newValue,
        }));
      };
    const handleChange = (e ) => {
        console.log();
        setFeedback((prev) => ({
           ...prev,
           [e.target.name]: e.target.value,
       }));
};
    return (

        <div className="card card-custom card-flush">

            <div className="card-body">
                <form onSubmit={handleSubmit}>
                <div className="alert alert-dismissible bg-light-warning d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">


                    <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">...</span>

                    <div className="text-center">
                        <h5 className="fw-bolder fs-1 mb-5">Feedback</h5>

                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                        <div className="mb-9">
                            <h3 className="anchor fw-bolder mb-2" id="icon-colors"><a href="#icon-colors"></a>
                                En base  a tu experiencia,¿Recomendarías el tema a tus compañeros?:
                                 
                            </h3>
                            Tema:<h1>{   }</h1> 
                        </div>

                        <div className="mb-10">
                        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">

                        <span className="fs-3x">😞</span>
                            <Slider
                                    defaultValue={10}
                                    onChange={handleChangeSlider}
                                    name="escala"
                                    step={10}
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                />
                                <span className="fs-3x">😍</span>
                        </Stack>
                      
                        
                            <div className="rating text-center">
                                  
                            </div>
                            <label className="form-label">Comentario</label>
                            <input
                                type="text"   name="comentario"
                                className="form-control"
                                placeholder="Comentario" onChange={handleChange}
                            />
                        </div>
                        <div className="d-flex flex-center flex-wrap">

                        <button className='btn btn-primary'  type="submit" >
                                <i className="fa-solid fa-floppy-disk"></i>
                                Enviar Feedback</button>

                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    )
}

export default TemaFeedback