
 import { Link } from "react-router-dom";
import EvaluacionLista from "../../../_components/evaluacion";

export function Evaluacion() {

    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                
                                    <EvaluacionLista></EvaluacionLista>
                              
            </div>
        </div>
    );
}