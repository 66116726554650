import React, { useState } from 'react';
import { read, utils, writeFile } from 'xlsx';
import { Link ,useNavigate} from "react-router-dom";
import PreguntaDataService from '../_services/pregunta';
import UsuarioDataService from '../_services/usuario';
function UsuarioImportarForm() {
  const navigate = useNavigate();

  const [fileData, setFileData] = useState(null);
  const [tableData, setTableData] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, {type: 'array'});
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      
      const json = utils.sheet_to_json(sheet, {header: 1});
      setFileData(file);
      setTableData(json);
    }
    reader.readAsArrayBuffer(file);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const answer = window.confirm("Esta seguro de Importar los Usuarios?");
    if (answer) {
      console.log(tableData)
      var usuarios =[]
      for (var i = 1, l = tableData.length; i < l; i++) {
        usuarios.push({
          cod_empresa: tableData[i][0] ,  
          empresa: tableData[i][1] ,  
          ceco: tableData[i][2] ,  
          desc_ceco: tableData[i][3] ,  
          trabajador: tableData[i][4] ,  
          departamento: tableData[i][5] ,  
          tipo_doc: tableData[i][6] ,  
          n_doc: tableData[i][7] ,  
          mail: tableData[i][8] ,  
          telefono: tableData[i][9] ,  
          nombre: tableData[i][10] ,  
          apellido_paterno: tableData[i][11] ,  
          apellido_materno: tableData[i][12] ,  
          codigo: tableData[i][13] ,  
          puesto_trabajador: tableData[i][14] ,  
          fecha_ingreso: tableData[i][15] ,  
          sexo: tableData[i][16] ,  
          

        })
      }
      console.log(usuarios)
      UsuarioDataService.createUsuarioimportar(usuarios)
      .then( (response) =>{
        //console.log(JSON.stringify(response.data));
        alert("Registro Actualizado correctamente");
        navigate('/usuario');
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  return (
    <div className="card card-custom">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <h2>Carga Masiva de Usuarios</h2>
          </div>
        </div>
        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <div className="w-100 mw-150px">
     
                         
          </div>
          <Link to={"/usuario"}
                                className="btn btn-icon-white btn-text-white btn-danger btn-sm">
                                <i className="fa-solid fa-reply "></i>
                                Volver
                            </Link>
                            <button className='btn btn-primary' onClick={handleSubmit}  >
                                <i className="fa-solid fa-floppy-disk"></i>
                                Guardar</button>
        </div>
      </div>
      <div className="card-body pt-10">
      <form>
        <label>
          Cargar archivo xlsx:
          <input type="file" onChange={handleFileChange} />
        </label>
      </form>
      {tableData && (
        <table className="table table-responsive table-bordered">
          <thead>
            <tr>
              {tableData[0].map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={`${rowIndex}-${cellIndex}`}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
    <div className="card-footer">
        <a href="https://ucj2.azurewebsites.net/content/plantilla-carga_usuarios.xlsx" 
        target='_blank' className='text-success'>
           <i className="fa fa-file text-success "></i>
          Descarga Plantilla</a>
      </div>
    </div>
  );
}

export default UsuarioImportarForm;
