
 
import axios from 'axios';
import React, {Component} from 'react';
import { asignacion } from '../_models/asignacion';
const API_BASE_URL = process.env.REACT_APP_API_URL+"/asignacion";
class AsignacionService extends Component{
    constructor(){
        super();
        
        this.state = {
            users: []
        };
       
    }
    getasignacions(){
        return axios.get( API_BASE_URL+'/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
        
        //.then((data) => console.log(data));
    }
    getasignacionesusuario(idusuario){
        return axios.get( API_BASE_URL+ '/sellst/usuario/'+ idusuario, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createasignacion(asignacion){
        return axios.post(API_BASE_URL+ '/ins', asignacion,{
            headers: {'Content-Type': 'application/json'}
        })}
    createasignacionitem(asignacion){
        return axios.post(API_BASE_URL+ '/ins/item', asignacion,{
            headers: {'Content-Type': 'application/json'}
        })}
    createasignaciongrupo(asignacion){
        return axios.post(API_BASE_URL+ '/ins/grupo', asignacion,{
            headers: {'Content-Type': 'application/json'}
        })}
    getasignacionById(asignacionId){
        return axios.get(API_BASE_URL + '/sel/' + asignacionId);
    }
    getasignacionByUsuario(idusuario){
        return axios.get(API_BASE_URL + '/sel/usuario/' + idusuario);
    }
    getAsignacionByUsuarioPendiente(idusuario,idplan){
        return axios.get(API_BASE_URL + '/sel/usuario/pendiente/' + idusuario+'/'+idplan);
    }
    updateasignacion(idasignacion,asignacion){
        return axios.post(API_BASE_URL + '/upd/'+idasignacion , asignacion,{
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            console.log(response);
          }).catch(err => console.error("Wasn't able to update user.", err));}

    deleteasignacion(asignacion){
        return axios.post(API_BASE_URL + '/del/' , asignacion,{
            headers: {'Content-Type': 'application/json'}
        }); 
    }
    eliminarasignacion(asignacion){
        return axios.post(API_BASE_URL + '/eliminar/' , asignacion,{
            headers: {'Content-Type': 'application/json'}
        }); 
    }
    asignacionmasiva(data){
        return axios.post( API_BASE_URL+ '/sellst/eliminar',data, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
}

export default new AsignacionService()