
import DashboardTemaPanel from "../../../_components/dashboardtema";
export function DashboardTemaPage  () {
    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
                   <div className="   " id="kt_docs_content_container">
                       <div className="row  ">
                           <div className="col-xl-12">
                                <DashboardTemaPanel></DashboardTemaPanel>
                                     
                           </div>
                       </div>
                   </div>
               </div>
         );
}