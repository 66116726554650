import { Link, useNavigate } from "react-router-dom";
import React, { Component, useEffect, useState } from "react";
import LoadingSpinner from "../_components/LoadingSpinner";

import EmpresaDataService from "../_services/empresa";
import ParametroDataService from "../_services/parametro";
import Select from 'react-select';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ReporteDataService from "../_services/reporte";
import PlanDataService from "../_services/planestudio";

import UsuarioDataService from "../_services/usuario";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const API_BASE_URL = "https://apiucj.azurewebsites.net/api/reporte/";

const columns = [
  { dataField: 'planestudio', text: 'Plan', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
  { dataField: 'fecha_creacion', text: 'Fecha', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
  { dataField: 'tema', text: 'Tema', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
  { dataField: 'encuestas', text: 'Encuestas', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
  { dataField: 'modulo', text: 'Modulo', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' } },
  { dataField: 'detractor', text: 'Detractor', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' }, formatter: (rowContent, row) => {
      let total = parseInt(row["total"])
      //let pdetractor =Math.round(parseInt(rowContent) *100/total,2)
      return (
        <>
          <span className="badge badge-light-danger">{Math.round(rowContent * 100 / total, 0)}%</span>

        </>
      )
    }
  },

  {
    dataField: 'promotor', text: 'Promotor', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' }, formatter: (rowContent, row) => {
      let total = parseInt(row["total"])
      //let ppromotor = Math.round(parseInt(rowContent) *100/total,2)
      return (
        <>
          <span className="badge badge-light-primary">{Math.round(rowContent * 100 / total, 0)}%</span>

        </>
      )
    }
  },
  {
    dataField: 'nps', text: 'NPS', sort: true, headerStyle: { color: '#fff', backgroundColor: '#009ef7' }, formatter: (rowContent, row) => {
      let total = parseInt(row["total"])
      //let ppromotor = Math.round(parseInt(rowContent) *100/total,2)
      return (
        <>
          <span className="badge badge-light-primary">{Math.round(rowContent * 100 / total, 0)}%</span>

        </>
      )
    }
  },

];
const exportToCSV = (csvData, fileName) => {
  const ddlreporte = [];
  for (var i = 0, l = csvData.length; i < l; i++) {
    ddlreporte.push({
      "planestudio": csvData[i].planestudio,
      "fecha_creacion": csvData[i].fecha_creacion,
      "tema": csvData[i].tema,
      "encuestas": csvData[i].encuestas,
      "modulo": csvData[i].modulo,
      "detractor": csvData[i].detractor,
      "promotor": csvData[i].promotor,
      "nps": csvData[i].nps
    })
  }
  const ws = XLSX.utils.json_to_sheet(ddlreporte);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}
const ReporteNPS = () => {
  const [reporte, setReporte] = useState([]);
  const [fechadesde, setFechaDesde] = useState(null);
  const [fechahasta, setFechaHasta] = useState(null);
  const [datos, setDatos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState([]);
  const [plansel, selPlan] = useState([]);
  const handleClick = async () => {
    try {
        console.log(plansel)
        console.log(fechadesde)
        console.log(fechahasta)
          //if (plansel !=''){
              setIsLoading(true)
              const response = await fetch(API_BASE_URL+'/nps/'+plansel+'/'+fechadesde+'/'+fechahasta );
              const result = await response.json();
              console.log(result)
              setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
              setIsLoading(false)
          //}else {
              //alert("Seleccione Plan")
          //}
      
      
  } catch (error) {
      console.error('Hubo un error al obtener los datos:', error);
  } 
};
const handleChangePlanSelect = (e) => {
  //console.log(e)
  let arrtemp = []
  for (var i = 0, l = e.length; i < l; i++) {
      arrtemp.push(e[i].id)
  }
  selPlan(e.id_planestudio)
  console.log(plansel)
}
  useEffect(() => {
    const date = new Date();
        const futureDate = date.getDate() + 7;
        const defaultfecha = date.toLocaleDateString('en-CA');
        date.setDate(futureDate);
        const defaultfecha2 = date.toLocaleDateString('en-CA');
        setFechaDesde(defaultfecha)
        setFechaHasta(defaultfecha2)
     
    PlanDataService.getplanestudios( )
      .then(result => {
          setPlan(result.data);
          //console.log(result.data)
          setIsLoading(false)
      })
      .catch(e => {
          console.log(e);
      });
      
    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
    <>
      <div className="row">


        <div className="col-lg-12">
          <div className="card card-custom">
            <div className="card-header pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">Reporte NPS</span>
                <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
              </h3>
              <div className="card-toolbar">
                
              <button className="btn btn-primary btn-sm"  onClick={handleClick}>
                                    <i className="fa-solid fa-print "></i>Generar Reporte</button>
                <button variant="warning" className="btn btn-success btn-sm"
                  onClick={(e) => exportToCSV(datos, 'UCJ-Reporte NPS')}>
                  <i className="fa-solid fa-file-excel "></i>Exportar</button>

              </div>
            </div>

          </div>
        </div>
        <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                            <div className="col-lg-3   input-group-sm mb-5">
                                    Plan Estudio:
                                    <Select  
                            placeholder="Plan de Estudio"
                            name="id_planestudio"
                             
                            onChange={(e) => selPlan(e.id_planestudio)}
                            options={plan}
                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id_planestudio}
                            getOptionLabel={option => option.denominacion}
                        />
                                </div>
                                <div className="col-lg-3   input-group-sm mb-5">
                                    <span className="label text-info">Fecha Desde: </span>
                                    <input type="date" className="form-control"
                                        id="fecha_desde" name="fecha_desde" onChange={e => setFechaDesde(e.target.value)}
                                        defaultValue={fechadesde} />
                                </div>
                                <div className="col-lg-3   input-group-sm mb-5">
                                    <span className="label text-info">Hasta: </span>
                                    <input type="date" className="form-control"
                                        id="fecha_hasta" name="fecha_hasta" onChange={e => setFechaHasta(e.target.value)}
                                        defaultValue={fechahasta} />
                                </div>
                                 
                                <div className="col-lg-3   input-group-sm mb-5">
                                    NPS:
                                    <input type="number" className="form-control" 
                                    id="nps" name="nps"   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">

              <ToolkitProvider
                keyField="id"
                data={datos}
                columns={columns}
                search           >
                {
                  props => (
                    <div>
                      <SearchBar {...props.searchProps} placeholder="Buscar..." />
                      <hr />
                      <BootstrapTable className="table table-bordered table-responsive"
                        {...props.baseProps}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>




            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default ReporteNPS;