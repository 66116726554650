import axios from 'axios';
import React, {Component} from 'react';
import { Usuario } from '../_models/usuario';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/usuario";
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/usuario";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/usuario";
class UsuarioService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getUsuarios(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getAccesos(){
        return axios.get( API_BASE_URL+ '/sellst/acceso', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosplan(idplanestudio){
        return axios.get( API_BASE_URL+ '/sellst/planestudio/'+idplanestudio, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosSinAsignar(ide,idp,feci,fecf){
        return axios.get( API_BASE_URL+ '/sellst/sinasignar/'+ide+'/'+idp+'/'+feci+'/'+fecf, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosAsignar(){
        return axios.get( API_BASE_URL+ '/sellst/asigna', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosEmpresa(idempresa){
        return axios.get( API_BASE_URL+ '/sellst/empresa/'+idempresa, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosSeguimiento(idusuario){
        return axios.get( API_BASE_URL+ '/sellst/seguimiento/'+idusuario, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosTema(idusuario){
        return axios.get( API_BASE_URL+ '/sellst/tema/'+idusuario, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosKpi(idusuario){
        return axios.get( API_BASE_URL+ '/sellst/kpi/'+idusuario, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosKpiEmpresa(idplanestudio){
        return axios.get( API_BASE_URL+ '/sellst/kpi/empresa/'+ idplanestudio, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosKpiTema(){
        return axios.get( API_BASE_URL+ '/sellst/kpi/tema/', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getUsuariosKpiHora(){
        return axios.get( API_BASE_URL+ '/sellst/kpi/hora/', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createUsuario(Usuario){
        return axios.post(API_BASE_URL+ '/ins', Usuario,{
            headers: {'Content-Type': 'application/json'}
        })}
    createUsuarioGrupo(Usuario){
        return axios.post(API_BASE_URL+ '/ins/grupo', Usuario,{
               headers: {'Content-Type': 'application/json'}
        })}
    createUsuarioimportar(Usuario){
            return axios.post(API_BASE_URL+ '/ins/importar', Usuario,{
                headers: {'Content-Type': 'application/json'}
    })}
    getUsuarioById(UsuarioId){
        return fetch(API_BASE_URL + '/sel/' + UsuarioId);
    }
    getUsuarioReporteById(UsuarioId){
        return fetch(API_BASE_URL + '/sel/reporte/' + UsuarioId);
    }
    updateUsuario(Usuario){
        return axios.post(API_BASE_URL + '/upd/' , Usuario,{
            headers: {'Content-Type': 'application/json'}
        })}
    updateUsuarioPassword(Usuario){
            return axios.post(API_BASE_URL + '/upd/password/' , Usuario,{
                headers: {'Content-Type': 'application/json'}
        })}
    deleteUsuario(UsuarioId){
        return axios.delete(API_BASE_URL + '/' + UsuarioId);
    }
}

export default new UsuarioService()