import React, { useCallback, Component, useEffect, useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../_components/LoadingSpinner";
import Select from 'react-select';
import ReporteDataService from "../_services/reporte";
import AsignacionDataService from "../_services/asignacion";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import EmailService from "../_services/email";
import paginationFactory from 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const API_BASE_URL = process.env.REACT_APP_API_URL+"/asignacion";

const options = {
    sizePerPageList: [{
      
      text: '100', value: 100
    } , {
        text: '250', value: 250
      }, {
        text: '500', value: 500
      }, {
        text: '1000', value: 1000
      }] // A numeric array is also available. the purpose of above example is custom the text
  };
const columns = [
    { dataField: 'plan', text: 'Plan', sort: true },
    { dataField: 'fecha_creacion', text: ' Asignado', sort: true },
    { dataField: 'departamento', text: 'dpto', sort: true, csvText: 'dpto'},

    { dataField: 'centrocosto', text: 'CeCo', sort: true, csvText: 'CeCo'},
    { dataField: 'cargod', text: 'Cargo', sort: true, csvText: 'Cargo'},
    { dataField: 'empresa', text: 'Empresa', sort: true, csvText: 'Empresa'},
    { dataField: 'codigo', text: 'Código', sort: true, csvText: 'Codigo'},
    { dataField: 'nombres', text: 'Usuario', sort: true, formatter: (rowContent, row) => {
        return (
          <>
            {row["nombres"]} {row["apellido"]}
          </>
        )
      }
    },

    { dataField: 'correo_electronico', text: 'Email', sort: true },


    { dataField: 'fecha_inicio', text: 'Inicio', sort: true },
    { dataField: 'fecha_fin', text: 'Fin', sort: true },
  ];
const exportToCSV = (csvData, fileName) => {
    const ddlreporte = [];
    for (var i = 0, l = csvData.length; i < l; i++) {
        ddlreporte.push({
            "plan": csvData[i].plan, 
            "fecha_creacion":  csvData[i].fecha_creacion ,
            "codigo_grupo":  csvData[i].codigo_grupo,
            "centrocosto":  csvData[i].centrocosto,
            "cargod":  csvData[i].cargod,
            "empresa":  csvData[i].empresa        ,
            "codigo":  csvData[i].codigo,
            "personal":  csvData[i].nombres,
            "correo_electronico":  csvData[i].correo_electronico,
            "fecha_inicio":  csvData[i].fecha_inicio,
            "fecha_fin":  csvData[i].fecha_fin 
        })
      }
      const ws = XLSX.utils.json_to_sheet(ddlreporte);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }
const handleEmail = (row) => {
    const answer = window.confirm("Seguro de Enviar Notificacion de Asignacion al Usuario?");
    if (answer) {
      //console.log(row);
      const datausuario = []
      datausuario.push({
        "destinatario": row.correo_electronico,
        "nombre": row.nombres,
        "codigo": row.codigo,
        "plan": row.plan,
        "fecha_vencimiento": row.fecha_fin
      })
      //console.log(JSON.stringify(datausuario));
      EmailService.asignausuario(JSON.stringify(datausuario))
        .then(response => {
          //console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }


  }
const AsignacionEliminarLista = () => {
    const [datos, setDatos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [empresa, setEmpresa] = useState([]);
    const [plan, setPlan] = useState([]);
    const [plansel, selPlan] = useState([]);
    const [ceco, setCeCo] = useState([]);
    const [dpto, setDepartamento] = useState([]);
    const [puesto, setPuesto] = useState([]);
    const [empresasel, selEmpresa] = useState([]);
    const [fechadesde, setFechaDesde] = useState(null);
    const [fechahasta, setFechaHasta] = useState(null);
    const [filtro, setFiltro] = useState([]);
    const [eliminar, setEliminar] = useState([]);

    const selectedRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log('=== selectRow row ===', row);
            //setEliminar((empresa) => ({ ...prev, row}))
            //console.log(eliminar.length)
            if (empresa.length==0) {
              setEliminar(row);
            } else {
              setEliminar(empresa => [...empresa, row]);
            }
            console.log(eliminar)
            //console.log(eliminar.length)
        },
        onSelectAll: (isSelected, rows) => {
            console.log('=== selected All Row  ===', rows);
            setEliminar(rows)
            if (isSelected) {
                 
            } else {
  
            }
            
        }
    };
    const handleClickEliminar = async () => {
        try {
          const answer = window.confirm("Esta seguro de Eliminar los registros seleccionados?");
          if (answer) {
            AsignacionDataService.eliminarasignacion(JSON.stringify(eliminar))
            .then(function (response) {
                alert("Se eliminaron " + eliminar.length + " registros!")
                console.log( response);
                handleClick();
            })
            .catch(function (error) {
                console.log(error);
            });
          }
            //console.log(eliminar)
             
            
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
    const handleClick = async () => {
        try {
            //console.log(empresasel)
            console.log(filtro)
            setEliminar([])
            setIsLoading(true)
            const response = await axios.post(API_BASE_URL+'/sellst/eliminar',  filtro );
            const result = await response.data;
            console.log(result)
            //AsignacionDataService.asignacionmasiva(filtro)
            //.then(result => {
            ///   setDatos(result.data)
            //   console.log(result.data);})
            // .catch(e => {console.log(e);});
            setDatos(result); // Suponiendo que la respuesta de la API sea un arreglo de objetos
            setIsLoading(false)
             
            
        } catch (error) {
            console.error('Hubo un error al obtener los datos:', error);
        } 
    };
    const handleChangeSelect = (e) => {
        //console.log(e)
        let arrtemp = []
        for (var i = 0, l = e.length; i < l; i++) {
             arrtemp.push(e[i].id)
         }
        //selEmpresa(arrtemp.toString())
        //console.log(empresasel)
        setFiltro((prev) => ({ ...prev, ['id_empresa']: arrtemp.toString()}))
    } 
    const handleChangePlanSelect = (e) => {
      console.log(e)
      selPlan(e.id)
     
  }
    useEffect(() => {
        const date = new Date();
        const futureDate = date.getDate() + 7;
        // 
        const defaultfecha = date.toLocaleDateString('en-CA');
        date.setDate(futureDate);
        const defaultfecha2 = date.toLocaleDateString('en-CA');

        setFechaDesde(defaultfecha)
        setFechaHasta(defaultfecha2)
        setFiltro((prev) => ({ ...prev, ['fecha_desde']: defaultfecha}))
        setFiltro((prev) => ({ ...prev, ['fecha_hasta']: defaultfecha2}))
        const parempresa = {
            "nombre_tabla": "tab_empresa",
            "idresult": "convert(varchar,id_empresa)",
            "codigo": "convert(varchar,id_empresa)",
            "descripcion": "empresa",
            "primer_valor": "[Todas las Empresas]",
            "campocondicion": "codigo_estado",
            "campovalor": "1"
        }
        ReporteDataService.obtener_parametros(parempresa)
            .then(result => {setEmpresa(result.data);})
            .catch(e => {console.log(e);});
        const parplan = {
              "nombre_tabla": "tab_planestudio",
              "idresult": "convert(varchar,id_planestudio)",
              "codigo": "denominacion",
              "descripcion": "denominacion",
              "primer_valor": "",
              "campocondicion": "codigo_estado",
              "campovalor": "1"
          }
        ReporteDataService.obtener_parametros(parplan)
              .then(result => {setPlan(result.data); })
              .catch(e => {console.log(e);});
        const parpuesto = {
                "nombre_tabla": "tab_parametro",
                "idresult": "convert(varchar,codigo_parametro)",
                "codigo": "parametro",
                "descripcion": "parametro",
                "primer_valor": "[Todos los Puestos]",
                "campocondicion": "id_dominio",
                "campovalor": "2"
            }
        ReporteDataService.obtener_parametros(parpuesto)
                .then(result => {setPuesto(result.data);})
                .catch(e => {console.log(e);});
        const parceco = {
                    "nombre_tabla": "tab_parametro",
                    "idresult": "convert(varchar,codigo_parametro)",
                    "codigo": "parametro",
                    "descripcion": "parametro",
                    "primer_valor": "[Todos los CeCo]",
                    "campocondicion": "id_dominio",
                    "campovalor": "6"
                }
        ReporteDataService.obtener_parametros(parceco)
                    .then(result => {setCeCo(result.data);})
                    .catch(e => {console.log(e);});
        const pardpto = {
                        "nombre_tabla": "tab_parametro",
                        "idresult": "convert(varchar,codigo_parametro)",
                        "codigo": "parametro",
                        "descripcion": "parametro",
                        "primer_valor": "[Todos los Departamentos]",
                        "campocondicion": "id_dominio",
                        "campovalor": "8"
                    }
        ReporteDataService.obtener_parametros(pardpto)
                        .then(result => {setDepartamento(result.data);})
                        .catch(e => {console.log(e);});

    }, [isLoading]);
    if (isLoading) {
        return <> <LoadingSpinner />  </>;
    }
    return (
        <div className="card card-custom">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">Eliminacion Masiva de Usuarios Asignados</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6"> { }</span>
                </h3>
                <div className="card-toolbar">
                    <Link to={"/asignacion"} 
                        className="btn btn-icon-white btn-text-white btn-info btn-sm">
                        <i className="fa-solid fa-reply "></i>
                        Volver
                        </Link>
                    <button className='btn btn-primary btn-sm' onClick={handleClick}>Generar Listado</button>
                    {datos?<>
                      <button  variant="warning" className="btn btn-success btn-sm"
                         onClick={(e) => exportToCSV(datos,'UCJ-Usuarios Asignados')}>
                             <i className="fa-solid fa-file-excel "></i>Exportar</button>
                             <button className='btn btn-danger btn-sm' onClick={handleClickEliminar}>
                    <i className="fa-solid fa-trash "></i>Eliminar</button>
                    </>:<></>}
                  
                </div>
            </div>

            <div className="card-body">
                <div className="form-group row">
                    <div className="col-lg-3   input-group-sm mb-5">
                        <span className="label text-info">Seleccione: </span>
                        <Select className=" "
                            placeholder="Empresas"
                            name="id_empresa"
                            isMulti
                            onChange={(e) => handleChangeSelect(e)}
                            options={empresa}
                            
                            //onChange={(e) => setFiltro((prev) => ({ ...prev, ['id_empresa']: e.id}))}
                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.codigo}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        <span className="label text-info">Seleccione: </span>
                        <Select className=" "
                            placeholder="Plan de Estudio"
                            name="id_planestudio"
                            //onChange={(e) => handleChangePlanSelect(e)}
                            options={plan}
                            onChange={(e) => setFiltro((prev) => ({ ...prev, ['id_planestudio']: e.codigo}))}

                            // onChange={(e) => selEmpresa((prev) => ({ ...prev, ['id_empresa']: e.id_empresa}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.descripcion}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        <span className="label text-info">Fecha Desde: </span>
                        <input type="date" className="form-control"
                         id="fecha_desde" name="fecha_desde" 
                         onChange={(e) => setFiltro((prev) => ({ ...prev, ['fecha_desde']: e.target.value}))}
                         //onChange={e => setFechaDesde(e.target.value)}
                         defaultValue={fechadesde} />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        <span className="label text-info">Hasta: </span>
                        <input type="date" className="form-control" 
                        id="fecha_hasta" name="fecha_hasta" 
                        onChange={(e) => setFiltro((prev) => ({ ...prev, ['fecha_hasta']: e.target.value}))}

                       // onChange={e => setFechaHasta(e.target.value)}
                       defaultValue={fechahasta} />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Centro de Costo:
                        <Select className="form-control"
                            placeholder="Centro de Costo"
                            name="centro_costo" id="centro_costo"
                            //onChange={(e) => handleChangeSelect(e)}
                            options={ceco}
                            onChange={(e) => setFiltro((prev) => ({ ...prev, ['centro_costo']: e.id}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.descripcion}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Departamento:
                        <Select className="form-control"
                            placeholder="Departamento"
                            name="dpto" id="dpto"
                            //onChange={(e) => handleChangeSelect(e)}
                            options={dpto}
                            onChange={(e) => setFiltro((prev) => ({ ...prev, ['dpto']: e.id}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.descripcion}
                        />
                    </div>
                    <div className="col-lg-3   input-group-sm mb-5">
                        Puesto:
                        <Select className="form-control"
                            placeholder="Puesto"
                            name="puesto" id="puesto"
                            //onChange={(e) => handleChangeSelect(e)}
                            options={puesto}
                            onChange={(e) => setFiltro((prev) => ({ ...prev, ['puesto']: e.id}))}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.descripcion}
                        />
                    </div>
                    
                </div>
               
                <ToolkitProvider
                    keyField="id_asignacion"
                    data={datos}
                    columns={columns}
                    search           >
                    {
                        props => (
                            <div className="table-responsive">
                                <BootstrapTable 
                                selectRow={selectedRow}
                                className="table table-bordered table-responsive"
                                 pagination={ paginationFactory(options) }
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
               
            </div>

        </div>
    );

};

export default AsignacionEliminarLista;
