
 
import axios from 'axios';
import React, {Component} from 'react';
import { detalle } from '../_models/detalle_evaluacion';
//const API_BASE_URL = "https://apiucj.azurewebsites.net/api/detalle";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/detalle";
class DetalleService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getdetalles(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createdetalle(detalle){
        return axios.post(API_BASE_URL+ '/ins', detalle,{
            headers: {'Content-Type': 'application/json'}
        })}
    getdetalleById(detalleId){
        return fetch(API_BASE_URL + '/sel/' + detalleId);
    }
    updatedetalle( detalle){
        return axios.post(API_BASE_URL + '/upd/' , detalle,{
            headers: {'Content-Type': 'application/json'}
        }) }

    deletedetalle(detalleId){
        return axios.delete(API_BASE_URL + '/' + detalleId);
    }
}

export default new DetalleService()