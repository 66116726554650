
 
import axios from 'axios';
import React, {Component} from 'react';

import { evaluacion } from '../_models/evaluacion';
//const API_BASE_URL = "http://localhost:65389/api/evaluacion";
const API_BASE_URL = process.env.REACT_APP_API_URL+"/evaluacion";
 
class EvaluacionService extends Component{
    constructor(){
        super();
        this.state = {
            users: []
        };
    }
    getevaluacions(){
        return axios.get( API_BASE_URL+ '/sellst', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    getevaluacionesusuario(){
        return axios.get( API_BASE_URL+ '/sellst/usuario', {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    evaluacionresult(idevaluacion){
        return axios.post( API_BASE_URL+ '/resultado/',idevaluacion, {
            headers: {
                'Content-Type': 'application/json'
            }
        }) 
    }
    createevaluacion(evaluacion){
        return axios.post(API_BASE_URL+ '/ins', evaluacion,{
            headers: {'Content-Type': 'application/json'}
        })}
    createevaluaciondetalle(evaluacion){
        return axios.post(API_BASE_URL+ '/ins/detalle', evaluacion,{
            headers: {'Content-Type': 'application/json'}
        })}
    getevaluacionById(evaluacionId){
        return axios.get(API_BASE_URL + '/sel/' + evaluacionId);
    }
    getevaluacionResultadoById(evaluacionId){
        return axios.get(API_BASE_URL + '/sel/resultado/' + evaluacionId);
    }
    getevaluacionRptaById(evaluacionId){
        return axios.get(API_BASE_URL + '/sel/respuesta/' + evaluacionId);
    }
    updateevaluacion(idevaluacion,evaluacion){
        return axios.post(API_BASE_URL + '/upd/'+idevaluacion , evaluacion,{
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            console.log(response);
          }).catch(err => console.error("Wasn't able to update user.", err));}

    deleteevaluacion(evaluacionId){
        return axios.delete(API_BASE_URL + '/' + evaluacionId);
    }
}

export default new EvaluacionService()