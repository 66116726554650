import React, { useCallback, Component, useEffect, useState } from "react";
import EvaluacionDataService from "../../../_services/evaluacion";
import { Link } from "react-router-dom"
import axios from "axios";

const API_BASE_URL = "https://apiucj.azurewebsites.net/api/";

export function ResultadoPage  () {
    const [evaluacion, setEvaluacion] = useState([]);
    const [isOnline, setIsOnline] = useState(false);
    const [flgAprobado, setAprobado] = useState(false);
    const [respuestas, setRespuestas] = useState([]);

    const queryParameters = new URLSearchParams(window.location.search)
    const idevaluacion = queryParameters.get("id")
    const fetchData = useCallback( () => {
        EvaluacionDataService.getevaluacionById(idevaluacion)
            .then(result => {
                //setEvaluacion(result.data);
                setTimeout(() => {
                    EvaluacionDataService.getevaluacionById(idevaluacion)
                    .then(result => {
                        setEvaluacion(result.data);
                        setIsOnline(true)
                        if (result.data.nota >= result.data.notaminima) {
                            setAprobado(true)
                        }
                    });
                  }, 1500);
                console.log(result.data);
                setIsOnline(true)
            })
            .catch(e => {
                console.log(e);
            });
        //const json = await result.json();
        //console.log(evaluacion)
        fetch(API_BASE_URL + 'evaluacion/sel/respuesta/' + idevaluacion, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
         })
            .then(response => response.json())
            .then(result => {
                setRespuestas(result);
                
                console.log(result)
            })
            .catch(e => {
                console.log(e);
        });

    }, [])
    
    React.useEffect(() => {
        axios.post(API_BASE_URL + '/evaluacion/sel/result/'+idevaluacion , {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            console.log(response.data);
          }).catch(err => console.error("Wasn't able to update user.", err))
      }, []);
      
  return (
 <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                         
                    </div>
                </div>
            </div>
        </div>
  );
}