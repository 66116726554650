
import React, { Component } from "react";
import { Link } from "react-router-dom";

import ContenidoLista from "../../../_components/contenido";

export function Contenido() {
    return (
        <div className="   d-flex flex-column flex-column-fluid" id="kt_docs_content">
            <div className="   " id="kt_docs_content_container">
                <div className="row  ">
                    <div className="col-xl-12">
                        <div className="card card-flush">
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1">
                                       Contenido
                                    </div>
                                </div>

                                <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
                                    <div className="w-100 mw-150px">
                                         
                                    </div>
                                    <Link to={"/contenidoedit"} 
                                        className="btn btn-icon-white btn-text-white btn-primary">Nuevo contenido
                                        </Link>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div className="table-responsive">
                                    <ContenidoLista></ContenidoLista>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}